//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _didCreate_identity_backboneUser_success from './didCreate_identity_backboneUser_success';
import _willCreate_identity_backboneUser from './willCreate_identity_backboneUser';
import _willUpdate_identity_backboneUser_setPassword from './willUpdate_identity_backboneUser_setPassword';
export const didCreate_identity_backboneUser_success = _didCreate_identity_backboneUser_success;
export const willCreate_identity_backboneUser = _willCreate_identity_backboneUser;
export const willUpdate_identity_backboneUser_setPassword = _willUpdate_identity_backboneUser_setPassword;
export default {didCreate_identity_backboneUser_success, willCreate_identity_backboneUser, willUpdate_identity_backboneUser_setPassword};

