//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _componentStorage from './componentStorage';
import _localStorage from './localStorage';
import _loginSessionStorage from './loginSessionStorage';
import _pageLoadStorage from './pageLoadStorage';
import _tenantSettingsStorage from './tenantSettingsStorage';
export const componentStorage = _componentStorage;
export const localStorage = _localStorage;
export const loginSessionStorage = _loginSessionStorage;
export const pageLoadStorage = _pageLoadStorage;
export const tenantSettingsStorage = _tenantSettingsStorage;
export default {componentStorage, localStorage, loginSessionStorage, pageLoadStorage, tenantSettingsStorage};

