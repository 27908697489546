import { createElement as rc } from 'react';
import logging from '@sstdev/lib_logging';
import PropTypes from 'prop-types';
import HNode from '../../HNode';
import { hooks } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
const { useBbState } = hooks;

const _p = {
    useBbState
};

export const _private = _p;

const { SCOPE, VISIBILITY, DURATION } = constants.retention;

const TENANT_SETTINGS_RETENTION_POLICY = {
    scope: SCOPE.GLOBAL,
    visibility: VISIBILITY.PER_TENANT,
    duration: DURATION.ALWAYS
};
const DEFAULT_VALUE = false;
/**
 * @typedef {import('../../../../types').TenantSettings} TenantSettings
 */

/**
 * @see TenantSettings for property names that are acceptable as values
 * @param {object} props
 * @param {object} props.hNode
 * @param {Array<string>} props.hNode.value
 * @param {Array<object>} props.hNode.children
 * @returns {JSX.Element}
 */
const ShowForTenantSetting = props => {
    const {
        hNode: {
            value: [acceptableTenantSetting],
            children: [hNode]
        },
        ...restOfProps
    } = props || { hNode: {} };
    if (props.hNode.value.length > 1) {
        logging.error('ShowForTenantSetting only supports one value');
    }
    // get the actual setting for acceptableTenantSetting
    const [settingIsSet] = _p.useBbState(DEFAULT_VALUE, acceptableTenantSetting, TENANT_SETTINGS_RETENTION_POLICY);

    if (!settingIsSet) {
        return null;
    }

    return rc(HNode, { ...restOfProps, hNode });
};

ShowForTenantSetting.propTypes = {
    hNode: PropTypes.object
};
export default ShowForTenantSetting;
