import { useContext, useEffect, createElement as rc, useState, useCallback } from 'react';
import { Route, Switch } from 'react-router';
import useEventSink from './hooks/useEventSink';
import { LicenseAgreement, UseCaseRoot, TenantSelection } from './components';
import { App, ErrorBoundary, hooks, contexts, useDebounce } from 'lib_ui-primitives';
import { configureLogging, pageLoadStorage, getRouter, constants, registerServiceWorker } from 'lib_ui-services';
import Loading from './components/Unauthenticated/Loading';
import Unauthenticated from './components/Unauthenticated';
import ContractExpired from './components/Unauthenticated/ContractExpired';
import SetPin from './components/SetPin';
import lodash from 'lodash';
const { isEqual } = lodash;
import logging from '@sstdev/lib_logging';
import ConfigEntry from './components/Unauthenticated/ConfigEntry';
const { useRouter, useFeatureFlags } = hooks;

//All of these paths expect that we do NOT have a logged in profile
let profilelessPaths = ['/oauth', '/login', '/reset', '/reset/confirmed', '/confirm', '/signup'];
//overload the includes for to some-endswith
profilelessPaths.includes = path => profilelessPaths.some(p => path.endsWith(p));

const messagesByStatus = {
    [constants.ASYNC_LOAD_STATUS.notStarted]: 'Beginning a session.',
    [constants.ASYNC_LOAD_STATUS.pending]: 'Loading a session.',
    [constants.ASYNC_LOAD_STATUS.error]: 'There was an error loading the session.',
    [constants.ASYNC_LOAD_STATUS.success]: 'A session was loaded.',
    [constants.ASYNC_LOAD_STATUS.loggingOut]: 'Logging out.'
};

const _p = {
    configureLogging,
    ContractExpired,
    LicenseAgreement,
    Loading,
    SetPin,
    TenantSelection,
    Unauthenticated,
    UseCaseRoot,
    useFeatureFlags,
    useRouter,
    ConfigEntry,
    CONFIG_ENTRY_DEBOUNCE_MS: 1500
};
export const _private = _p;
/**
 * Responsible for
 * 1. enforce SSL
 * 2. Authentication (& group) verification / redirection
 * 3. guide between "FIXED"/basic routes
 * 4. render use case
 * Note, the actual use case orchestration (rules engine, offline/service worker, persistence setup) happens in `UseCaseRoot`
 */
export default function Backbone() {
    // 1. enforce SSL. Do that here, as the parent (App.js) sets up the react-router Router that we use here
    const router = _p.useRouter();
    router.ensureSslIfDeployed();
    // Provided by the SessionProvider (which is a parent of this component)
    const session = useContext(contexts.SessionContext);

    const offlineLoginsFF = _p.useFeatureFlags()?.includes('OfflineLogins');

    /**
     * @type {import('../../../../types').Session}
     */
    const {
        profile,
        eulaAccepted,
        mlaAccepted,
        tenant,
        groupNumber,
        status: sessionStatus,
        sessionError,
        useOfflinePin,
        setSessionMayStart,
        contractExpired
    } = session ?? {};
    const sessionMessage = messagesByStatus[sessionStatus];

    // Allow config entry if user clicks 5 times on the spinner
    const [spinnerClickCount, setSpinnerClickCount] = useState(0);
    const [configVisible, setConfigVisible] = useState(false);
    const [waitingForSpinnerClicks, setWaitingForSpinnerClicks] = useState(true);

    const tryShowingConfigEntry = useDebounce(
        () => {
            if (spinnerClickCount > 4) {
                logging.debug('[Backbone] Spinner clicked 5 times. Showing config entry.');
                setConfigVisible(true);
            } else {
                logging.debug(
                    '[Backbone] Not rendering config entry.  Spinner clicked ',
                    spinnerClickCount,
                    ' times. Not showing config entry.'
                );
                setWaitingForSpinnerClicks(false);
                setSessionMayStart(true);
            }
        },
        [spinnerClickCount, setSessionMayStart],
        _p.CONFIG_ENTRY_DEBOUNCE_MS
    );

    useEffect(() => {
        tryShowingConfigEntry();
    }, [tryShowingConfigEntry]);

    const onSpinnerClick = useCallback(() => {
        setSpinnerClickCount(spinnerClickCount => {
            logging.debug('[Backbone] Spinner clicked ', spinnerClickCount + 1, ' times');
            return spinnerClickCount + 1;
        });
    }, []);

    const onConfigClose = useCallback(() => {
        logging.debug('[Backbone] Config closed');
        setConfigVisible(false);
        setSpinnerClickCount(0);
        setWaitingForSpinnerClicks(false);
        setSessionMayStart(true);
    }, [setSessionMayStart]);

    // Provides router methods outside of react (e.g. in lib_ui-services).
    useEffect(() => {
        getRouter.init(router);
    }, [router]);

    const isReady = tenant != null && eulaAccepted && mlaAccepted;

    // Add session information to logs to aid in debugging
    useEffect(() => {
        _p.configureLogging(session);
    }, [session]);

    // Remove old redirect service worker - this useEffect can probably be removed after a year or so.
    useEffect(() => {
        registerServiceWorker.unregisterRedirectWorker();
    }, []);

    //3. Switch between "FIXED"/basic routes (for login etc) and "the rest" (As part of the use case)
    useEffect(() => {
        if (profile == null && !useOfflinePin) {
            return;
        }
        const currentLocation = router.history.location.pathname;

        // The rootRoute will be '/' before the tenant is selected because we cannot infer the group without
        // knowing the tenant.
        const rootRoute = router.getRoot(groupNumber);

        // If the contract has expired, redirect to the contract expired page
        if (contractExpired) {
            if (currentLocation !== `${rootRoute}contractExpired`) {
                router.goToLocation(`${rootRoute}contractExpired`);
            }
            return;
        } else if (useOfflinePin) {
            if (currentLocation !== `${rootRoute}offlinePin`) {
                router.goToLocation(`${rootRoute}offlinePin`);
            }
            return;
            //if we _have_ a profile, but they need to select a tenant, or accept a license agreement, display that.
        } else if (tenant == null) {
            if (currentLocation !== `${rootRoute}select`) {
                router.goToLocation(`${rootRoute}select`);
            }
            return;
        } else if (!eulaAccepted || !mlaAccepted) {
            if (currentLocation !== `${rootRoute}licenseAcceptance`) {
                router.goToLocation(`${rootRoute}licenseAcceptance`);
            }
            return;
        } else if (offlineLoginsFF && profile.offlinePin == null) {
            if (currentLocation !== `${rootRoute}setPin`) {
                router.goToLocation(`${rootRoute}setPin`);
            }
            return;
        } else {
            // If the profile just selected a usecase, accepted a license or entered a correct PIN, then
            // we are on a path that is no longer valid, but looks like the group (at least is correct).
            if (
                [
                    `${rootRoute}select`,
                    `${rootRoute}licenseAcceptance`,
                    `${rootRoute}offlinePin`,
                    `${rootRoute}contractExpired`
                ].includes(currentLocation)
            ) {
                //Just navigate to the group root
                router.replaceCurrentLocation(rootRoute);
                return;
            } else if (
                ['/select', '/licenseAcceptance', '/offlinePin', '/setPin', '/contractExpired'].some(path =>
                    currentLocation.endsWith(path)
                )
            ) {
                // looks like we are on a invalid path AND group, so redirect to the group root
                router.redirectToGroupRoot(groupNumber);
                return;
            }
        }

        //if we have a logged in profile, but they navigated (e.g. bookmarked) a path that that expects none, then redirect to root
        if (profilelessPaths.includes(currentLocation)) {
            // group verification / redirection, now that we have a profile, we should be able to figure out if their group matches our path
            router.redirectToGroupRoot(groupNumber);
            return;
        }
        if (currentLocation === '/') {
            router.redirectToGroupRoot(groupNumber);
            return;
        }
        //noop if we are at the right group
        router.ensureProperGroup(groupNumber);
    }, [router, eulaAccepted, mlaAccepted, tenant, groupNumber, profile, useOfflinePin, contractExpired, offlineLoginsFF]);

    const [subscribe] = useEventSink();

    // Listen for programmatic route change requests and update the app route
    // user-initiated route changes (e.g. nav menu clicks) do NOT go through here.
    useEffect(() => {
        let openActionType = { verb: 'redirect', namespace: 'application', relation: 'route' };
        const unsubscribes = [
            subscribe(openActionType, payload => {
                if (payload.redirectFinishedAction != null) {
                    const unsubscribe = subscribe(
                        { verb: 'navigate', namespace: 'application', relation: 'route', status: 'success' },
                        () => {
                            unsubscribe();
                            payload.redirectFinishedAction();
                        }
                    );
                }
                if (payload.reboundRoute != null) {
                    pageLoadStorage.setKey(`reboundEvents|${payload.to}`, payload.reboundRoute.reboundEvents);
                    payload.reboundRoute.reboundEvents.forEach(re => {
                        const unsubscribe = subscribe(re, () => {
                            unsubscribe();
                            router.goToLocation(payload.reboundRoute.reboundRoute);
                            // if this is a failure event, then perform the failure action (if defined)
                            if (payload.reboundRoute.failureEvents.some(fe => isEqual(fe, re))) {
                                payload.reboundRoute?.failureAction();
                            }
                            // Wait until after the activeRecord is cleared (see ActiveRecord.js).
                            setTimeout(() => {
                                pageLoadStorage.deleteKey(`reboundEvents|${payload.to}`);
                            }, 0);
                        });
                    });
                }
                router.goToLocation(payload.to);
            })
        ];
        // unsubscribe during cleanup
        return () => unsubscribes.forEach(u => u());
    }, [subscribe, router]);

    if (configVisible) {
        logging.debug('[Backbone] Rendering config entry');
        return rc(_p.ConfigEntry, { configVisible, closeConfig: onConfigClose });
    }
    if (sessionStatus !== constants.ASYNC_LOAD_STATUS.success || waitingForSpinnerClicks) {
        if (sessionError) {
            logging.error('[Backbone] ', sessionError);
        }
        return rc(_p.Loading, { message: sessionMessage, error: sessionError, onSpinnerClick });
    } else {
        const rootRoute = groupNumber
            ? `/g/${groupNumber}/`
            : router.groupNumber != null
            ? `/g/${router.groupNumber}/`
            : '/';
        // prettier-ignore
        return rc(ErrorBoundary, null,
            rc(App, null,
                rc(Switch, {},
                    rc(Route, { path: forAllGroups('/offlinePin') }, rc(_p.Unauthenticated)),
                    rc(Route, { path: forAllGroups('/select') }, rc(_p.TenantSelection)),
                    rc(Route, { path: forAllGroups('/licenseAcceptance') }, rc(_p.LicenseAgreement)),
                    rc(Route, { path: forAllGroups('/setPin') }, rc(_p.SetPin)),
                    rc(Route, { path: forAllGroups('/contractExpired') }, rc(_p.ContractExpired)),
                    isReady && rc(Route, null, rc(_p.UseCaseRoot, { currentRoute: rootRoute }))
                )
            )
        );
    }
}

//Hardcoded groups! If we use more than 2 canary groups, this will break (or better yet, needs to be updated)
//However, the likeliness that we will is small, and doesn't outweigh the complexity that would be required to make it dynamic, somehow.
const forAllGroups = path => ['', '/g/0', '/g/1', '/g/2'].map(g => `${g}${path}`);
