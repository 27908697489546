import conversions from '@sstdev/lib_epc-conversions';
import logging from '@sstdev/lib_logging';

let dispatch, scanningIntervalId, serviceContext, currentScanConfig;

const init = function init(dispatchToRulesEngine) {
    dispatch = dispatchToRulesEngine;
};

let serviceStarted = false;
function startupSensorService(config) {
    if (serviceStarted) {
        logging.debug('[RFID] Attempted to start the MOCK RFID service, but it is already started.');
    }
    serviceStarted = true;
    if (typeof config.intervalMilliseconds === 'undefined') {
        throw new Error('intervalMilliseconds config property is required.');
    }

    if (typeof config.scanType === 'undefined') {
        throw new Error('scanType config property is required.');
    }

    currentScanConfig = config;
    logging.debug('[RFID] mock service started');
    return true;
}

function stopSensorService() {
    if (!serviceStarted) {
        logging.error('[RFID]Attempted to stop the MOCK RFID service, but it is already stopped.');
    }
    serviceStarted = false;
    logging.debug('[RFID] mock service stopped');
}

function isStarted() {
    return serviceStarted;
}

async function encode(payload) {
    logging.debug('[RFID] mock encoding', payload);
    if (typeof payload.power !== 'number') {
        return Promise.reject('power not a number');
    }
    if (payload.power < 33) {
        return Promise.reject('No Tags Found');
    } else if (payload.power > 66) {
        return Promise.reject('Multiple Tags in Range');
    }
    return Promise.resolve('');
}

// ADJUST AS YOU"D LIKE FOR DEBUGGING:
// Mocked tag IDs are generated here.
// generateCount:  startDec 100000 would generate 3130303030, 3130303031
let generateCount = 2;
let startDec = 10000;
let tagIds = [];
for (let i = 0; i < generateCount; i++) {
    const newDec = startDec + i;
    tagIds.push(conversions.ascii.toHex(newDec));
}

// alternatively, you can hardcode tagIds:
// let tagIds = [
//     '70BC0000000000000000000000000000',
//     'E2801191A5030060611D95DE',
//     'E2801191A5030060611D95DE',
//     'E2801191A5030060611DB6D3',
//     'E2801191A5030060611D94DD',
//     'E2801191A5030060E290E424',
//     '7ED80000000000000000000000000000',
//     'E12345678910',
//     '00730000000000000000000000000000',
//     '313438373938373939393939393939393939393939393939393939393837393837393839373837',
//     '2E026001F00010000064000000000101'
//     // '1111',
//     // '2222'
//     // '3333',
//     // '4444',
//     // '5555',
//     // '6666',
//     // '7777',
//     // '8888',
//     // '9999',
//     // 'aaaa',
//     // 'bbbb',
//     // 'cccc',
//     // 'dddd',
//     // 'eeee'
// ];

// Typically called by rules engine, rather than direct user action through UI or physical button
async function scanDirect(_scanConfig = {}) {
    let readBuffer = [];
    const scanConfig = { ...currentScanConfig, ..._scanConfig };
    if (!Object.keys(scanConfig).length) {
        throw new Error('A scan was attempted, but no scan configuration is available.');
    }

    // 👇I'll leave this in here in case more than one tag is needed for a different scenario.
    //tagIds.forEach(rawTag => {
    const rawTag = tagIds[0];

    let hexTagId = rawTag;
    let tag = { ...scanConfig };
    tag['rssi'] = Math.random() * -100;
    tag['tagId'] = hexTagId;
    tag['_id'] = hexTagId;

    readBuffer.push(tag);
    // 👇I'll leave this in here in case more than one tag is needed for a different scenario.
    //});
    return readBuffer;
}

function scan() {
    const tagsAreHex = true;

    let tagBatch = [];
    tagIds.forEach(rawTag => {
        let hexTagId = tagsAreHex ? rawTag : conversions.ascii.toHex(rawTag, true);
        let tag = { ...currentScanConfig };
        tag['rssi'] = Math.random() * -100;
        tag['tagId'] = hexTagId;
        tag['_id'] = hexTagId;

        tagBatch.push(tag);
    });
    for (let i = 0; i < tagBatch.length / 10; i++) {
        let batch = tagBatch.slice(i * 10, i * 10 + 10);
        setTimeout(() => {
            logging.debug(`[RFID] "Scanning" ${batch.length} tags: ${batch.map(x => x.tagId).join(', ')}`);
            dispatch(batch, {
                ...serviceContext,
                verb: 'change',
                namespace: 'sensor',
                relation: 'read'
            });
        }, 0);
    }

    if (currentScanConfig.scanType === 'Continuous') {
        scanningIntervalId = setInterval(scan, currentScanConfig.intervalMilliseconds);
    }
} // Mainly for testing.

function stopScanning() {
    clearInterval(scanningIntervalId);
}

// Reenable this to work with mocked power updates
// eslint-disable-next-line
function update(payload) {
    // const { power } = payload;
    // if (power != null && !isNaN(power)) {
    //     const value = Number(power);
    //     logging.info(`[MOCK] Change of Power Requested to ${value}`);
    //     if (value <= 13) {
    //         tagIds = ['E2801191A5030060611D95DE'];
    //     } else if (value <= 50) {
    //         tagIds = ['E2801191A5030060611D95DE', 'E2801191A5030060611D95DE', 'E2801191A5030060611DB6D3'];
    //     } else {
    //         tagIds = [
    //             'E2801191A5030060611D95DE',
    //             'E2801191A5030060611D95DE',
    //             'E2801191A5030060611DB6D3',
    //             'E2801191A5030060611D94DD',
    //             'E2801191A5030060E290E424'
    //         ];
    //     }
    // }
}

export default {
    init,
    startupSensorService,
    stopSensorService,
    scan,
    stopScanning,
    isStarted,
    isReaderAvailable: () => true,
    update,
    encode,
    scanDirect
};
