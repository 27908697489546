import getHostName from './getHostname';
export default function isDeployed(productionBuild) {
    const hostname = getHostName(productionBuild);
    return !(
        (
            hostname.startsWith('192.168') ||
            hostname.startsWith('10.0.0') || // for Niki's IP Address
            hostname.startsWith('172.16.11') || //old teamcity
            hostname.startsWith('172.28.1') || //teamcity2
            hostname === 'localhost' ||
            hostname === 'redbeam' ||
            hostname === '[::1]' || // [::1] is the IPv6 localhost address.
            hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) || // 127.0.0.1/8 is considered localhost for IPv4.
            // handle local and minikube
            hostname.match(/^local\.(redbeam|sstid|minikube)\.com$/) ||
            hostname.match(/^minikube\.(redbeam|sstid)\.com$/)
        )
    );
}
