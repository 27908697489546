import format from 'date-fns/format';
import { metadata } from 'lib_ui-services';
import lodash from 'lodash';
const { cloneDeep } = lodash;
import createDataModel from './createDataModel';
import getAllFeatureFlags from '../../../../utilities/getAllFeatureFlags';

const _p = { getDictionary: metadata.getDictionary, createDataModel, fromHNodes: createDataModel.fromHNodes };
export const _private = _p;

export default {
    verb: 'willNew',
    description: 'Generate default values for the import record',
    namespace: 'import',
    relation: 'import',
    // this is the actual logic:
    logic: willNew
};

async function willNew({ data, context }) {
    const {
        hNode: { title, namespace: foreignNamespace, relation: foreignRelation, children },
        newRecord: passedInDefaultValues = {}
    } = data;

    let dataModel;
    // either we got hNodes passed in, if so, use that to build our model available for selecting columns from
    if (children) {
        dataModel = _p.fromHNodes(children, getAllFeatureFlags(context));
    } else {
        // otherwise, base the model on the complete definition in the dataDictionary
        const dictionary = await _p.getDictionary();
        const dataDictionaryRelation = dictionary[foreignNamespace][foreignRelation];
        dataModel = _p.createDataModel(dataDictionaryRelation, getAllFeatureFlags(context));
    }

    // generate a 'newRecord' property to pass in preset values.
    const newRecord = {
        title: `${title} - ${format(new Date(), 'yyyyMMdd.HHmmss')}`,
        firstRowContainsHeader: true,
        foreignNamespace,
        foreignRelation,
        // The object model that we are trying to fill with data
        dataModel,
        // the (preliminary) guess of how the fields out of the dataModel match up to the import file
        // In the future, we might want to specify a predefined format (e.g. for Flair), or perhaps the last used format.
        columns: cloneDeep(dataModel),
        ...passedInDefaultValues
    };

    return {
        newRecord
    };
}
