import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import FilterInterdependencyBoundary from '../contextProviders/FilterInterdependencyBoundary';


function FilterBoundaryLayout(props) {
    if (props == null) return null;
    const { id, title: name, children } = props;
    return rc(FilterInterdependencyBoundary, { id, name: `filter_boundary_${name}_${id}`, restoreSelectionsOnRemount: true }, children);
}

FilterBoundaryLayout.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.array,
    hNode: PropTypes.object.isRequired
};

export default FilterBoundaryLayout;
