// @ts-check
import { pageLoadStorage } from 'lib_ui-services';

const _p = {
    getKey: pageLoadStorage.getKey,
    setKey: pageLoadStorage.setKey,
    deleteKey: pageLoadStorage.deleteKey,
    clear: pageLoadStorage.clear
};
export const _private = _p;

/**
 * just a passthrough to localStorage, making sure the method signatures are compatible with the other storage providers
 * @type {import('../BbState').Storage}
 */
export default {
    isAsync: false,
    get: async function get(key, defaultValue) {
        return _p.getKey(key, defaultValue);
    },
    set: async function set(key, value) {
        return _p.setKey(key, value);
    },
    remove: async function remove(key) {
        return _p.deleteKey(key);
    },
    clear: async function clear() {
        return _p.clear();
    },
    getSync: function getSync(key, defaultValue) {
        return _p.getKey(key, defaultValue);
    }
};
