import { createElement as rc, Fragment } from 'react';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import lodash from 'lodash';
const { get } = lodash;
import conversions from '@sstdev/lib_epc-conversions';
import { Link, Icon, Text, styled, webOnlyStyles, fromTheme } from 'lib_ui-primitives';
import { nativeOnlyProperties, valueUtilities } from 'lib_ui-services';
import ButtonColumn from './ButtonColumn';
import PatchDetailTable from './PatchDetail/PatchDetailTable';
import LinkColumn from './LinkColumn';
import getNestedDetailValue from './getNestedDetailValue';
import getNestedPatchDetailColumnHNodes from './PatchDetail/getNestedPatchDetailColumnHNodes';

let ColumnWidth = styled(Text).attrs(props => ({
    title: typeof props.children === 'string' ? props.children : undefined,
    name: 'column',
    ...nativeOnlyProperties({ numberOfLines: 2, ellipsizeMode: 'tail' })
}))`
    width: ${({ width }) => width + 'px'};
    border-left-width: 0px;
    border-left-color: ${fromTheme('gridLineColor')};
    text-align: left;
    overflow: hidden;
    padding-left: ${fromTheme('textPadding')};
    padding-right: ${fromTheme('textPadding')};
`;
let ColumnNoWidth = styled(Text).attrs(props => ({
    title: typeof props.children === 'string' ? props.children : undefined,
    name: 'column',
    ...nativeOnlyProperties({ numberOfLines: 2, ellipsizeMode: 'tail' })
}))`
    border-left-width: 0px;
    border-left-color: ${fromTheme('gridLineColor')};
    text-align: left;
    overflow: hidden;
    padding-left: ${fromTheme('textPadding')};
    padding-right: ${fromTheme('textPadding')};
`;

ColumnNoWidth = webOnlyStyles(ColumnNoWidth)`
    border-left-style: solid;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

ColumnWidth = webOnlyStyles(ColumnWidth)`
    border-left-style: solid;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

/**
 * Returns a React component representing a cell in a column based on the provided properties.
 *
 * @param {Object} params - Parameters for the cell.
 * @param {Object} params.record - The data record for the cell.
 * @param {Object} params.columnHNode - Configuration object for the column.
 * @param {Object} [params.style] - Style object for custom styling.
 * @param {boolean} [params.skipUndefined=false] - Whether to skip rendering if the value is undefined.
 * @param {Function} [params.platformSpecificValueLookup] - Function to look up platform-specific values.
 * @param {number} [params.width] - Width of the column.
 * @param {Function} [params.setWidth] - Function to set the width of the column.
 * @param {Array} [params.featureFlags] - Feature flags for conditional rendering.
 * @param {Object} [params.activeRecord] - Active record data.
 * @param {Object} [params.splashRecord] - Splash record data.
 * @param {Object} [params.navigationSelection] - Navigation selection data.
 * @param {string} [params.key] - Key for the cell.
 * @returns {React.Component|null} - A React component representing the cell or null if conditions are not met.
 */
export default function getColumnCell({
    record,
    columnHNode,
    style,
    skipUndefined = false,
    platformSpecificValueLookup,
    //theme,
    width,
    setWidth,
    featureFlags,
    activeRecord,
    splashRecord,
    navigationSelection,
    key
}) {
    if (!columnHNode || !record) return;

    if (columnHNode.dataType === 'button') {
        return rc(ButtonColumn, { width, hNode: columnHNode, record, key });
    }

    let Column;
    if (setWidth) {
        Column = ColumnWidth;
    } else {
        Column = ColumnNoWidth;
    }

    let columnLabel = columnHNode.children ? columnHNode.children[0].label : columnHNode.label;
    const testAttrs = {
        'data-testid': `column-${columnHNode.propertyName}`,
        'data-columnlabel': columnLabel
    };

    let value = get(
        record,
        columnHNode.propertyPath ? `${columnHNode.propertyPath}.${columnHNode.propertyName}` : columnHNode.propertyName
    );
    if (columnHNode.displayProperties?.length) {
        let prefix = [columnHNode.propertyPath, columnHNode.namespace, columnHNode.relation].filter(Boolean).join(':');
        if (prefix) prefix += '.';

        value = columnHNode.displayProperties
            .map(property => get(record, `${prefix}${property}`))
            .filter(Boolean)
            .join(', ');
    }

    if (skipUndefined && typeof value === 'undefined') return;
    if (!value) {
        if (columnHNode.flagOnValue === 'true' || columnHNode.flagOnValue === 'false') {
            value = 'false';
        }
    }

    // Some display values must be rendered by components that are platform specific
    // (such as images (base64 column.dataType)).
    if (platformSpecificValueLookup) {
        // TODO: instead of platformSpecificValueLookup use .native/.browser or whatever
        throw new Error('not implemented');
        // let platformValue = platformSpecificValueLookup(column.dataType, value);
        // if (typeof platformValue !== 'undefined') return platformValue;
    }
    if (value == null && columnHNode.dataType !== 'mappedBoolean') {
        return rc(Column, { width, ...testAttrs, key }, '');
    }
    // Most datatypes can just be converted to some string format.
    switch (columnHNode.dataType) {
        case 'text':
            if (typeof value === 'number') {
                value = value.toString();
            }
            if (typeof value === 'boolean') {
                value = value.toString();
            }
            if (typeof value !== 'string') {
                throw new Error(
                    `A text column cell for ${columnHNode.propertyName} was passed ${typeof value} instead of text.`
                );
            }
            if (Array.isArray(value)) {
                value = value.join(', ');
            }
            return rc(Column, { width, style, ...testAttrs, key }, value);
        case 'dateTime':
            if (columnHNode.format === 'ISO') return rc(Column, { width, style, key }, formatISO(new Date(value)));
            return rc(Column, { width, style, ...testAttrs, key }, formatDate(value, columnHNode.format));
        case 'localShortDate':
            return rc(Column, { width, style, ...testAttrs, key }, formatDate(value, 'P'));
        case 'localVerboseDateTime':
            return rc(Column, { width, style, ...testAttrs, key }, formatDate(value, 'PP p'));
        case 'encodedText':
            return rc(
                Column,
                { width, style, ...testAttrs, key },
                featureFlags?.includes('displayInAscii') ? conversions.ascii.fromHex(value) : value
            );
        case 'mappedBoolean':
            if (value == null) {
                return rc(Column, { width, style, ...testAttrs, key }, '');
            } else if (columnHNode.mappedTrueValue || columnHNode.mappedFalseValue) {
                return rc(
                    Column,
                    { width, style, ...testAttrs, key },
                    value ? columnHNode.mappedTrueValue || '' : columnHNode.mappedFalseValue || ''
                );
            } else {
                return rc(Column, { width, style, ...testAttrs, key }, value ? 'True' : 'False');
            }
        case 'gpsCoordinates':
            return getMapsUrl(value, columnHNode, testAttrs);
        case 'nestedDetail': {
            let text = getNestedDetailValue(value, columnHNode, {
                featureFlags,
                activeRecord,
                splashRecord,
                navigationSelection
            });
            if (typeof text === 'number') {
                text = text.toString();
            }
            if (typeof text !== 'string') {
                throw new Error(
                    `A nested detail column cell for ${columnHNode.propertyName} contained something other than text.`
                );
            }
            return rc(Column, { width, style, ...testAttrs, key }, text);
        }
        case 'patchDetail': {
            if (columnHNode.asCardBody) {
                return rc(PatchDetailTable, {
                    parentRecordId: record._id,
                    patches: value,
                    width,
                    style,
                    hNode: columnHNode,
                    ...testAttrs,
                    key
                });
            }
            const subColumns = getNestedPatchDetailColumnHNodes();
            return rc(
                Fragment,
                null,
                subColumns.map(subColumn =>
                    getColumnCell({
                        record: value[0],
                        columnHNode: subColumn,
                        style,
                        skipUndefined,
                        platformSpecificValueLookup,
                        //theme,
                        width: subColumn.width,
                        setWidth,
                        featureFlags,
                        activeRecord,
                        splashRecord,
                        navigationSelection,
                        key: `${key || record._id}-${subColumn.id}`
                    })
                )
            );
        }
        case 'currency':
            return rc(Column, { width, style, ...testAttrs, key }, valueUtilities.formatCurrency(value));
        case 'link': {
            return rc(LinkColumn, { record, width, style, hNode: columnHNode, Column, ...testAttrs, key });
        }
        default:
            throw new Error(`Unknown data type for column ${columnHNode.label}`);
    }
}

function getMapsUrl(value, column, props) {
    /* URL parameters:
        q=latN+lonW+(label)     location of teardrop
        t=k             keyhole (satellite map)
        t=h             hybrid
        ll=lat,-lon     center of map
        spn=w.w,h.h     span of map, degrees ex: &spn=0.004250,0.011579
        hl=en           language
    */
    // &iwloc=A     something to do with info window
    const url = `http://maps.google.com/maps?q=${value.firstCoordinate}+${value.secondCoordinate}+(label)&ll=${value.firstCoordinate},${value.secondCoordinate}&t=h&hl=en&z=19`;

    // prettier-ignore
    return rc(Link, { href: url, target: '_blank' },
        rc(Icon, { className: 'material-icons', title: 'Show in maps', style: { color: '#06a7e2' }, ...props },
            column.iconName
        )
    );
}

function formatDate(value, desiredFormat) {
    return value == '' || value == null ? '' : format(new Date(value), desiredFormat);
}
