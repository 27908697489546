export default {
    verb: 'doingUpdate',
    namespace: 'security',
    relation: 'profile',
    status: 'success',
    featureFlag: 'OfflineLogins',
    description: 'close the profile dialog on update success',
    //this is the actual logic:
    logic: updateSuccess
};

async function updateSuccess({ dispatch, context }) {

    const { namespace, relation } = context;
    dispatch({}, { verb: 'cancel', namespace, relation });

}
