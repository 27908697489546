import filterHelper from './filterHelpers';
// Because this is only used by lokijs dynamicViews (as opposed to a paged database get),
// most of this is dependent on the navigationSelection context boundary which is passed in via
// the contextualInfo parameter on fromHNode() below.
export default {
    getFilter,
    fromHNode,
    getUriComponent,
    getMql,
    getJavaScriptFilter
};

function fromHNode(hNode, contextualInfo) {
    const { namespace, relation, criteriaProperty = 'criteria', propertyName } = hNode;
    const {
        available = false,
        loading,
        namespace: selectionNamespace,
        relation: selectionRelation,
        record
    } = contextualInfo?.navigationSelection || {};

    // too early to filter, or nothing selected
    if (!available || loading) return;
    // selection is not for us.
    if (selectionNamespace !== namespace || selectionRelation !== relation) return;

    /*
     * record will look something like:
     * {
     *   criteria: {
     *     cost: {
     *       _gt: 1000
     *     }
     *   }
     * }

     */
    const key = propertyName;
    const criteria = record?.[criteriaProperty]?.find(c => c[key]);
    if (!criteria || !Object.keys(criteria).length || !Object.keys(criteria[key]).length) return;

    const op = Object.keys(criteria[key])[0];
    let value = criteria[key][op];
    if (typeof value === 'undefined') return;
    return getFilter(propertyName, op.replace('_', '$'), value);
}

function getFilter(propertyName, op, value) {
    return {
        propertyName,
        op,
        value
    };
}

function getMql(filters) {
    if (filters.byNavigationSelectionCriteria == null) return;
    const { propertyName, op, value } = filters.byNavigationSelectionCriteria;
    return { [propertyName]: { [op]: value } };
}

function getUriComponent(filters) {
    const filter = filters['byNavigationSelectionCriteria'];
    const { propertyName, op, value } = filter;
    const criteria = { [propertyName]: { [op]: value } };
    return {criteria};
}

function getJavaScriptFilter(filter) {
    return filterHelper.basicFilterToJavaScript(filter);
}
