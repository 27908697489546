import logging from '@sstdev/lib_logging';
import { BbState } from 'lib_ui-primitives';
export const _private = { BbState };

export default {
    verb: 'didUpsert',
    namespace: 'identity',
    relation: 'tenantSetting',
    description:
        'proactively Update the tenantSettings used throughout the app while waiting for an update to come in over sockets',
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data }) {
    try {
        const record = data.items[0];
        if (record == null) {
            return;
        }
        await _private.BbState.loadTenantSettings(record);
    } catch (err) {
        logging.error(err);
    }
}
