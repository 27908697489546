import logging from '@sstdev/lib_logging';
import lodash from 'lodash';
const { omit, cloneDeep } = lodash;
import { globalConfig, constants } from 'lib_ui-services';

let idMap = {};
export default async function combineMetadata(
    namespaces,
    profileMenu,
    navigation,
    pages,
    { includeSplashSelection = false, includeSideNav = true, title } = {},
    dispatch
) {
    const transforms = await import('@sstdev/lib_metadata-transforms');
    //create the basic useCaseVersion record
    let useCaseVersion = {};

    const notificationHandler = (message, blockId, overrides = {}) => {
        dispatch(
            {
                message,
                blockId,
                timeout: globalConfig().notificationTimeoutFormError,
                addToList: true,
                isError: true,
                ...overrides
            },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
    };
    idMap = {};
    useCaseVersion.namespaces = namespaces.map(ns => ({
        title: ns.title,
        //any new properties on namespaces need to be explicitly included here,
        //because the namespace records have much more junk on it as far as we are concerned here.
        prettyName: ns.prettyName,
        withCustomApi: ns.withCustomApi,
        hideApiDocs: ns.hideApiDocs,
        relations: ns.relations.map(rel => {
            const { methodAccess, ...rest } = omit(rel, ['offlineSync', 'noOfflineChanges', 'patchRelationType']);

            return {
                limitSyncSize: rel.title.endsWith('-patch'),
                //any additional properties on relations get automatically passed through
                ...rest,
                methodAccess: methodAccess
                    .map(ma => {
                        if (!ma['identity:role']) {
                            return null;
                        }
                        const {
                            'identity:role': { title: role },
                            ...access
                        } = ma;

                        return { role, get: true, patch: false, post: false, put: false, remove: false, ...access };
                    })
                    //Used at least in some places in Rules Engine:
                    .concat({
                        role: 'DEITY',
                        get: true,
                        patch: true,
                        post: true,
                        put: true,
                        remove: true
                    })
                    .filter(x => !!x)
            };
        })
    }));

    //convert the profile menu blocks into topmenu metadata
    if (constants.acceptableTopProfileBlocks.includes(profileMenu?.blockly?.blocks?.blocks?.[0]?.type)) {
        // backward compatibility for embedded new menu
        // keep the old menu as before (it gets ignored by the new menu code)
        const embeddedNewMenu = profileMenu?.blockly?.blocks?.blocks?.[0]?.next?.block;
        // if we don't clone it here, we will loose the 2nd block on the profileMenu page, until we reload.
        profileMenu = cloneDeep(profileMenu);
        if (embeddedNewMenu) {
            delete profileMenu?.blockly?.blocks?.blocks?.[0]?.next;
        }
        const topMenuMetadata = await transforms.getMetaDataFromWorkspace(
            profileMenu.blockly,
            'metadata:menu',
            namespaces,
            notificationHandler
        );
        if (topMenuMetadata.status === 'fail') {
            // Don't allow the rules engine rule to complete.  Just fail (error) out of it without reporting anything else
            // to the user.
            throw new Error(
                `Failed to generate metadata for profile menu: ${topMenuMetadata.result.message}. This is probably due to a previously reported error for this operation.`
            );
        }
        if (topMenuMetadata.result.hNodeType === 'MeatballProfileLayout') {
            //backwards compatible group:
            topMenuMetadata.result.hNodeTypeGroup = 'layout';
        }
        // Backwards Compatibility
        useCaseVersion.topMenu = [topMenuMetadata.result];
        // New menu as of April 2024
        useCaseVersion.topMenuV2 = [topMenuMetadata.result];
        //end of backward compatibility for embedded new menu
        // so, now add the new menu
        if (embeddedNewMenu && embeddedNewMenu.enabled !== false) {
            profileMenu.blockly.blocks.blocks[0] = embeddedNewMenu;
            const newMenuMetadata = await transforms.getMetaDataFromWorkspace(
                profileMenu.blockly,
                'metadata:menu',
                namespaces,
                notificationHandler
            );
            if (newMenuMetadata.status === 'fail') {
                // Don't allow the rules engine rule to complete.  Just fail (error) out of it without reporting anything else
                // to the user.
                throw new Error(
                    `Failed to generate metadata for profile menu: ${newMenuMetadata.result.message}. This is probably due to a previously reported error for this operation.`
                );
            }
            useCaseVersion.topMenuV2 = [newMenuMetadata.result];
        }
    } else {
        notificationHandler(
            `Profile menu root block type not allowed: ${profileMenu?.blockly?.blocks?.blocks?.[0]?.type}`
        );
        throw new Error(`Profile menu root block type not allowed: ${profileMenu?.blockly?.blocks?.blocks?.[0]?.type}`);
    }

    //now the actual content:
    //take a reference to the object on the useCaseVersion Record, so we can mess with that reference is we need to nest it all in a splash layout
    useCaseVersion.hNodes = {};
    let appLayoutRef = useCaseVersion.hNodes;
    if (includeSplashSelection) {
        //TODO We have a hack. we might need to implement something better.
    }

    appLayoutRef.id = navigation._id;
    appLayoutRef.hNodeType = 'to be replaced';
    appLayoutRef.hNodeTypeGroup = 'appLayout';
    appLayoutRef.title = title;
    if (includeSideNav) {
        appLayoutRef.hNodeType = 'LeftSideNavAppLayout';
        //build up the nav tree.
        appLayoutRef.children = [
            {
                id: navigation._id + '-sn',
                hNodeType: 'SideNav',
                hNodeTypeGroup: 'nav',
                children: await Promise.all(
                    navigation.children.map(nav =>
                        navigationToNavHeading(nav, pages, namespaces, notificationHandler, transforms)
                    )
                )
            }
        ];
    } else {
        appLayoutRef.hNodeType = 'NoNavAppLayout';
        appLayoutRef.children = await Promise.all(
            pages.map(
                async page =>
                    (
                        await transforms.getMetaDataFromWorkspace(
                            page.blockly,
                            'metadata:page',
                            namespaces,
                            notificationHandler
                        )
                    ).result
            )
        );
    }
    return useCaseVersion;
}

async function navigationToNavHeading(navigation, pages, namespaces, notificationHandler, transforms) {
    const {
        _id,
        title,
        children,
        isTabContainer,
        isTabNavHeading,
        isMenuList,
        iconName,
        iconColor,
        ...accessModifiers
    } = navigation;

    if (!children) {
        // if no sub-navs, this likely is a page
        const page = pages.find(p => p._id === _id);
        // there are some menu items that are special, like "/logout" that don't have a backing page
        // those will be treated as menu entries below
        //if there IS a page, generate the full page metadata and nest it into the metadata.
        if (page) {
            logging.info(`[METADATA] loading page ${title}`);
            const { result: pageMetadata } = await transforms.getMetaDataFromWorkspace(
                page.blockly,
                'metadata:page',
                namespaces,
                notificationHandler
            );
            if (pageMetadata.stack) {
                const error = new Error(`Generating page ${title}failed: ${pageMetadata.message}`);
                error.stack = pageMetadata.stack;
                throw error;
            }

            if (idMap[pageMetadata.id] && idMap[pageMetadata.id] !== title) {
                notificationHandler(
                    `Duplicate id ${pageMetadata.id} found in navigation tree. Title: ${title}, previous title: ${
                        idMap[pageMetadata.id]
                    }`
                );
            } else {
                idMap[pageMetadata.id] = title;
            }

            if (isMenuList) {
                const filters = pageMetadata.children.filter(c => c.hNodeTypeGroup === 'filter');
                //If this is a menu List (navigation Selected Context Page), we need a navHeading
                // which is what opens/collapses.
                let result = {
                    id: _id,
                    title,
                    hNodeType: 'NavHeading',
                    hNodeTypeGroup: 'navHeading',
                    iconName,
                    iconColor,
                    // Within that we need a navHeadingList, which generates the list of referenced records in the menu
                    children: [
                        {
                            id: _id + '-rl',
                            hNodeType: 'NavHeadingRecordList',
                            hNodeTypeGroup: 'navHeading',
                            namespace: pageMetadata.namespace,
                            relation: pageMetadata.relation,
                            children: [
                                ...filters,
                                //And finally a NavigationSelectionContext to provide the context
                                //for the original page content.
                                {
                                    id: _id + '-context',
                                    hNodeType: 'NavigationSelectionContext',
                                    hNodeTypeGroup: 'contextProviders',
                                    namespace: pageMetadata.namespace,
                                    relation: pageMetadata.relation,
                                    children: pageMetadata.children
                                }
                            ]
                        }
                    ]
                };
                //return the NavHeading with all the access modifiers on it.
                return addAccessModifiers(result, accessModifiers);
            } else {
                //MD3.0C calls it page_Page, previously, it was a navHeading_NavHeading.
                //for now, we want to stay backwards compatible to reduce the implementation effort for the old stuff
                //anything new, we can return as is.
                return {
                    ...pageMetadata,
                    hNodeType: isTabNavHeading ? 'TabNavHeading' : 'NavHeading',
                    hNodeTypeGroup: 'navHeading'
                };
            }
        } else {
            logging.warn(`[METADATA] Processing ${title} as nav link.`);
            notificationHandler(
                `NOTE: Processing menu '${title}' as navigation link (or is there a missing page?).`,
                null,
                {
                    addToList: false,
                    isError: false
                }
            );
        }
    }

    logging.info(`[METADATA] processing menu entry ${title}`);
    let result = {
        id: _id,
        title,
        hNodeType: 'NavHeading',
        hNodeTypeGroup: 'navHeading',
        iconName,
        iconColor
    };
    if (children?.length) {
        const generatedChildren = await Promise.all(
            children.map(child =>
                navigationToNavHeading(
                    isTabContainer ? { ...child, isTabNavHeading: true } : child,
                    pages,
                    namespaces,
                    notificationHandler,
                    transforms
                )
            )
        );
        //if this is a tabContainer, we actually need a separate block in between this block (which is the menu entry)
        //and the actual tabs (the tabNavHeadings). That block  is really the content container that holds the tabs:
        if (isTabContainer) {
            result.children = [
                {
                    id: _id + '-tn',
                    hNodeType: 'TabNav',
                    hNodeTypeGroup: 'nav',
                    children: generatedChildren
                }
            ];
        } else {
            result.children = generatedChildren;
        }
    }

    return addAccessModifiers(result, accessModifiers);
}

function addAccessModifiers(result, accessModifiers) {
    return Object.entries(accessModifiers).reduce((result, [key, values]) => {
        if (!values || !Array.isArray(values)) {
            logging.warn(`Access Modifier ${key} does not have an array as value, which was expected. Excluding...`);
            return result;
        }
        const redactionExpectedKey = keyMap[key] || key;
        return { ...result, [redactionExpectedKey]: values.map(value => Object.values(value)[0]) };
    }, result);
}

const keyMap = {
    addForFeatureFlag: 'addForFeatureFlags',
    removeForFeatureFlag: 'removeForFeatureFlags',
    showForViewPort: 'showForViewport'
};
