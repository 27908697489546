import notHandledHere from './notHandledHere';
import { database } from 'lib_ui-services';

export default {
    verb: 'doingPurge',
    excludedNamespaceRelations: notHandledHere,
    description: 'Remove the requested record from the database',
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data, context }) {
    if (data.handledBySync) {
        return;
    }
    const db = database.get();
    let result;
    if (context.type === 'all') {
        result = await db.deleteAllDatabases(data, context);
    } else {
        result = await db.deleteDatabase(data, context);
    }
    return { ...data, result };
}
