import { constants, globalConfig } from 'lib_ui-services';

export default {
    verb: 'didCreate',
    namespace: 'transaction',
    description: 'display confirmation of transaction success',
    useCaseIds: [constants.useCaseIds.REDBEAM_INVENTORY_TRACKING],
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'stock',
                type: 'find'
            },
            query: async ({ data }) => {

                let { 'item:item': item } = data.newRecord;

                let locationIds = [];

                if (data.newRecord.receive?.['location:location']) {
                    locationIds.push(data.newRecord.receive['location:location']._id);
                }
                if (data.newRecord.issue?.['location:location']) {
                    locationIds.push(data.newRecord.issue['location:location']._id);
                }
                if (!locationIds.length) {
                    throw new Error('Location not found.');
                }

                return {
                    'item:item._id': item._id,
                    'location:location._id': {$in: locationIds},
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    //this is the actual logic:
    logic: didCreate
};

/**
 * @param {object} params
 * @param {any} params.data
 * @param {Array<{result:[]}>} params.prerequisiteResults
 * @param {(data:object, context:Context, awaitResult?:boolean)=>Promise<void|any>} params.dispatch
 * @param {import('../../../extendedRulesArrayRepository').Context} params.context
 */
async function didCreate({ prerequisiteResults, dispatch }) {
    const [
        { result: newStock = [] }
    ] = prerequisiteResults;

    if (newStock.length === 0) {
        throw new Error('No stock found.');
    }

    let messages = [];

    for (const stock of newStock) {
        const {
            'item:item': { assetNo},
            'location:building': building,
            'location:location': location,
            quantity
        } = stock;

        messages.push(`${building.title}-${location.title} now has ${quantity} of Item ${assetNo}. `);
    }

    await dispatch(
        {
            isError: false,
            message: messages.join('\n'),
            timeout: globalConfig().notificationTimeout
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );

}