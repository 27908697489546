import getFilter from '../filterFactory';
import { getRelationMetadata } from '../metadata';
import lodash from 'lodash';
const { merge } = lodash;

const _p = { getRelationMetadata, getFilter };
export const _private = _p;
export default function getQueryUrl(namespace, relation, requestFilters, correlationId) {
    let baseUrl = `/api/${namespace}/${relation}`;
    if (!requestFilters || Object.keys(requestFilters).length === 0) {
        return baseUrl;
    }
    if (requestFilters.aggregateType) {
        baseUrl += '/aggregate';
    }
    if (requestFilters.fullTextSearch) {
        baseUrl += '/search';
    }

    /**
     * @type {string[]}
     */
    let filterPieces = [];
    let filterObject = {};
    const { limitSyncSize } = _p.getRelationMetadata(namespace, relation);

    Object.keys(requestFilters).forEach(filterName => {
        // eslint-disable-next-line import/namespace
        let filter = _p.getFilter(filterName);
        if (filter.getUriComponent) {
            let piece = filter.getUriComponent(requestFilters, limitSyncSize);
            if (typeof piece === 'string') {
                if (!piece || piece.length === 0) return; // skip empty parameters.
                filterPieces.push(piece);
            } else if (typeof piece === 'object') {
                if (Object.keys(piece).length === 0) return; // skip empty parameters.
                // the primary type of data being returned here is likely an object with a "criteria" property
                // but making this generic to make it future proof.
                const { criteria, ...rest } = piece;
                if (criteria) {
                    // if we have criteria, we need to merge it with the existing criteria, in a way that doesn't break logic (like merging 2 $ors)
                    if (filterObject.criteria) {
                        if (filterObject.criteria.$and) {
                            filterObject.criteria.$and.push(criteria);
                        } else {
                            filterObject.criteria = { $and: [filterObject.criteria, criteria] };
                        }
                    } else {
                        filterObject.criteria = criteria;
                    }
                }
                filterObject = merge(filterObject, rest);
            }
        }
    });
    Object.keys(filterObject).forEach(key => {
        filterPieces.push(`${key}=${encodeURIComponent(JSON.stringify(filterObject[key]))}`);
    });

    if (filterPieces.length > 0) {
        if (correlationId) {
            return `${baseUrl}?${filterPieces.join('&')}&correlationId=${encodeURIComponent(correlationId)}`;
        }
        return `${baseUrl}?${filterPieces.join('&')}`;
    }
    return baseUrl;
}
