import encryption from '@sstdev/lib_encryption';
import { patch } from 'lib_ui-services/src/http/index.SwitchToAuth0';
import { getProfile, saveProfile } from 'lib_ui-services/src/session/sessionCache';

const _p = {
    patch,
    getProfile,
    saveProfile
};
export const _private = _p;

async function UpdateServerWithNewPin(offlinePin, salt, userId) {

    const url = `/api/identity/user/${userId}`;
    const payload = [
        { op: 'replace', path: '/offlinePin', value: offlinePin },
        { op: 'replace', path: '/salt', value: salt },
        { op: 'remove', path: '/failedAttempts' }
    ];

    try {
        await _p.patch(url, payload);

        let profile = await _p.getProfile(false);

        await _p.saveProfile({ ...profile, offlinePin, salt }, true);

        return { success: true };

    } catch (error) {
        return { success: false, error };
    }
}

async function EncryptPin(pin) {
    const salt = Math.floor(Math.random() * 100000).toString();
    const encryptedPin = await encryption.bcrypt.encrypt(pin.toString() + salt);

    return { encryptedPin, salt };
}

export { UpdateServerWithNewPin, EncryptPin };