export async function PromiseImmediate(func) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                const result = func();
                resolve(result);
            } catch (err) {
                reject(err);
            }
        }, 0);
    });
}

export async function wait(interval) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, interval);
    });
}

// create a queue for a given async function.  If the function is already running,
// the new call will be queued and will not be run until the previous call is complete.
export function createAsyncQueue(func) {
    let running = false;
    const queue = [];
    const run = async function run() {
        if (running) {
            return;
        }
        try {
            running = true;
            while (queue.length > 0) {
                const { resolve, reject, args } = queue.shift();
                try {
                    const result = await func(...args);
                    resolve(result);
                } catch (err) {
                    reject(err);
                }
            }
        } finally {
            running = false;
        }
    };
    return async function (...args) {
        const prom = new Promise((resolve, reject) => {
            queue.push({ resolve, reject, args });
        });
        // Go to the end of the stack.
        setTimeout(run, 0);
        return prom;
    };
}
