import { createElement as rc, useMemo } from 'react';
import PropTypes from 'prop-types';
import { View } from 'lib_ui-primitives';
import { metadata } from 'lib_ui-services';
import useDbView from '../../hooks/useDbView';
import useIsInActiveRoute from '../../hooks/useIsInActiveRoute';
const { getPrettyRelationName } = metadata;

import {
    NoShrink,
    DisabledNavHeader,
    NavHeaderLink,
    Noncollapsible,
    ActiveNavHeaderLink,
    LinkText,
    DisabledLinkText
} from './styles';

const _p = {
    getPrettyRelationName,
    useDbView
};

export const _private = _p;

const ELLIPSES = '…';
const MAX_TITLE_LENGTH = 20;

function NavHeadingRecordList(props) {
    const {
        id: _id,
        currentRoute,
        hNode,
        hNode: { namespace, relation }
    } = props || { hNode: {} };
    const id = _id || hNode.id;

    const disabledMessage = useMemo(() => {
        const prettyRelationName = _p.getPrettyRelationName(namespace, relation);
        return `No ${prettyRelationName || 'Records'} Available`;
    }, [namespace, relation]);

    // we need to communicate the selected record to the page.
    // We have different options for this:
    // react-router URL parameters (e.g. /Inventory/Take/:id)
    // But the Page title is taken from the last part of the path so
    // e.g. /Inventory/Take/1234 would display "1234" as page title
    // It also would require updating the route handling
    // Another option is to add it into the query like:
    // /Inventory/Take?inventory:inventory=1234
    // That would still show "Take" as Page title,
    // as the the query is already excluded from the path before taking the title off of it.
    // Which means: less changes/less risks of bugs if we go that route
    // So, stick the id in the query/search portion:
    const subRoute = useMemo(() => {
        return `${currentRoute}?${namespace}_${relation}=`;
    }, [namespace, relation, currentRoute]);

    const { records, recordCount, viewReady } = _p.useDbView(
        namespace,
        relation,
        `${namespace}_${relation}_menu_${hNode.id}`,
        hNode
    );

    if (!viewReady) {
        // prettier-ignore
        return rc(View, null,
            rc(DisabledNavHeader, { id },
                rc(DisabledLinkText, { title: 'Loading' }, 'Loading')
            )
        );
    }
    if (!recordCount) {
        // prettier-ignore
        return rc(View, null,
            rc(DisabledNavHeader, { id },
                rc(DisabledLinkText, { title: disabledMessage }, disabledMessage)
            )
        );
    }

    // prettier-ignore
    return rc(NoShrink, null,
        records.map(record => rc(ListEntry,{key: record._id, subRoute, record}))
    );
}

function ListEntry({ subRoute, record }) {
    const currentRoute = subRoute + record._id;
    const isActive = useIsInActiveRoute({ currentRoute });

    const title = useMemo(() => {
        return record.title.length > MAX_TITLE_LENGTH
            ? record.title.substring(0, MAX_TITLE_LENGTH - 2) + ELLIPSES
            : record.title;
    }, [record.title]);

    if (isActive) {
        return rc(
            Noncollapsible,
            { isActive },
            rc(
                ActiveNavHeaderLink,
                { id: record._id, to: currentRoute, title: record.title },
                rc(LinkText, null, title)
            )
        );
    } else {
        return rc(
            Noncollapsible,
            { isActive },
            rc(NavHeaderLink, { id: record._id, to: currentRoute, title: record.title }, rc(LinkText, null, title))
        );
    }
}

NavHeadingRecordList.propTypes = {
    id: PropTypes.string,
    currentRoute: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    hNode: PropTypes.object
};

export default NavHeadingRecordList;
