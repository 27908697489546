// @ts-check
import { localStorage } from 'lib_ui-services';

const _p = {
    getKey: localStorage.getKey,
    setKey: localStorage.setKey,
    deleteKey: localStorage.deleteKey,
    deleteInstance: localStorage.deleteInstance
};
export const _private = _p;

const NO_NAMESPACE = '';

/**
 * just a passthrough to localStorage, making sure the method signatures are compatible with the other storage providers
 * @type {import('../BbState').Storage}
 */
export default {
    isAsync: true,
    get: async function get(key, defaultValue, usePrefix = true) {
        return _p.getKey(key, NO_NAMESPACE, defaultValue, usePrefix);
    },
    set: async function set(key, value, usePrefix = true) {
        return _p.setKey(key, value, NO_NAMESPACE, usePrefix);
    },
    remove: async function remove(key, usePrefix = true) {
        return _p.deleteKey(key, NO_NAMESPACE, usePrefix);
    },
    clear: async function clear(usePrefix) {
        return _p.deleteInstance(NO_NAMESPACE, usePrefix);
    },
    getSync: function getSync(key, defaultValue) {
        return defaultValue;
    }
};
