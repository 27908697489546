import { constants } from 'lib_ui-services';

const retention = constants.retention;
const { SCOPE, VISIBILITY, DURATION } = retention;

/**
 * @typedef {import('./BbState').VISIBILITY} BBState_VISIBILITY
 * @typedef {import('./BbState').DURATION} BBState_DURATION
 * @typedef {import('./BbState').SCOPE} BBState_SCOPE
 */

/**
 * Validate if the given combination of parameters is both implemented and correct:
 * See https://docs.sstid.com/app/page/1ALoRIJmSYJsa84KA24xVCRX6FHhp7e_4050-xRS5o5A?p=1pj9Xbv8SF0JwCGWP1A2HyaWz2FCPOMIZ for details
 * @param {object} retention
 * @param {BBState_VISIBILITY} retention.visibility
 * @param {BBState_DURATION} retention.duration
 * @param {BBState_SCOPE} retention.scope
 * @param {string} [retention.id]
 * @throws {Error} if the combination is not implemented or is incorrect
 */
export default function validate({ visibility, duration, scope, id }) {
    switch (visibility) {
        case VISIBILITY.PER_BROWSER:
            switch (scope) {
                case SCOPE.GLOBAL:
                    if (duration === DURATION.REQUEST) {
                        throw new Error(
                            '[RETENTION] VISIBILITY.PER_BROWSER and SCOPE.GLOBAL are conflicting arguments.'
                        );
                    } else {
                        // for all other durations wrt global scope:
                        // all good
                        return;
                    }
                case SCOPE.LOCAL:
                    if (duration !== DURATION.REQUEST && id == null) {
                        throw new Error(
                            '[RETENTION] An "id" must be included in the retention options for useBbState if the scope is "local" and the duration is not equal to "request".'
                        );
                    }
                    // everything else is all good
                    return;
                case SCOPE.PER_ROUTE:
                default:
                    // always all good
                    return;
            }

        case VISIBILITY.PER_USER:
            if (duration === DURATION.ALWAYS) {
                throw new Error(
                    '[RETENTION] visibility of "perUser", Duration "Always" is not yet implemented for any scope.'
                );
            } else {
                // for all other durations:
                throw new Error(
                    `[RETENTION] Please use Visibility: ${VISIBILITY.PER_BROWSER}, Duration: ${duration}, Scope: ${scope} instead.`
                );
            }
        case VISIBILITY.PER_TENANT:
            if (duration === DURATION.ALWAYS) {
                switch (scope) {
                    case SCOPE.LOCAL:
                        throw new Error(
                            `Please use Visibility: ${VISIBILITY.PER_BROWSER}, Duration: ${duration}, Scope: ${scope} instead.`
                        );
                    case SCOPE.PER_ROUTE:
                        throw new Error(
                            '[RETENTION]  visibility of "perUser", Duration "Always" is not yet implemented for any scope.'
                        );
                    case SCOPE.GLOBAL:
                        // all good
                        return;
                }
            } else {
                // for all other durations:
                throw new Error(
                    `[RETENTION] Please use Visibility: ${VISIBILITY.PER_BROWSER}, Duration: ${duration}, Scope: ${scope} instead.`
                );
            }
    }
}
