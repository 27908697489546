//@ts-check
import _storage from './storage';
import { durationToStorageType, convertVisibility, scopeToKey } from './mapping';
import { publishGlobalChangeDirect } from '../hooks/useSimpleChangeObserver';
import validateRetention from './validateRetention';
import { constants } from 'lib_ui-services';
import lodash from 'lodash';

const { isEqual } = lodash;

const retention = constants.retention;
const { SCOPE, VISIBILITY, DURATION } = retention;

export const CHANGE_TYPE = 'bbStateChange';

export const _private = {
    publishGlobalChangeDirect
};

/**
 *
 * @template T
 * @param {import('./BbState').Retention} retention
 * @param {string} [routeStateKey] only required for retention.scope === PER_ROUTE
 * Intentionally kept out of retention to assure we rerender when the route changes
 * @returns {import('./BbState').BbStateAccess<T>}
 */
export function connect(
    { id, scope = SCOPE.LOCAL, visibility = VISIBILITY.PER_BROWSER, duration = DURATION.REQUEST } = {
        scope: SCOPE.LOCAL,
        visibility: VISIBILITY.PER_BROWSER,
        duration: DURATION.REQUEST
    },
    routeStateKey
) {
    const retention = { id, scope, visibility, duration };
    // this will throw an error if retention is invalid:
    validateRetention(retention);

    // good to go, setup storage and accessors
    /**
     * @type {import('./BbState').Storage}
     */
    const storage = _storage[durationToStorageType(retention).type];
    const prefix = convertVisibility(retention);
    // const key = scopeToKey(retention, stateName, routeStateKey);
    // const [initialValue, isAsync] = storage.getSync(key, defaultValue, prefix);

    return {
        getStorageKey: stateName => scopeToKey(retention, stateName, routeStateKey),
        getSync: (stateName, defaultValue) => {
            const key = scopeToKey(retention, stateName, routeStateKey);
            return storage.getSync(key, defaultValue, prefix);
        },
        isAsync: storage.isAsync,
        getAsync: async (stateName, defaultValue) => {
            const key = scopeToKey(retention, stateName, routeStateKey);
            const result = await storage.get(key, defaultValue, prefix);
            return result ?? defaultValue;
        },
        setAsync: async (newState, stateName, defaultValue) => {
            let value;
            const key = scopeToKey(retention, stateName, routeStateKey);
            const prev = await storage.get(key, defaultValue, prefix);
            if (typeof newState === 'function') {
                if (prev == null) {
                    // @ts-ignore, ts doesn't see the type check in the wrapping if statement
                    value = newState(defaultValue);
                } else {
                    // @ts-ignore, ts doesn't see the type check in the wrapping if statement
                    value = newState(prev);
                }
            } else {
                value = newState;
            }

            if (isEqual(value, prev)) {
                return prev;
            }

            if (value == null) {
                await storage.remove(key, prefix);
            } else {
                await storage.set(key, value, prefix);
            }
            _private.publishGlobalChangeDirect(CHANGE_TYPE, { storageKey: key, newState: value ?? defaultValue });
            return value;
        }
    };
}

export async function clearUserSession() {
    await _storage.loginSessionStorage.clear(true);
    await _storage.loginSessionStorage.clear(false);
}

const tenantRetention = {
    scope: SCOPE.GLOBAL,
    visibility: VISIBILITY.PER_TENANT,
    duration: DURATION.ALWAYS
};
/**
 *
 * @param {import('../../../types').TenantSettings} tenantSetting
 */
export async function loadTenantSettings(tenantSetting) {
    // for each other tenant setting, we need to store it in the localStorage
    // using the property name and tenant id as the key
    for (const property in tenantSetting) {
        if (!['_id', 'title', 'meta', '_idx', '$loki'].includes(property)) {
            const key = scopeToKey(tenantRetention, property);
            const prefix = convertVisibility(tenantRetention);
            // NOT using tenantSettingsStorage.set because we don't want to publish a change
            const old = await _storage.tenantSettingsStorage.get(key, false, prefix);
            if (isEqual(old, tenantSetting[property])) {
                continue;
            }
            // @ts-ignore there is actually a 4th option on tenantSettingsStorage, even if it is not in the type
            await _storage.tenantSettingsStorage.set(key, tenantSetting[property], prefix, true);
            _private.publishGlobalChangeDirect(CHANGE_TYPE, { storageKey: key, newState: tenantSetting[property] });
        }
    }
}

export default {
    connect,
    clearUserSession,
    loadTenantSettings,
    CHANGE_TYPE
};
