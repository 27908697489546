// @ts-check
import { constants, localStorage } from 'lib_ui-services';

const { DURATION } = constants.retention;
const USE_PREFIX = true;

/**
 * effectively just localstorage, with "DURATION.USER_SESSION" as the namespaceRelation,
 * so that we can clear it all easily upon login and logout.
 * @type {import('../BbState').Storage}
 */
export default {
    isAsync: true,
    get: async function get(key, defaultValue) {
        return localStorage.getKey(key, DURATION.USER_SESSION, defaultValue, USE_PREFIX);
    },
    set: async function set(key, value) {
        return localStorage.setKey(key, value, DURATION.USER_SESSION, USE_PREFIX);
    },
    remove: async function remove(key) {
        return localStorage.deleteKey(key, DURATION.USER_SESSION, USE_PREFIX);
    },
    clear: async function clear() {
        return localStorage.deleteInstance(DURATION.USER_SESSION, USE_PREFIX);
    },
    getSync: function getSync(key, defaultValue) {
        return defaultValue;
    }
};
