import { useState, useEffect, useCallback, createElement as rc, Children, useMemo } from 'react';
import PropTypes from 'prop-types';
import { View, testProperties } from 'lib_ui-primitives';
import useHiddenForViewPort from '../../hooks/useHiddenForViewPort';
import useIsInActiveRoute from '../../hooks/useIsInActiveRoute';
import useDisabledForNetwork from '../../hooks/useDisabledForNetwork';
import { DIRECTION } from '../_abstractComponent/DropDown/ArrowIcon';

import {
    NoShrink,
    NavHeaderPlain,
    DisabledNavHeader,
    NavHeaderLink,
    Noncollapsible,
    ActiveNavHeaderLink,
    LinkText,
    DisabledLinkText,
    CollapseIndicator,
    FullWidthCollapse,
    NavIcon,
    CollapsableNoShrink
} from './styles';

/**
 * navHeadings implement (Route) Navigational Support ONLY.
 * In this case, it generates how a NavHeading is shown in the menu (in the sideNav)
 * Any _CONTENT_ other than other NavHeading is passed down into, and generated from, RouteContainer without this "NavHeading" component
 * This includes nav:TabNav and nav:SelectionNav containers
 * @param {*} props
 * @returns
 */
function NavHeading(props) {
    const {
        id: _id,
        children,
        currentRoute,
        hNode: { title, iconName, iconColor },
        hNode
    } = props || { hNode: {} };
    const id = _id || hNode.id;
    const hiddenForViewPort = useHiddenForViewPort(hNode);
    const { disabledByNetwork, hiddenByNetwork, disabledMessage } = useDisabledForNetwork(hNode);
    const isActive = useIsInActiveRoute(props);

    const navHeadingChildren = useMemo(
        () => Children.toArray(children).filter(child => child.props?.hNode?.hNodeTypeGroup === 'navHeading'),
        [children]
    );
    const getInitialIsCollapsedState = useCallback(() => {
        if (navHeadingChildren.length < 1) return null;
        return !isActive;
    }, [isActive, navHeadingChildren.length]);
    const [isCollapsed, setIsCollapsed] = useState(getInitialIsCollapsedState);
    useEffect(() => {
        setIsCollapsed(getInitialIsCollapsedState);
    }, [isActive, getInitialIsCollapsedState]);

    const toggleIsCollapsed = useCallback(() => {
        setIsCollapsed(prevVal => !prevVal);
    }, []);

    if (hiddenForViewPort || hiddenByNetwork) {
        return null;
    }

    if (disabledByNetwork) {
        // Encapsulate to ensure flex-direction is correct.
        // prettier-ignore
        return rc(View, null,
            rc(DisabledNavHeader, { id },
                rc(DisabledLinkText, { title: disabledMessage }, title)
            )
        );
    }

    // Normal non-collapsible header
    if (isCollapsed == null) {
        // Encapsulate to ensure flex-direction is correct.
        // prettier-ignore
        return rc(NoShrink, null,
            rc(Noncollapsible, { isActive },
                iconName && rc(NavIcon, { fontColor: iconColor, title, isActive }, iconName),
                isActive && rc(ActiveNavHeaderLink, { id, to: currentRoute },
                    rc(LinkText, null, title)
                ),
                !isActive && rc(NavHeaderLink, { id, to: currentRoute },
                    rc(LinkText, null, title)
                )
            )
        );
    }

    // Collapsible header
    // prettier-ignore
    return rc(CollapsableNoShrink, {isOpen: !isCollapsed},
        rc(Noncollapsible, { isActive },
            iconName && rc(NavIcon, { fontColor: iconColor, title }, iconName),
            rc(NavHeaderPlain, { id, onClick: toggleIsCollapsed },
                rc(LinkText, null, title),
                rc(CollapseIndicator, { ...testProperties({ id }, 'chevron'), id: `chevron-${id}`, isOpen: !isCollapsed, closed: DIRECTION.right, open: DIRECTION.down })
            )
        ),
        rc(FullWidthCollapse, { isCollapsed: isCollapsed, indentExtra:!!iconName }, navHeadingChildren)

    );
}

NavHeading.propTypes = {
    id: PropTypes.string,
    currentRoute: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    hNode: PropTypes.object,
    hiddenForViewport: PropTypes.bool
};

export default NavHeading;
