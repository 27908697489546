import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import RangeField from './RangeField';
import useDbView from '../../../hooks/useDbView';
import * as primitives from 'lib_ui-primitives';
import createTreePositionGetter from '../../../utilities/createTreePositionGetter';

const { fromTheme, Text, View, styled } = primitives;

const Title = styled(Text)`
    color: ${fromTheme('labelForEmptyField')};
    margin-right: ${fromTheme('textMargin')};
`;
Title.displayName = 'Title';
const RangeContainer = styled(View).attrs({ name: 'range-container' })`
    overflow: visible;
    flex-direction: column;
`;
RangeContainer.displayName = 'RangeContainer';
const RangeFieldContainer = styled(View).attrs({ name: 'range-field-container' })`
    overflow: visible;
    flex-wrap: wrap;
    margin-left: ${fromTheme('viewMargin')};
`;
RangeFieldContainer.displayName = 'RangeFieldContainer';

const _p = { useDbView };
export const _private = _p;
export default function Range(props) {
    const {
        fromValue,
        setFromValue,
        toValue,
        setToValue,
        rangeTypes,
        hNode: { title, fromInputHNode, toInputHNode, treePosition }
    } = props ?? { hNode: {} };

    if (fromInputHNode.hNodeType !== toInputHNode.hNodeType) {
        throw new Error('Ranges of two different types are not supported.');
    }

    const getTreePosition = createTreePositionGetter(treePosition, 2);
    fromInputHNode.treePosition = getTreePosition(0);
    toInputHNode.treePosition = getTreePosition(1);

    // prettier-ignore
    return rc(RangeContainer, null,
        rc(Title, null, title),
        rc(RangeFieldContainer, null,
            rc(RangeField, {
                // eslint-disable-next-line import/namespace
                PrimitiveComponent: primitives[rangeTypes[fromInputHNode.hNodeType].childComponentType],
                primitiveComponentProps: rangeTypes[fromInputHNode.hNodeType].childComponentProps,
                hNode: fromInputHNode,
                value: fromValue,
                onChange: setFromValue,
                sequence: fromInputHNode.treePosition.sequence
            }),
            rc(RangeField, {
                // eslint-disable-next-line import/namespace
                PrimitiveComponent: primitives[rangeTypes[toInputHNode.hNodeType].childComponentType],
                primitiveComponentProps: rangeTypes[fromInputHNode.hNodeType].childComponentProps,
                hNode: toInputHNode,
                value: toValue,
                onChange: setToValue,
                sequence: toInputHNode.treePosition.sequence
            })
        )
    );
}

Range.propTypes = {
    hNode: PropTypes.shape({
        fromInputHNode: PropTypes.object,
        toInputHNode: PropTypes.object
    })
};
