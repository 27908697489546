import { BbState } from 'lib_ui-primitives';

export default {
    verb: 'willRemove',
    namespace: 'security',
    relation: 'profile',
    description: "Clear the user's session on logout",
    priority: 0,
    logic
};

async function logic() {
    await BbState.clearUserSession();
}
