import { session as _session } from 'lib_ui-services';

export default {
    verb: 'doingGet',
    namespace: 'application',
    relation: 'session',
    description: 'get the current session',
    priority: 10,
    logic
};

/**
 * @typedef {import('../../../../../types').Session} Session
 */

/**
 *
 * @returns {Promise<{result: Session[]}>}
 */
async function logic() {
    const session = await _session.getSessionData();
    return { result: [session] };
}
