import logging from '@sstdev/lib_logging';
import { constants } from 'lib_ui-services';
const { useCaseIds } = constants;

export default {
    verb: 'didUpsert',
    namespace: 'deploy',
    relation: 'deployment',
    description: 'Just upserted deployment - if it is displaying, then set it to dirty so the user can save it.',
    useCaseIds: [useCaseIds.ONE_TOUCH],
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
function logic({ data, dispatch, context }) {
    try {
        const record = data.items?.[0];
        if (record == null) {
            throw new Error('There was a problem upserting a deployment record.');
        }
        // If the record is currently displaying, make sure the correct status is displayed
        // Need to probably do this for every field or somehow update ActiveRecord.
        dispatch({ propertyName: 'status', newValue: record.status }, { ...context, verb: 'beginChange' });
    } catch (err) {
        logging.error(err);
    }
}
