import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { View } from 'lib_ui-primitives';


function FormElementLayout(props) {
    if (props == null) return null;
    const { id, title: name, children } = props;
    return rc(View, { id, name: `form_element_layout_${name}_${id}`, restoreSelectionsOnRemount: true }, children);
}

FormElementLayout.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.array,
    hNode: PropTypes.object.isRequired
};

export default FormElementLayout;
