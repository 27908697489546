//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _bulk from './bulk';
import _defaultDidGet_namespace_relation from './defaultDidGet_namespace_relation';
import _didPurge_namespace_relation from './didPurge_namespace_relation';
import _didRemove_namespace_relation from './didRemove_namespace_relation';
import _doingCount_namespace_relation from './doingCount_namespace_relation';
import _doingCreate_namespace_relation from './doingCreate_namespace_relation';
import _doingCreate_namespace_relation_transaction from './doingCreate_namespace_relation_transaction';
import _doingGetFromServer_namespace_relation from './doingGetFromServer_namespace_relation';
import _doingGetMore_namespace_relation from './doingGetMore_namespace_relation';
import _doingGet_namespace_relation from './doingGet_namespace_relation';
import _doingPurge_namespace_relation from './doingPurge_namespace_relation';
import _doingRefresh_namespace_relation from './doingRefresh_namespace_relation';
import _doingRemove_namespace_relation from './doingRemove_namespace_relation';
import _doingUpdate_namespace_relation from './doingUpdate_namespace_relation';
import _doingUpsert_namespace_relation from './doingUpsert_namespace_relation';
import _doingValidate_namespace_relation from './doingValidate_namespace_relation';
import _exportHelpers from './exportHelpers';
import _export_namespace_relation from './export_namespace_relation';
import _notHandledHere from './notHandledHere';
import _rollback from './rollback';
import _willCreate_namespace_relation from './willCreate_namespace_relation';
import _willCreate_namespace_relation_transaction from './willCreate_namespace_relation_transaction';
import _willRemove_namespace_relation from './willRemove_namespace_relation';
import _willRemove_namespace_relation_deletePrevention from './willRemove_namespace_relation_deletePrevention';
import _willUpdate_namespace_relation from './willUpdate_namespace_relation';
import _willUpdate_namespace_relation_attachments from './willUpdate_namespace_relation_attachments';
export const bulk = _bulk;
export const defaultDidGet_namespace_relation = _defaultDidGet_namespace_relation;
export const didPurge_namespace_relation = _didPurge_namespace_relation;
export const didRemove_namespace_relation = _didRemove_namespace_relation;
export const doingCount_namespace_relation = _doingCount_namespace_relation;
export const doingCreate_namespace_relation = _doingCreate_namespace_relation;
export const doingCreate_namespace_relation_transaction = _doingCreate_namespace_relation_transaction;
export const doingGetFromServer_namespace_relation = _doingGetFromServer_namespace_relation;
export const doingGetMore_namespace_relation = _doingGetMore_namespace_relation;
export const doingGet_namespace_relation = _doingGet_namespace_relation;
export const doingPurge_namespace_relation = _doingPurge_namespace_relation;
export const doingRefresh_namespace_relation = _doingRefresh_namespace_relation;
export const doingRemove_namespace_relation = _doingRemove_namespace_relation;
export const doingUpdate_namespace_relation = _doingUpdate_namespace_relation;
export const doingUpsert_namespace_relation = _doingUpsert_namespace_relation;
export const doingValidate_namespace_relation = _doingValidate_namespace_relation;
export const exportHelpers = _exportHelpers;
export const export_namespace_relation = _export_namespace_relation;
export const notHandledHere = _notHandledHere;
export const rollback = _rollback;
export const willCreate_namespace_relation = _willCreate_namespace_relation;
export const willCreate_namespace_relation_transaction = _willCreate_namespace_relation_transaction;
export const willRemove_namespace_relation = _willRemove_namespace_relation;
export const willRemove_namespace_relation_deletePrevention = _willRemove_namespace_relation_deletePrevention;
export const willUpdate_namespace_relation = _willUpdate_namespace_relation;
export const willUpdate_namespace_relation_attachments = _willUpdate_namespace_relation_attachments;
export default {bulk, defaultDidGet_namespace_relation, didPurge_namespace_relation, didRemove_namespace_relation, doingCount_namespace_relation, doingCreate_namespace_relation, doingCreate_namespace_relation_transaction, doingGetFromServer_namespace_relation, doingGetMore_namespace_relation, doingGet_namespace_relation, doingPurge_namespace_relation, doingRefresh_namespace_relation, doingRemove_namespace_relation, doingUpdate_namespace_relation, doingUpsert_namespace_relation, doingValidate_namespace_relation, exportHelpers, export_namespace_relation, notHandledHere, rollback, willCreate_namespace_relation, willCreate_namespace_relation_transaction, willRemove_namespace_relation, willRemove_namespace_relation_deletePrevention, willUpdate_namespace_relation, willUpdate_namespace_relation_attachments};

