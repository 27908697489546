import database from '../database';
import lodash from 'lodash';
const { debounce, isEqual } = lodash;
import ViewCriteria from './viewCriteria';
import _emptyDbView, { EMPTY_ARRAY } from './emptyDbView';
import logging from '@sstdev/lib_logging';
import getGlobalConfig from '../globalConfig';
import { getRelationMetadata } from '../metadata';
import { SYNC_TYPES } from '../constants';
import fetchAndUpsertTemporaryQueryResult from './fetchAndUpsertTemporaryQueryResult';
import { getKey, setKey } from '../pageLoadStorage';

const CHECK_EQUALITY_RECORD_COUNT_BREAKPOINT = 70;
const { minFullTextSearchTermLength } = getGlobalConfig();

function getDbViewReferences() {
    let dbViewReferences = getKey('dbViewReferences');
    if (dbViewReferences == null) {
        dbViewReferences = {};
        setKey('dbViewReferences', dbViewReferences);
    }
    return dbViewReferences;
}

const PAGE_SIZE = getGlobalConfig().pageSizeForLimitSyncSizeRelations;
const _p = {
    getOnRebuild,
    getNextPageFromServer,
    getRelationMetadata,
    fetchAndUpsertTemporaryQueryResult,
    getDbViewReferences,
    PAGE_SIZE
};
export const _private = _p;
export const emptyDbView = _emptyDbView;

/**
 * Fascaded pattern over top of the lokiJs dynamicView to simplify
 * things (a little bit :-) ).
 * @param {string} namespaceTitle
 * @param {string} relationTitle
 * @param {string} dbViewName - determines app-wide unique identity of view
 * @returns dbView
 */
export async function getDbView(
    namespaceTitle,
    relationTitle,
    dbViewName = `${namespaceTitle}_${relationTitle}_default`,
    clearFilters = false
) {
    if (!getDbViewReferences()[dbViewName]) {
        getDbViewReferences()[dbViewName] = createView(namespaceTitle, relationTitle, dbViewName, clearFilters);
    }
    return await getDbViewReferences()[dbViewName];
}

function viewContainsId(lokiView, _id) {
    return lokiView.data().some(record => record._id === _id);
}

async function createView(namespaceTitle, relationTitle, dbViewName, clearFilters) {
    const relation = _p.getRelationMetadata(namespaceTitle, relationTitle, false);
    const dbViewToExport = {
        namespace: namespaceTitle,
        relation: relationTitle,
        viewName: dbViewName,
        data: EMPTY_ARRAY,
        count: 0,
        page: 0,
        serverSideOffset: 0,
        viewCriteria: null,
        changeListeners: [],
        // rebuilds can occur even without changes to the data in the view (e.g. when filters change, but the filter does not change the data)
        rebuildListeners: [],
        cleanupOperations: [],
        getNextPage: async () => {
            if (relation == null || !relation.limitSyncSize) {
                throw new Error('dbView.getNextPage can only be called for relations with limitSyncSize of true.');
            }
            return await _p.getNextPageFromServer(dbViewToExport);
        }
    };

    await database.isDbReady();
    const db = database.get();
    // Get underlying lokijs database to access collection directly
    const loki = await db.abstractDbRoot.waitTillDbReady;
    const collectionName = `${namespaceTitle}_${relationTitle}`;
    // Noop if already initialized, create collection otherwise
    db.initializeDb(namespaceTitle, relationTitle);
    const collection = loki.getCollection(collectionName);
    if (collection == null) {
        throw new Error(
            `No collection was found for namespace_relation ${collectionName}.  This likely means the metadata is not setup correctly for this namespace/collection.  It may need "Offline Sync" checked in Blockly.`
        );
    }

    let _lokiView = collection.getDynamicView(dbViewName);
    if (_lokiView == null) {
        _lokiView = collection.addDynamicView(dbViewName, { minRebuildInterval: 5 });
        _lokiView.namespace = namespaceTitle;
        _lokiView.relation = relationTitle;
    } else {
        if (clearFilters) {
            _lokiView.removeFilters();
        }
    }

    const _onRebuild = getOnRebuild(_lokiView, dbViewToExport, dbViewName);
    _lokiView.addListener('rebuild', _onRebuild);
    // When debugging you can track down rebuilds with this:
    // _view.addListener('rebuild', () => {
    //     debugger;
    // });
    _onRebuild();

    const viewCriteria = ViewCriteria(_lokiView);
    // Reset view page count when the view sort or filters change.
    viewCriteria.onBeforeChange(type => {
        if (type === 'applyFilters') {
            logging.debug(`[DBVIEW] - Resetting page count and server side offset for ${dbViewName} due to ${type}.`);
            dbViewToExport.page = 0;
            dbViewToExport.serverSideOffset = 0;
        }
    });
    dbViewToExport.viewCriteria = viewCriteria;
    // If the viewCriteria creates any cleanup operations, be sure to run those as part of
    // the cleanup for this dbView.
    dbViewToExport.cleanupOperations.push(viewCriteria.cleanupAll);
    dbViewToExport.lokiView = _lokiView;
    dbViewToExport.name = _lokiView.name;
    dbViewToExport.containsId = _id => viewContainsId(_lokiView, _id);

    // Allow cleanup
    dbViewToExport.release = getOnRelease(dbViewToExport, _onRebuild);

    return dbViewToExport;
}

function getOnRelease(dbView, _onRebuild) {
    return function release() {
        try {
            // If memory consumption of views becomes a problem then this function may also need
            // to destroy the actual loki dynamicView.
            // Otherwise, don't remove the loki dynamicview filters here because it will cause a dynamicview
            // rebuild and the screen will flicker in summary detail view.
            // This is because a cancel (or just clicking between records (which causes a cancel))
            // of the summary detail render will release this view, but then the summary only render
            // will happen immediately after, and the same loki dynamicview will be needed for that.
            const _lokiView = dbView.lokiView;
            logging.debug(`[DBVIEW] - releasing ${dbView.viewName}.`);
            _lokiView.removeListener('rebuild', _onRebuild);
            _onRebuild.cancel();
            dbView.lokiView = undefined;
            dbView.data = EMPTY_ARRAY;
            dbView.count = 0;
            dbView.cleanupOperations.forEach(c => c());
            // Clear this reference so that this cleanup happens only once, even if there are
            // multiple consumers of the view.
            dbView.release = () => {};
            // Publish cleared out data to all consumers
            dbView.rebuildListeners.forEach(listener => listener(dbView));
            dbView.changeListeners.forEach(listener => listener(dbView));
            delete getDbViewReferences()[dbView.viewName];
        } catch (err) {
            logging.debug(`[DBVIEW] - Error while releasing ${dbView.viewName}.`);
            logging.error(err);
        }
    };
}

function getOnRebuild(_lokiView, dbViewToExport, dbViewName) {
    const { namespace: namespaceTitle, relation: relationTitle } = dbViewToExport;
    const limitedSyncSizeRelation =
        _p.getRelationMetadata(namespaceTitle, relationTitle, false)?.limitSyncSize ?? false;
    const debounced = debounce(() => {
        // This is throttled because lokijs dynamicView.data() "resolves any pending
        // filtering and sorting, then returns document array as result."
        const newData = _lokiView.data();
        const filters = dbViewToExport.viewCriteria.getFiltersFromView();
        if (filters?.fullTextSearch?.serverResults != null) {
            // Set serverSideOffset to the count of all full text search results
            dbViewToExport.serverSideOffset = Object.values(filters.fullTextSearch.serverResults).reduce(
                (count, result) => (count += result.length),
                0
            );
        }
        // Ideally, we would only check for deep equality if the effort is something less than rerendering.
        // This is pretty difficult to measure though.  Here's the "skateboard" approach:
        if (newData.length < CHECK_EQUALITY_RECORD_COUNT_BREAKPOINT) {
            if (isEqual(newData, dbViewToExport.data)) {
                // See "wrapperToTrackIfRebuilding" below.
                dbViewToExport.rebuildInProgress = false;
                dbViewToExport.rebuildListeners.forEach(listener => listener(dbViewToExport));
                return;
            }
        }

        dbViewToExport.data = newData;
        // Avoid unnecessary renders by using a constant reference for empty data
        if (dbViewToExport.data.length === 0) {
            dbViewToExport.data = EMPTY_ARRAY;
        }
        dbViewToExport.count = dbViewToExport.data.length;

        // Create a new method reference here (as opposed to just keeping the existing one which
        // does the exact same thing) because useEffect calls that need to be aware of a change
        // in the view data may (in the useEffect dependency array) only be looking at the
        // containsId method reference on the view -- meaning the useEffect
        // won't run even though the result of the containsId method might be different
        // with the new data.
        dbViewToExport.containsId = _id => viewContainsId(_lokiView, _id);

        // Log view rebuilds with count, filters and sort
        logging.debug(
            // FYI - This is different from the Loki rebuilds because we might skip this rebuild if the lokijs
            // dataset is small enough to measure deep equality and find it equal to the old dataset.
            `[DBVIEW] Rebuild ${dbViewName}, Count: ${dbViewToExport.count}` //, \n    ${logViewCriteria(_lokiView)}`
        );
        // See "wrapperToTrackIfRebuilding" below.
        dbViewToExport.rebuildInProgress = false;
        // inform dependencies (like useDbView) that the data has been rebuilt
        dbViewToExport.changeListeners.forEach(listener => listener(dbViewToExport));
        dbViewToExport.rebuildListeners.forEach(listener => listener(dbViewToExport));

        if (relationTitle !== 'shiftSummary') {
            if (dbViewToExport.data.length < _p.PAGE_SIZE && limitedSyncSizeRelation) {
                logging.info(`[DBVIEW] Rebuild count was only ${dbViewToExport.data.length}; getting next page.`);
                dbViewToExport.getNextPage();
            }
        }
    }, 250);

    // The debounced function (obviously) could be called any number of times, so we need this
    // wrapper to show we have a need to do (or are currently doing) our rebuild logic.
    // The actual debounced function will set it to false when done.
    const wrapperToTrackIfRebuilding = () => {
        dbViewToExport.rebuildInProgress = true;
        debounced();
    };

    // Since we are wrapping the debounce, expose the cancel function on the wrapper.
    wrapperToTrackIfRebuilding.cancel = debounced.cancel;
    return wrapperToTrackIfRebuilding;
}

async function getNextPageFromServer(dbView) {
    // This code is only relevant for relations with limited sync size.
    const { namespace: namespaceTitle, relation: relationTitle, lokiView, viewCriteria } = dbView;
    const relation = _p.getRelationMetadata(namespaceTitle, relationTitle, false);

    // If this is not a synchronized relation (e.g. application notification)
    // or not a limited sync size relation then, skip.
    if (relation == null || !relation.limitSyncSize) {
        return;
    }

    const MAX_COLLECTION_SIZE = getGlobalConfig().maxRecordsInLimitedSizeCollection;

    // If there is a 'paged' filter use that to get the page size. Otherwise, use the
    // global constant.
    const filters = viewCriteria.getFiltersFromView();
    const pageSize = filters?.paged?.limit ?? _p.PAGE_SIZE;
    // If the maximum local collection size has been reached, then we cannot request
    // another batch and the request cannot be filtered and fulfilled locally
    if (lokiView.collection.data.length + pageSize > MAX_COLLECTION_SIZE) {
        // TODO: Figure out the best way to report this given the functionality we have
        // for the user.
        logging.error(
            'Too much data would be required to filter and fulfill this request locally.  Please add additional filters to reduce the volume of data.'
        );
        return;
    }
    const findFilters = Object.keys(filters).filter(
        f => !['orderBy', 'orderByMultiple', 'excludeDeleted', 'paged'].includes(f)
    );

    let result = [];
    // If there are no filters that actually find data, then just do a regular sync to
    // get the next page.
    if (
        findFilters.length === 0 ||
        (filters?.fullTextSearch != null &&
            (filters.fullTextSearch?.searchTerm?.length ?? 0) < minFullTextSearchTermLength)
    ) {
        const db = database.get();
        ({ result } = await db.synchronization.syncOneRelation(
            { namespaceTitle, relationTitle },
            SYNC_TYPES.sizeLimitedFullBatch
        ));
    } else {
        filters.paged = filters.paged ?? { page: dbView.page };
        const newPage = (filters.paged.page ?? 0) + 1;
        filters.paged.page = newPage;
        filters.paged.currentOffset = dbView.serverSideOffset;
        filters.paged.limit = pageSize;
        dbView.page = newPage;
        const queryId = `${dbView.viewName}`;

        // Log page requests
        logging.debug(
            `[DBVIEW] Getting next page (page: ${newPage}, offset: ${dbView.serverSideOffset}) for ${queryId}.`
        );

        if (filters.fullTextSearch != null) {
            if (filters.fullTextSearch.searchTerm.length >= minFullTextSearchTermLength) {
                // Full text search pulls pages of records from the server as part of the filter.
                await viewCriteria.applyFilters(filters, queryId);
                if (filters.fullTextSearch.serverResults == null) {
                    throw new Error('fullTextSearch.serverResults should be set after applying filters.');
                }
                result = filters.fullTextSearch.serverResults[`page${newPage}`];
            } else {
                return [];
            }
        } else {
            let cleanup;
            ({ cleanup, result } = await _p.fetchAndUpsertTemporaryQueryResult(dbView, queryId));
            dbView.serverSideOffset += result?.length ?? 0;
            if (cleanup != null) {
                dbView.cleanupOperations.push(cleanup);
            }
        }
    }
    return result;
}

export async function destroyDbView(viewName) {
    const viewPromise = getDbViewReferences()[viewName];
    if (!viewPromise) return;
    logging.debug(`[DBVIEW] destroying ${viewName}`);
    delete getDbViewReferences()[viewName];
    const view = await viewPromise;
    const collection = view.lokiView.collection;
    view.release();
    collection.removeDynamicView(viewName);
}

export async function destroyDbViewForRelation(namespaceTitle, relationTitle) {
    const views = Object.values(_p.getDbViewReferences());
    for (let i = 0; i < views.length; i++) {
        const viewPromise = views[i];
        const view = await viewPromise;
        if (view.namespace === namespaceTitle && view.relation === relationTitle) {
            const collection = view?.lokiView?.collection;
            view.release();
            collection?.removeDynamicView(view.viewName);
        }
    }
}

export function logViewCriteria(_lokiView) {
    return `CRITERIA: ${JSON.stringify(
        _lokiView?.filterPipeline?.filter(f => f.type === 'find'),
        null,
        3
    ).replace(/\n/g, '\n    ')}, \n    SORT: [\n        ${_lokiView?.sortCriteria
        ?.map(sort => `${sort[0]}: ${sort[1] ? 'desc' : 'asc'} uid: ${sort[2].join(', ')}`)
        .join('\n        ')}\n    ]`;
}
