import filterHelper from './filterHelpers';

// Because this is only used by lokijs dynamicViews (as opposed to a paged database get),
// most of this is dependent on the navigationSelection context boundary which is passed in via
// the contextualInfo parameter on fromHNode() below.
export default {
    getFilter,
    fromHNode,
    getUriComponent,
    getMql,
    getJavaScriptFilter
};

function fromHNode(hNode, contextualInfo) {
    const { namespace, relation, criteriaProperty = 'criteria', foreignNamespace, foreignRelation } = hNode;
    const {
        available = false,
        loading,
        namespace: selectionNamespace,
        relation: selectionRelation,
        record
    } = contextualInfo?.navigationSelection || {};

    // too early to filter, or nothing selected
    if (!available || loading) return;
    // selection is not for us.
    if (selectionNamespace !== namespace || selectionRelation !== relation) return;

    /*
     * record will look something like (multiSelect):
     * {
     *   criteria: {
     *     'fns:frel':{
     *       _in: [
     *           {
     *              _id:...,
     *              title:...
     *           }
     *       ]
     *     }
     *   }
     * }
     * or  (single Select):
     * {
     *   criteria: {
     *     'fns:frel':{
     *       _eq: {
     *          _id:...,
     *          title:...
     *       }
     *     }
     *   }
     * }
     */
    const key = `${foreignNamespace}:${foreignRelation}`;
    const criteria = record?.[criteriaProperty]?.find(c => c[key]);
    if (!criteria || !Object.keys(criteria).length || !Object.keys(criteria[key]).length) return;

    const op = Object.keys(criteria[key])[0];
    let values;
    if (Array.isArray(criteria[key][op])) {
        //multiSelect
        values = criteria[key][op].map(x => x._id);
        if (!values.length) return;
    } else {
        //single Select
        values = criteria[key][op]._id;
        if (typeof values === 'undefined') return;
    }

    //this is only meant to filter e.g. location:location  by the location:location criteria
    //meaning, whatever values we have need to be matched against the unique _id fo the current record set.
    return getFilter('_id', op.replace('_', '$'), values);
}

function getFilter(propertyName, op, values) {
    return {
        propertyName,
        op,
        values
    };
}

function getMql(filters) {
    if (filters.byNavigationSelectionDropdownCriteria == null) return;
    const { propertyName = '_id', op, values } = filters.byNavigationSelectionDropdownCriteria;
    return { [propertyName]: { [op]: values } };
}

function getUriComponent(filters) {
    const filter = filters['byNavigationSelectionDropdownCriteria'];
    const { propertyName = '_id', op, values } = filter;
    const criteria = { [propertyName]: { [op]: values } };
    return {criteria};
}

function getJavaScriptFilter(filter) {
    return filterHelper.basicFilterToJavaScript({ propertyName: '_id', ...filter });
}
