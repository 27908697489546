// ReactState storage effectively is: do nothing extra beyond that.
// Hence, all methods are no-ops.

// @ts-check

/**
 * useBbState keeps everything in state. Storage is just to persist it beyond that use.
 * @type {import('../BbState').Storage}
 */
export default {
    isAsync: false,
    get: async function get() {
        // noop
    },
    set: async function set() {
        // noop
    },
    remove: async function remove() {
        // noop
    },
    clear: async function clear() {
        // noop
    },
    getSync: function getSync(key, defaultValue) {
        return defaultValue;
    }
};
