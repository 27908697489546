export default {
    getFilter,
    getUriComponent,
    fromHNode,
    pageResetRequired: true,
    getMql
};

function fromHNode(hNode) {
    const { to = 0, from = 0 } = hNode;
    return getFilter(from, to, hNode.propertyName);
}

function getFilter(fromValue, toValue, propertyPath) {
    if (!toValue && !fromValue) {
        return null;
    }

    return {
        fromValue,
        toValue,
        propertyPath
    };
}

function getUriComponent(filters) {
    const filter = filters['numberRange'];
    if (filter.propertyName) {
        let criteria = { [filter.propertyName]: {} };

        if (filter.fromValue) {
            criteria[filter.propertyName] = { $gte: filter.fromValue };
        }

        if (filter.toValue) {
            criteria[filter.propertyName] = {
                ...criteria[filter.propertyName],
                $lte: filter.toValue
            };
        }

        return {criteria};
    }
}
function getMql(filters) {
    if (filters == null || filters.numberRange == null) return;
    const {
        numberRange: { fromValue, toValue, propertyPath }
    } = filters;
    if (!fromValue) {
        return {
            [propertyPath]: { $lte: toValue }
        };
    } else if (!toValue) {
        return {
            [propertyPath]: { $gte: fromValue }
        };
    }
    return {
        [propertyPath]: { $between: [fromValue, toValue] }
    };
}
