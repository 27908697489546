// @ts-check
import { constants } from 'lib_ui-services';

const { SCOPE, VISIBILITY, DURATION } = constants.retention;

/**
 * @typedef {import('./BbState').Retention} Retention
 */

/**
 * This effectively decides how many variations with the same stateName can exist next to each other
 * @param {Retention} retention
 * @param {string} stateName
 * @param {string} [routeStateKey] only required for retention.scope === PER_ROUTE
 * @returns {string} key to use for storing/getting this state
 */
export function scopeToKey(retention, stateName, routeStateKey) {
    switch (retention.scope) {
        case SCOPE.GLOBAL:
            // only 1 instance
            return stateName;
        case SCOPE.LOCAL:
            // as many instances as you can reference with ids
            return `${stateName}|${retention.id}`;
        case SCOPE.PER_ROUTE: {
            // as many instances as we have routes in our app
            if (!routeStateKey) {
                throw new Error('[RETENTION] PER_ROUTE scope requires a routeStateKey.');
            }
            return `${stateName}|${routeStateKey}`;
        }
        default:
            throw new Error(`[RETENTION] ${retention.scope} is not a valid retention scope.`);
    }
}

/**
 * `usePrefix` is a boolean that indicates if the key should be prefixed with the tenantId (or userId)
 * `notifier` is function that will forward a change to the value to the appropriate workflows
 * @param {Retention} retention
 * @returns {boolean|string} whether to use a prefix or not
 */
export function convertVisibility(retention) {
    switch (retention.visibility) {
        case VISIBILITY.PER_BROWSER:
            // even if we want it mentally "for anyone on the browser",
            // practically, we never want anything to possibly cross to other tenants.
            return VISIBILITY.PER_TENANT;
        case VISIBILITY.PER_USER:
            // todo: replace noop with a function that will forward a change to be stored as a user specific setting
            return VISIBILITY.PER_USER;
        case VISIBILITY.PER_TENANT:
            return VISIBILITY.PER_TENANT;
        default:
            throw new Error(`[RETENTION] ${retention.visibility} is not a valid retention visibility.`);
    }
}

/**
 *
 * @param {Retention} retention
 * @returns {{type:string, isAsync:boolean}}
 */
export function durationToStorageType(retention) {
    switch (retention.duration) {
        case DURATION.REQUEST:
            return { type: 'componentStorage', isAsync: false };
        case DURATION.PAGE_LOAD:
            return { type: 'pageLoadStorage', isAsync: false };
        case DURATION.USER_SESSION:
            return { type: 'loginSessionStorage', isAsync: true };
        case DURATION.ALWAYS:
            if (retention.visibility === VISIBILITY.PER_TENANT) {
                // when storing or accessing "tenant level" state for anything shorter than "always",
                // it is no different from PER_BROWSER, so we don't do anything special there
                // but when it is "always", we need to store it in a different storage
                // that handles all the special aspects of tenant level data
                return { type: 'tenantSettingsStorage', isAsync: true };
            }
            return { type: 'localStorage', isAsync: true };
        default:
            throw new Error(`[RETENTION] ${retention.duration} is not a valid retention duration.`);
    }
}

export default {
    scopeToKey,
    convertVisibility,
    durationToStorageType
};
