import localforage from 'localforage';
import { VISIBILITY } from './constants/retention';

let instances = {}; // localforage instances;

//Prepend a prefix to all keys. Typically this will be a tenantId. This avoids storage getting
//polluted when users switch between tenants.
let _prefix = '_global_';
let _userPrefix = '';

export function getPrefix() {
    return _prefix || '';
}

export function setPrefix(prefix) {
    _prefix = prefix.toString();
}

export function getUserPrefix() {
    return _userPrefix || getPrefix();
}

export function setUserPrefix(prefix) {
    _userPrefix = prefix.toString();
}

function _getInstanceName(namespaceRelation = '', usePrefix = true) {
    let prefix = '';
    if (usePrefix === VISIBILITY.PER_USER) {
        prefix = getUserPrefix();
    } else if (usePrefix) {
        prefix = getPrefix();
    }
    return prefix + namespaceRelation || 'bbDefault';
}

export async function getKey(key, namespaceRelation = '', defaultValue, usePrefix = true) {
    const instance = getLocalForageInstance(localforage, _getInstanceName(namespaceRelation, usePrefix));
    return instance.getItem(key).then(value => {
        // 5/22/2023 in case we didn't find anything, just in case it was stored incorrectly before
        // see if it happened to be stored under "localForage".
        // This can probably be removed in the future
        if (typeof value === 'undefined' && namespaceRelation === '') {
            const ancientValue = getKey(key, 'bbDefault', defaultValue, usePrefix);
            if (ancientValue != defaultValue) {
                throw new Error(
                    `Found value for key ${key} in 'bbDefault' namespace, but not in the current namespace. This is likely a bug. Please report it.`
                );
            }
        }
        return value ?? defaultValue;
    });
}

export async function setKey(key, value, namespaceRelation = '', usePrefix = true) {
    const instance = getLocalForageInstance(localforage, _getInstanceName(namespaceRelation, usePrefix));
    return instance.setItem(key, value);
}

export async function deleteKey(key, namespaceRelation = '', usePrefix = true) {
    const instance = getLocalForageInstance(localforage, _getInstanceName(namespaceRelation, usePrefix));
    return instance.removeItem(key);
}


export async function deleteInstance(namespaceRelation = '', usePrefix = true) {
    const name = _getInstanceName(namespaceRelation, usePrefix);

    if (instances[name] != null) {
        const instance = instances[name];
        delete instances[name];
        await instance.clear();
    }
    const dbs = await indexedDB.databases();
    for (const db of dbs) {
        if (db.name === name) {
            indexedDB.deleteDatabase(db.name);
        }
    }
}

function getLocalForageInstance(localforage, name = 'bbDefault') {
    if (instances[name] == null) {
        instances[name] = localforage.createInstance({ name });
    }
    return instances[name];
}

export default { getKey, setKey, deleteKey, getPrefix, setPrefix, deleteInstance };
