const NON_WRAPPING_FORM_ELEMENTS = ['MultiPickerSensorGrid'];
const FORCE_TO_FORM_ELEMENTS = ['Slider', 'FormElementLayout'];
/**
 * @param {import('react').ReactNode} children
 * @returns {import('react').ReactNode | null}
 */
export function getFormElements(children) {
    if (!Array.isArray(children)) {
        // If there is only one child and it is a form element, return it.
        if (
            FORCE_TO_FORM_ELEMENTS.includes(children?.props?.hNode?.hNodeType) ||
            (children?.props?.hNode?.hNodeTypeGroup === 'formElement' &&
                !NON_WRAPPING_FORM_ELEMENTS.includes(children?.props?.hNode?.hNodeType))
        )
            return children;
        // If there is only one child and it is something else return null
        return null;
    }

    return children.filter(
        c =>
            FORCE_TO_FORM_ELEMENTS.includes(c?.props?.hNode?.hNodeType) ||
            (c?.props?.hNode?.hNodeTypeGroup === 'formElement' &&
                !NON_WRAPPING_FORM_ELEMENTS.includes(c?.props?.hNode?.hNodeType))
    );
}

/**
 * @param {import('react').ReactNode} children
 * @returns {import('react').ReactNode | null}
 */
export function getFormButtons(children) {
    if (!Array.isArray(children)) {
        // If there is only one child and it is a form button, return it.
        if (
            children?.props?.hNode?.hNodeBlockGroup === 'formButtons' ||
            children?.props?.hNode?.hNodeTypeGroup === 'formButton'
        ) {
            return children;
        }
        // If there is only one child and it is something else return null
        return null;
    }

    return children.filter(
        c => c?.props?.hNode?.hNodeBlockGroup === 'formButtons' || c?.props?.hNode?.hNodeTypeGroup === 'formButton'
    );
}

export function getOtherElements(children) {
    if (!Array.isArray(children)) {
        if (FORCE_TO_FORM_ELEMENTS.includes(children?.props?.hNode?.hNodeType)) return null;
        if (NON_WRAPPING_FORM_ELEMENTS.includes(children?.props?.hNode?.hNodeType)) return children;
        // If there is only one child and it is not a form button or form element, return it.
        if (
            children?.props?.hNode?.hNodeTypeGroup !== 'formElement' &&
            children?.props?.hNode?.hNodeBlockGroup !== 'formButtons' &&
            children?.props?.hNode?.hNodeTypeGroup !== 'formButton'
        )
            return children;
        // If there is only one child and it is a form button or form element return null
        return null;
    }

    return children.filter(c => {
        return (
            (c?.props?.hNode?.hNodeBlockGroup !== 'formButtons' &&
                c?.props?.hNode?.hNodeTypeGroup !== 'formButton' &&
                c?.props?.hNode?.hNodeTypeGroup !== 'formElement' &&
                !FORCE_TO_FORM_ELEMENTS.includes(c?.props?.hNode?.hNodeType)) ||
            NON_WRAPPING_FORM_ELEMENTS.includes(c?.props?.hNode?.hNodeType)
        );
    });
}
