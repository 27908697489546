import { createElement as rc, forwardRef } from 'react';
import styled from '../styled';
import fromTheme from '../fromTheme';
import Icon from './Icon';
import styleToColor from '../styleToColor';
import { css, keyframes, useTheme } from 'styled-components';
import View from './View';

const spin = keyframes`
    to { transform: rotate(360deg); }
`;
const spinAnimation = css`
    ${spin} 1s ease-in-out infinite;
`;

const ButtonContainer = styled(View).attrs({ name: 'button-container' })`
    position: relative;
    flex-grow: 0;
    flex-basis: auto;
    margin: ${fromTheme('textMargin')};
`;

const RoundButtonContainer = styled(ButtonContainer).attrs({ name: 'round-button-container' })`
    // Without min-x these can be squished if the container doesn't have room.
    min-width: ${({ roundDiameter, theme }) => (roundDiameter ?? theme.button.roundDiameter) + 6 + 'px'};
    min-height: ${({ roundDiameter, theme }) => (roundDiameter ?? theme.button.roundDiameter) + 6 + 'px'};
    max-height: ${({ roundDiameter, theme }) => (roundDiameter ?? theme.button.roundDiameter) + 6 + 'px'};
`;

const ClockingBorder = styled(View).attrs({ name: 'clocking-border' })`
    border-radius: 50%;
    animation: ${({ clocking }) => (clocking ? spinAnimation : 'none')};
    border: ${({ clocking }) => (clocking ? '3px solid rgba(255, 255, 255, 0.3)' : 'none')};
    border-top-color: ${fromTheme('spinnerColor')};
    background-color: transparent;
    // Without min-x these can be squished if the container doesn't have room.
    min-width: ${({ roundDiameter, theme }) => (roundDiameter ?? theme.button.roundDiameter) + 6 + 'px'};
    min-height: ${({ roundDiameter, theme }) => (roundDiameter ?? theme.button.roundDiameter) + 6 + 'px'};
    position: absolute;
`;

const StyledButton = styled.button.attrs({ name: 'button', type: 'button' })`
    display: inline-flex;
    font-size: ${fromTheme('button', 'fontSize')};
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-width: ${({ buttonStyle, theme }) => (buttonStyle === 'Info' ? theme.width : theme.button.maxWidth)}px;
    border-radius: ${fromTheme('button', 'borderRadius')};
    border: 3px solid transparent;
    padding: ${fromTheme('button', 'padding')};
    background-color: ${({ theme, color, disabled }) =>
        disabled && color !== 'transparent' ? theme.disabledBackgroundColor : theme.button[color]};
    background-position: center;
    transition: ${({ disabled }) => {
        return `background ${disabled ? '0s' : '0.8s'}`;
    }};
    &:hover {
        background: ${({ theme, color, disabled }) => {
            if (disabled) {
                return color !== 'transparent' ? theme.disabledBackgroundColor : theme.button[color + 'Hover'];
            }
            const calcedColor = theme.button[color + 'Hover'];
            return `${calcedColor} radial-gradient(circle, transparent 1%, ${calcedColor} 1%) center/15000%;`;
        }};
    }
    &:active {
        background-color: ${({ theme, color, disabled }) => {
            if (disabled) {
                return color !== 'transparent' ? theme.disabledBackgroundColor : theme.button[color];
            }
            return theme.button[color + 'Highlight'];
        }};
        background-size: 100%;
        transition: background 0s;
    }
    color: ${({ theme, color, disabled, fontColor }) => {
        if (disabled) return theme.disabledFontColor;
        if (fontColor) return fontColor;
        if (['base', 'transparent'].includes(color)) theme.defaultFontColor;
        return theme.button.fontColor;
    }};
    box-shadow: ${({ color }) => {
        if (['base', 'transparent'].includes(color)) return 'none';
        return '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);';
    }};
`;

const RoundButton = styled(StyledButton).attrs({ name: 'round-button' })`
    width: ${({ roundDiameter, theme }) => (roundDiameter ?? theme.button.roundDiameter) + 'px'};
    height: ${({ roundDiameter, theme }) => (roundDiameter ?? theme.button.roundDiameter) + 'px'};
    // Without min-x these can be squished if the container doesn't have room.
    min-width: ${({ roundDiameter, theme }) => (roundDiameter ?? theme.button.roundDiameter) + 'px'};
    min-height: ${({ roundDiameter, theme }) => (roundDiameter ?? theme.button.roundDiameter) + 'px'};
    border-radius: 50%;
    position: absolute;
    position: absolute;
    right: 3px;
    bottom: 3px;
    padding: 3px;
`;

const BigRoundButton = styled(StyledButton).attrs({ name: 'big-round-button' })`
    margin: ${({ buttonStyle, theme }) => (buttonStyle === 'CircleAdd' ? theme.viewMargin + 'px' : 0)};
    font-size: ${fromTheme('fontSizeLarge')};
    width: ${fromTheme('button', 'roundDiameterLarge')};
    height: ${fromTheme('button', 'roundDiameterLarge')};
    border-radius: 50%;
`;

/**
 * @typedef {Object} Props
 * @property {string} [alt]
 * @property {string} [buttonStyle]
 * @property {string} [className]
 * @property {string} [color]
 * @property {boolean} [disabled]
 * @property {string} [hoverText]
 * @property {string} [icon]
 * @property {string} value
 * @property {Object} hNode
 * @property {(e: React.MouseEvent<HTMLButtonElement>) => void} onClick
 */
/**
 * @type {import('react').ForwardRefRenderFunction<HTMLButtonElement, Props>}
 */
const Button = (props, ref) => {
    const {
        id,
        children = [],
        role = 'button',
        icon,
        iconFont,
        iconVariation,
        iconSize,
        hoverText,
        alt,
        // A `Button` blockly block doesn't generate a props.buttonStyle, but puts the style value in the hNode style.
        // In which case buttonStyle is undefined. We don't want to have to worry about where the block came from.
        // And support the same styles for both.
        buttonStyle = props.hNode?.style,
        value,
        color,
        fontColor,
        className,
        disabled,
        disabledColor,
        displayTitleOnButton = true,
        roundDiameter,
        ...otherProps
    } = props;

    const theme = useTheme();
    let adjustedChildren = [];
    const _backgroundColor = color ?? styleToColor(buttonStyle) ?? 'primary';
    if (icon) {
        let iconColor;
        if (disabled) {
            iconColor = disabledColor ?? theme.disabledFontColor;
        } else {
            iconColor =
                fontColor ??
                (['transparent', 'base'].includes(color) ? theme.defaultFontColor : theme.button.fontColor);
        }
        adjustedChildren = [
            rc(
                Icon,
                {
                    disabled,
                    disabledColor,
                    iconFont,
                    iconVariation,
                    color: _backgroundColor,
                    fontColor: iconColor,
                    key: '0',
                    title: hoverText,
                    alt,
                    iconSize
                },
                icon
            )
        ];
        if (value && value.length && buttonStyle !== 'IconAction' && displayTitleOnButton)
            adjustedChildren.push(rc('span', { key: '1' }, value));
    } else {
        adjustedChildren = [value];
    }
    if (children) {
        Array.isArray(children)
            ? (adjustedChildren = [...adjustedChildren, ...children])
            : (adjustedChildren = [...adjustedChildren, children]);
    }
    if (buttonStyle === 'CircleAdd') {
        // prettier-ignore
        return rc(BigRoundButton,
            { id, disabled, alt, role, buttonStyle, ref, color: _backgroundColor, fontColor, className, title: 'Add', roundDiameter, ...otherProps },
            adjustedChildren
        );
    }
    if (['round', 'IconAction', 'BigIconAction'].includes(buttonStyle)) {
        // Don't let title values sneak onto a round button.
        adjustedChildren = adjustedChildren.filter(child => typeof child !== 'string');
        // prettier-ignore
        return rc(RoundButtonContainer, { className, roundDiameter },
            rc(ClockingBorder, { 'data-testid': `clockingBorder${id}`, clocking: otherProps.clocking, roundDiameter }),
            rc(RoundButton,
                { id, disabled, alt, role, ref, color: _backgroundColor, fontColor, title: value, roundDiameter, ...otherProps },
                adjustedChildren
            )
        );
    }

    // prettier-ignore
    return rc(ButtonContainer, { className },
        rc(StyledButton,
            { id, disabled, alt, role, ref, color: _backgroundColor, fontColor, title: !icon ? null : value, roundDiameter, buttonStyle, ...otherProps },
            ...adjustedChildren
        )
    );
};

export default forwardRef(Button);
