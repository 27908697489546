import conversions from '@sstdev/lib_epc-conversions';
import getGlobalConfig from './globalConfig';

/**
 * @typedef {import('../../types').STRATEGY_TYPES} TYPES
 */
export const STRATEGY_TYPES = {
    /**
     * @type {TYPES.ASCII}
     * @description Assume AssetNo is ASCII, and tagId is HEX representation of that
     */
    ASCII: 'ascii',
    /**
     * @type {TYPES.HEX}
     * @description Assume AssetNo is HEX, and tagId will be the same
     */
    HEX: 'hex',
    /**
     * @type {TYPES.GS1}
     * @description GS1 TDS
     * @see https://www.gs1.org/standards/epc-rfid/tds
     */
    GS1: 'gs1',
    /**
     * @type {TYPES.GID}
     * @description GS1 GID
     * @see https://www.gs1.org/standards/epc-rfid/tds
     */
    GID: 'gid',
    /**
     * @type {TYPES._ID}
     * @description Use the _id field of the record
     */
    _ID: '_id'
};

const { ascii, gs1, supportedTypes: types } = conversions;
/**
 * Gets the tagId for a record, E.g. when a new asset is created without tagId
 * THis is used generate the tagId off of the other information, to store it in the database. e.g. in case of an import.
 * Then, when you PRINT that record to a label with RFID, then yes, that value would be encoded onto the tag.
 *
 * The easy scenario, think old "displayInAscii":
 * They import a record with AssetNo 123
 * Based on that feature flag, we'd (try to) match that up with a tagId value of 0x313233 (the hex value of 123)
 *
 * Now, imagine an asset that they gave an assetNo of 12345678901234567890
 * Previously (without these strategies) given that we had displayInAscii, we would convert that to hex, getting the following value:
 * 0x3132333435363738393031323334353637383930, which is 40 character long: too long to encode on an rfid tag.
 * Previously, we would not be able to print a tag for them using an automatically generated tagId value.
 *
 * Item Tracking works around this by allowing the user to enter some other value.
 * But for Asset Tracking, we want it to be more magical.
 *
 * With this strategy, the magic we use is that we generate "some" more than likely unique value that DOES fit on an rfid tag.
 * We then store that in mongo, and subsequently encode that on an rfid tag when printed, it all works fine.
 * Then, when that unique value is read by a fixed or handheld RFID reader, it gets looked up,
 * and totally matches what we have in the database, and the customer is happy.
 *
 * The 2 pieces of background knowledge required in this:
 *
 * 1) Ideally, we (David B) want RFID tag values (tagIds) that actually represent the assetNo if possible.
 *    This allows them to purchase externally pre-encoded labels without much extra communication.
 * 2) If it is not possible, it's not possible. In that case we need to have "some" strategy
 *    to generate a unique tagId in the case they print from our system.
 *    Alternatively, they can still "Associate" any tag, which will then ignore and override the value we generated (it simply goes unused).
 *
 * Obviously, the _id on a record is unique by nature of being an ObjectID. Replacing the first 2 characters does introduce a small but extremely unlikely chance to turn it into a duplicate, but is necessary to be able to detect the encoding strategy used. (0x1A does NOT translate to a printable ascii character, and hence can be used to differentiate from any ascii-conversion based hex value.)
 * @param {object} record
 * @param {string} [titularField='title']
 * @returns {string} hex value to be used as the tagId
 */
export function getTagId(record, titularField = 'title') {
    let value;
    if (typeof record === 'string') {
        value = record;
    } else {
        if (record.tagId) return record.tagId;
        value = record[titularField] || '';
    }
    if (!value) return '';
    const { maxShortLength, shortStrategy, longStrategy } = getRfidConfig();
    if (value.length <= maxShortLength) {
        return convertToHex(value, shortStrategy, record);
    }
    return convertToHex(value, longStrategy, record);
}
function convertToHex(value, strategy, record) {
    switch (strategy) {
        case STRATEGY_TYPES.ASCII:
            return ascii.toHex(value);
        case STRATEGY_TYPES._ID:
            return `1A${record?._id?.substring(2) || value}`.toUpperCase();
        case STRATEGY_TYPES.HEX:
        default:
            return value.replace(/^0+/, '').toUpperCase();
    }
}

/**
 * Gets the value to be displayed for a tagId
 * used in EncodedShortText component
 * @param {string} tagId in hex
 * @returns {string} the value to be displayed instead of the tagId
 */
export function getDisplayValue(tagId) {
    const { displayStrategy } = getRfidConfig();
    if (!tagId) return '';
    if (displayStrategy === STRATEGY_TYPES.ASCII) {
        return ascii.fromHex(tagId);
    }

    if ([STRATEGY_TYPES.GS1, STRATEGY_TYPES.GID].includes(displayStrategy)) {
        const gs1TdsValue = gs1.parse(tagId);
        return gs1TdsValue.toString();
    }

    return tagId;
}
/**
 * Try to guess the titular (assetNo/title) value based on the tagId
 * This is NOT the same as the display value, as the display value is used to display the tagId field,
 * whereas the titular value is used to display (a guess towards) what the title
 * or assetNo value might have been intended to be.
 * e.g. in the case of auto add, or when displaying an unknown tag
 * @param {hex} tagId
 * @returns
 */
export function guessTitularValue(tagId) {
    const { shortStrategy, longStrategy } = getRfidConfig();
    if ([shortStrategy, longStrategy].includes(STRATEGY_TYPES._ID) && tagId.startsWith('1A')) {
        // nothing we can do, just return the value
        return tagId;
    }

    if ([shortStrategy, longStrategy].includes(STRATEGY_TYPES.ASCII)) {
        // ascii: trim leading and trailing 00s
        const trimmedValue = tagId.replace(/^(00)+/, '').replace(/(00)+$/, '');

        // now if it makes sense after converting to ASCII, return that
        const asciiString = ascii.fromHex(tagId);
        if (!/[a-zA-Z0-9]/.test(asciiString)) {
            // but if not, return the (trimmed) hex value
            return trimmedValue;
        }
        return asciiString;
    }

    if (
        [shortStrategy, longStrategy].includes(STRATEGY_TYPES.GS1) ||
        [shortStrategy, longStrategy].includes(STRATEGY_TYPES.GID)
    ) {
        const gs1TdsValue = gs1.parse(tagId);
        if ([types.gid96.id, types.sgtin96.id, types.sgtin198.id].includes(gs1TdsValue.type)) {
            return gs1TdsValue.serial;
        }

        // todo: GS1 TDS will generally contain multiple fields.
        // this will need further mapping!
        return tagId;
    }
    // hex: trim leading 0s
    const trimmedValue = tagId.replace(/^0+/, '');

    return trimmedValue;
}
/**
 * Given a tagId, returns a filter object to be used in a search query
 * to find the corresponding record
 * @param {string} tagId in hex
 * @param {string} [titularField='title']
 * @param {boolean} [simpleSearch = true] whether to return a search filter for just tagId, or also include the titularField
 * @returns {{tagId:string}|{$or:[object]}} a filter object to be used in a search query
 */
export function getFilter(tagId, titularField = 'title', simpleSearch = true) {
    const { shortStrategy, longStrategy } = getRfidConfig();
    if ([shortStrategy, longStrategy].includes(STRATEGY_TYPES._ID) && tagId.startsWith('1A')) {
        if (simpleSearch) {
            return { tagId };
        }
        return { $or: [{ _id: { $regex: new RegExp(`^..${tagId.slice(2)}$`) } }, { tagId }] };
    }

    if ([shortStrategy, longStrategy].includes(STRATEGY_TYPES.ASCII)) {
        // ascii: trim leading and trailing 00s
        const trimmedValue = tagId.replace(/^(00)+/, '').replace(/(00)+$/, '');
        if (simpleSearch) {
            return { tagId: trimmedValue };
        }

        const asciiString = ascii.fromHex(tagId);
        if (!/[a-zA-Z0-9]/.test(asciiString)) {
            return { tagId: trimmedValue };
        }
        return { $or: [{ [titularField]: ascii.fromHex(tagId) }, { tagId: trimmedValue }] };
    }

    if (
        [shortStrategy, longStrategy].includes(STRATEGY_TYPES.GS1) ||
        [shortStrategy, longStrategy].includes(STRATEGY_TYPES.GID)
    ) {
        if (simpleSearch) {
            return { tagId };
        }
        const gs1TdsValue = gs1.parse(tagId);
        if (gs1TdsValue.type === types.gid96.id) {
            return { $or: [{ [titularField]: gs1TdsValue.serial?.toString() || '' }, { tagId }] };
        }

        // todo: GS1 TDS will generally contain multiple fields.
        // this will need further mapping!
        return { tagId };
    }
    // hex: trim leading 0s
    const trimmedValue = tagId.replace(/^0+/, '');
    if (simpleSearch) {
        return { tagId: trimmedValue };
    }
    return { $or: [{ [titularField]: trimmedValue }, { tagId: trimmedValue }] };
}

/**
 * Gets the RFID configuration, or infers it from feature flags
 * @returns {{
 *  maxShortLength:number,
 *  shortStrategy:string,
 *  longStrategy:string,
 *  displayStrategy:string
 * }} rfidConfig
 */
export function getRfidConfig() {
    const globalConfig = getGlobalConfig();
    // unnecessary to set defaults here, as initialization is guaranteed
    // but it helps understanding the expected structure
    // this is RedBeam configuration:
    let rfidConfig = {
        maxShortLength: 12,
        shortStrategy: STRATEGY_TYPES.ASCII,
        longStrategy: STRATEGY_TYPES._ID,
        displayStrategy: STRATEGY_TYPES.HEX
    };
    if (globalConfig.rfid) {
        const {
            maxShortLength = rfidConfig.maxShortLength,
            shortStrategy = rfidConfig.shortStrategy,
            longStrategy = rfidConfig.longStrategy,
            displayStrategy = rfidConfig.displayStrategy
        } = globalConfig.rfid || {};
        rfidConfig = { maxShortLength, shortStrategy, longStrategy, displayStrategy };
    } else {
        if (globalConfig.featureFlags?.includes('displayInAscii')) {
            rfidConfig.shortStrategy = STRATEGY_TYPES.ASCII;
            rfidConfig.longStrategy = STRATEGY_TYPES.ASCII;
            rfidConfig.displayStrategy = STRATEGY_TYPES.ASCII;
        } else {
            rfidConfig.shortStrategy = STRATEGY_TYPES.HEX;
            rfidConfig.longStrategy = STRATEGY_TYPES.HEX;
            rfidConfig.maxShortLength = 24;
            rfidConfig.displayStrategy = STRATEGY_TYPES.HEX;
        }
    }
    return rfidConfig;
}

export default { getTagId, guessTitularValue, getDisplayValue, getFilter, getRfidConfig, STRATEGY_TYPES };
