import { useContext, createElement } from 'react';
import { NavbarContext } from '../NavbarContextProvider';
import { styled, View, fromTheme } from 'lib_ui-primitives';
import propTypes from 'prop-types';

const rc = createElement;

const LockedContainer = styled(View).attrs({ name: 'locked-main-container' })`
    margin-left: ${({ theme }) => (theme.mobile ? 0 : theme.viewMargin + +'px')};
    display: inherit;
    position: absolute;
    left: ${fromTheme('drawerWidth')};
    top: ${fromTheme('outerMenus', 'headerHeight')};
    width: ${({ theme }) => theme.width - theme.drawerWidth - theme.viewMargin + 'px'};
    height: ${({ theme }) => theme.height - theme.outerMenus.headerHeight + 'px'};
`;

const UnlockedContainer = styled(View).attrs({ name: 'unlocked-main-container' })`
    margin-left: ${({ theme }) => (theme.mobile ? 0 : theme.viewMargin + +'px')};
    max-width: ${fromTheme('width')};
    flex-grow: 1;
    height: ${({ theme }) => theme.height - theme.outerMenus.headerHeight - theme.viewMargin + 'px'};
`;

function MainContainer(props) {
    const { locked, ready } = useContext(NavbarContext);
    if (!ready) return null;
    if (!props.excludeSideBar && locked) return rc(LockedContainer, null, props.children);
    return rc(UnlockedContainer, null, props.children);
}
MainContainer.propTypes = {
    children: propTypes.node,
    excludeSideBar: propTypes.bool
};
export default MainContainer;
