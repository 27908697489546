import { EncryptPin, UpdateServerWithNewPin } from '../../../utilities/pinUtilities';

const _p = {
    EncryptPin,
    UpdateServerWithNewPin
};
export const _private = _p;

export default {
    verb: 'doingCreate',
    namespace: 'security',
    relation: 'profile',
    type: 'setOfflinePin',
    logic
};

async function logic({ data, context }) {
    const { offlinePin } = data?.newRecord ?? {};
    const { session: { userId } } = context;

    if (offlinePin == null) {
        throw new Error('Offline PIN is required, but missing.');
    }

    if (userId == null) {
        throw new Error('User ID is required, but missing.');
    }

    // encrypt the pin
    const {encryptedPin, salt } = await _p.EncryptPin(offlinePin);

    // update the server with the new pin
    await _p.UpdateServerWithNewPin(encryptedPin, salt, userId);

    return { ...data, encryptedPin, salt };
}
