import lodash from 'lodash';
const { cloneDeep } = lodash;
import setDefaultValuesIfNecessary from '../../../../utilities/setDefaultValuesIfNecessary';
import { constants } from 'lib_ui-services';
import { UserError } from '@sstdev/lib_logging';

const _p = {
    setDefaultValuesIfNecessary
};

export const _private = _p;

export default {
    verb: 'didChange',
    namespace: 'transaction',
    description: 'look up current stock',
    useCaseIds: [constants.useCaseIds.REDBEAM_INVENTORY_TRACKING],
    prerequisites: [
        {
            // receive location stock
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'stock',
                type: 'find'
            },
            query: async ({ data, context }) => {
                const { newRecord: _newRecord, hNode } = data;
                const newRecord = cloneDeep(_newRecord);
                // Need to merge in defaults so user doesn't have to re-select location
                await _p.setDefaultValuesIfNecessary({ ...context, isNew: true, hNode }, newRecord);

                let { 'item:item': item } = newRecord;

                let locationIds = [];

                if (newRecord.receive?.['location:location']) {
                    locationIds.push(newRecord.receive['location:location']._id);
                }
                if (newRecord.issue?.['location:location']) {
                    locationIds.push(newRecord.issue['location:location']._id);
                }

                if (!item) {
                    throw new UserError('Item not selected');
                }
                if (!locationIds.length) {
                    throw new UserError('Location not selected');
                }

                return {
                    'item:item._id': item._id,
                    'location:location._id': { $in: locationIds },
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    //this is the actual logic:
    logic: didChange
};

async function didChange({ data, context, prerequisiteResults }) {
    const { newRecord: _newRecord, hNode } = data;
    const newRecord = cloneDeep(_newRecord);
    // Need to merge in defaults so user doesn't have to re-select location
    await _p.setDefaultValuesIfNecessary({ ...context, isNew: true, hNode }, newRecord);

    const [{ result: existingStock = [] }] = prerequisiteResults;

    let existingReceiveStock, existingIssueStock;

    if (newRecord.receive?.['location:location']) {
        const receiveStock = existingStock.filter(
            stock => stock['location:location']._id === newRecord.receive['location:location']._id
        );
        if (receiveStock.length > 1) {
            throw new Error(
                `Multiple stock records found for same location: ${newRecord.receive['location:location']._id}`
            );
        }
        if (receiveStock.length) {
            existingReceiveStock = receiveStock[0];
        }
    }
    if (newRecord.issue?.['location:location']) {
        const issueStock = existingStock.filter(
            stock => stock['location:location']._id === newRecord.issue['location:location']._id
        );
        if (issueStock.length > 1) {
            throw new Error(
                `Multiple stock records found for same location: ${newRecord.issue['location:location']._id}`
            );
        }
        if (issueStock.length) {
            existingIssueStock = issueStock[0];
        }
    }

    const receiveQuantity = existingReceiveStock?.quantity || 0;
    const issueQuantity = existingIssueStock?.quantity || 0;

    return {
        ...data,
        newRecord: {
            ...data.newRecord,
            currentInventoryFrom: issueQuantity.toString(),
            currentInventoryTo: receiveQuantity.toString()
        },
        oldRecord: {
            ...data.oldRecord,
            currentInventoryFrom: issueQuantity.toString(),
            currentInventoryTo: receiveQuantity.toString()
        }
    };
}
