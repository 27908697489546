import log from '@sstdev/lib_logging';
import { default as globalConfig } from '../globalConfig/index';
import { default as Database } from './Database';
import EventSink from '../eventSink';
import { getKey, setKey, getPageLoadId } from '../pageLoadStorage';

const DATABASE_KEY = 'loki_database';
const DATABASE_READINESS_KEY = 'loki_database_readiness';
function eventSink() {
    return EventSink();
}

export default {
    get: getDatabase,
    isDbReady,
    initialize: InitializeForRuntime
};

function getDatabase() {
    const db = getKey(DATABASE_KEY);
    if (db == null) {
        throw new Error(
            'The lokijs database has not been initialized.  (See the initialize function in lib_ui-services/src/database/index.js).'
        );
    }
    return db;
}

function getReadiness() {
    let readiness = getKey(DATABASE_READINESS_KEY);
    if (readiness == null) {
        readiness = {};
        readiness.promise = new Promise((resolve, reject) => {
            readiness.setReady = resolve;
            readiness.setCrashed = reject;
        });
        setKey(DATABASE_READINESS_KEY, readiness);
    }
    return readiness;
}

export function isDbReady() {
    return getReadiness().promise;
}

export async function InitializeForRuntime(session, useCaseMetadata) {
    log.debug(`[PERSISTENCE] Initializing for session ${getPageLoadId()}`);
    const readiness = getReadiness();
    try {
        await initialize({
            config: globalConfig(),
            namespaces: useCaseMetadata.namespaces,
            tenantId: session.tenant['identity:tenant']._id,
            useCaseId: session.useCase['metaui:useCase']._id,
            groupUrlPrefix: `/g/${session.groupNumber}`
        });
        readiness.setReady();
        log.debug(`[PERSISTENCE] Initialized for session ${getPageLoadId()}`);
    } catch (error) {
        // If something has the promise, then inform it that we crashed.
        readiness.setCrashed(error);
        throw error;
    }
}

async function initialize(payload) {
    const { config, namespaces, tenantId, useCaseId } = payload;
    const [, publish] = eventSink();
    const settings = {
        config,
        namespaces,
        tenantId,
        useCaseId,
        actionPublisher: publish
    };
    setKey(DATABASE_KEY, new Database(settings));

    await getDatabase().initializeStorage();
    return getDatabase().dispatch([], payload, {
        verb: 'persist',
        namespace: 'application',
        relation: 'useCase',
        type: 'initialize'
    });
}
