// @ts-check
import { eventSink, localStorage } from 'lib_ui-services';

export const TENANT_SETTINGS_NAMESPACE = 'tenant_settings';

const _p = {
    eventSink
};
export const _private = _p;

/**
 * effectively just localstorage, with automatic dispatch of changes
 * @type {import('../BbState').Storage}
 */
export default {
    isAsync: true,
    get: async function get(key, defaultValue, usePrefix = true) {
        return localStorage.getKey(key, TENANT_SETTINGS_NAMESPACE, defaultValue, usePrefix);
    },
    set: async function set(key, value, usePrefix = true, suppressDispatch = false) {
        await localStorage.setKey(key, value, TENANT_SETTINGS_NAMESPACE, usePrefix);
        if (!suppressDispatch) {
            dispatchTenantChange(key, value);
        }
    },
    remove: async function remove(key, usePrefix = true) {
        return localStorage.deleteKey(key, TENANT_SETTINGS_NAMESPACE, usePrefix);
    },
    clear: async function clear(usePrefix) {
        return localStorage.deleteInstance(TENANT_SETTINGS_NAMESPACE, usePrefix);
    },
    getSync: function getSync(key, defaultValue) {
        return defaultValue;
    }
};

function dispatchTenantChange(key, newState) {
    const [, publish] = _p.eventSink();
    const patch = {
        op: newState == null ? 'remove' : 'replace',
        path: `/${key}`,
        value: newState
    };
    publish([patch], { verb: 'update', namespace: 'identity', relation: 'tenantSetting' });
}
