// @ts-check
/**
 * Selection Retention:
 *  Scope: Local, PerRoute, Global
 *  Visibility: PerBrowser (later: PerUser, PerTenant)
 *  Duration: Request, Runtime, user Session, Always
 * @typedef {import('../BbState/BbState').Retention} Retention
 *
 */
import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import useSimpleChangeObserver from './useSimpleChangeObserver';
import useTimeout from './useTimeout';
import BbState from '../BbState';
import useRouter from './useRouter';
import { constants } from 'lib_ui-services';
import useMemoizedObject from './useMemoizedObject';
// import useWhatChanged from '../../utility/useWhatChanged';

const { SCOPE } = constants.retention;

const _p = {
    useRouter,
    useMemoizedObject
};
export const _private = _p;

/**
 * This acts like useState, but retains the state according to the retention settings specified.
 * Scopes of perTenant and perUser are not yet implemented.  When they are, it might
 * (depending on the implementation) be important to change the usePrefix parameter of
 * the localStorage methods to reflect the scope.
 * Be aware that duration 'ALWAYS' state _can_ cross use cases, therefore always use a stateName that
 * includes an id (for something) to avoid collisions.
 * @template T
 * @param {T} _defaultValue - the default value to store/retrieve
 * @param {string} stateName - name of state to store/retrieve
 * @param {Retention} [_retention]
 * @returns {[T|undefined, function, boolean]} array containing the current state, the function to change it, and a boolean indicating whether the state is ready
 * to be used. The last value is only applicable for DURATION.ALWAYS and DURATION.USER_SESSION because the storage operation in localForage is async.
 */
function useBbState(_defaultValue, stateName, _retention /*, debugText /* useful for debugging state changes */) {
    // MANY places don't properly cache the parameters passed into here
    // So we need to cache them ourselves to prevent unnecessary rerenders
    const defaultValue = _p.useMemoizedObject(_defaultValue);
    const retention = _p.useMemoizedObject(_retention);

    // Very helpful for debugging state changes
    // Output in console. Grey dot means unchanged, green dot means changed.
    // useWhatChanged({ stateName, retention, defaultValue });

    // END caching parameters

    const router = _p.useRouter();
    const routeStateKey = router.getRouteStateKey();

    // This handles the edge case where the route changes, but the component doesn't.
    // This happens for instance with navigationSelectedContextPage
    // Even though the exact same component is still rendered, we need to reinitialize the state
    // to the last stored state for this storage key.
    // Because this useMemo for bbState is dependent on the routeStateKey,
    // it WILL give us a new bbState when the route changes, and hence, it gets re-initialized.
    const bbState = useMemo(() => {
        if (retention?.scope === SCOPE.PER_ROUTE) {
            return BbState.connect(retention, routeStateKey);
        }
        return BbState.connect(retention);
    }, [retention, routeStateKey]);

    const [ready, setReady] = useState(!bbState.isAsync);
    const [localState, _setLocalState] = useState(bbState.getSync(stateName, defaultValue));

    useEffect(() => {
        async function getAsync() {
            const actualValue = await bbState.getAsync(stateName, defaultValue);
            if (allowStateChange.current) {
                _setLocalState(actualValue);
                setReady(true);
            }
        }
        if (bbState.isAsync) {
            getAsync();
        }
    }, [bbState, defaultValue, stateName]);

    const setState = useCallback(
        newState => {
            async function doAsync() {
                const newValue = await bbState.setAsync(newState, stateName, defaultValue);
                if (allowStateChange.current) {
                    _setLocalState(newValue);
                }
            }
            doAsync();
        },
        [bbState, defaultValue, stateName]
    );

    // Because a change to useBbState in one component can update the useBbState
    // in another component (assuming they have the same storage key), this
    // makes the subscription for the change async, avoiding an update to the
    // second component while the first is still rendering.
    const asyncStateUpdate = useTimeout(
        newState => {
            if (allowStateChange.current) {
                _setLocalState(newState);
            }
        },
        [],
        0
    );

    // Coordinate state changes between components using the same storage key
    const { onChange } = useSimpleChangeObserver(true);
    const storageKey = useMemo(() => bbState.getStorageKey(stateName), [bbState, stateName]);

    useEffect(() => {
        onChange((_changeType, { storageKey: key, newState }) => {
            if (_changeType === BbState.CHANGE_TYPE && key === storageKey) {
                // Useful for debugging observations of state changes
                // console.log(debugText, 'useBbState.onChange', { storageKey, newState });
                asyncStateUpdate(newState);
            }
        });
    }, [onChange, asyncStateUpdate, storageKey]);

    // Prevent state updates after this is destroyed.
    const allowStateChange = useRef(true);
    useEffect(
        () => () => {
            allowStateChange.current = false;
        },
        []
    );

    return [localState, setState, ready];
}
export default useBbState;
