import { dbViews } from 'lib_ui-services';
const _private = {
    destroyDbViewForRelation: dbViews.destroyDbViewForRelation
};
export default {
    verb: 'didPurge',
    description: 'Removed the requested collection from the database',
    //this is the actual logic:
    logic,
    _private
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data, context }) {
    //destroy the dbViews for the purged relations
    if (data.result != null && context.namespace === 'application' && context.relation === 'useCase') {
        // if synchronization triggered this, it will pass in an array of relations to purge
        data.result.forEach(({ namespace, relation }) => {
            _private.destroyDbViewForRelation(namespace, relation);
        });
    } else {
        // if not, just purge the single relation
        return _private.destroyDbViewForRelation(context.namespace, context.relation);
    }
}
