import owasp from 'owasp-password-strength-test';
import getCurrentSession from '../authentication/getCurrentSession';
import validate from 'validate.js';
import lodash from 'lodash';
const { omit } = lodash;
import { getAllDisplayProperties, getDictionary } from '../metadata';
const { get } = lodash;
import logging from '@sstdev/lib_logging';
const _p = { getCurrentSession };
export const _private = _p;
/**
 * @param {object} model
 * @param {object} hNode
 * @param {object} [context]
 */
export async function withHNode(model, hNode, context) {
    let children = getEligibleNodes(hNode);
    let constraints = {};
    for (let i = 0; i < children.length; i++) {
        const child = children[i];
        await addConstraintsForChild(child, constraints, context);
    }
    validate.validators.complexity = passwordComplexityValidator;
    let errors = validate(model, constraints);
    let subModelErrors = await validateSubModels(model, hNode, context);
    return { ...errors, ...subModelErrors };
}

export async function withDictionary(model, namespaceName, relationName, context) {
    validate.validators.complexity = passwordComplexityValidator;
    const dictionary = await getDictionary();
    const properties = omit(dictionary[namespaceName][relationName], '_meta');
    return validateProperties(model, properties, context);
}

export async function validateEncodedField(hNode, constraint, context) {
    if (hNode.hNodeType === 'ShortEncodedText') {
        let user = context?.session;
        if (!user) {
            user = await _p.getCurrentSession(context);
        }
        const featureFlags = user.allFeatureFlags;
        if (!featureFlags.includes('displayInAscii')) {
            constraint.formatEmptyOk = {
                pattern: /^[0-9A-F]*$/,
                message: ': Please only use valid HEX (0-9 A-F)'
            };
        }
    }
}

async function validateProperties(model, properties, context) {
    const constraints = {};
    let accumulatedErrors = {};
    const propertyValues = Object.values(properties);
    for (let i = 0; i < propertyValues.length; i++) {
        const property = propertyValues[i];
        await addConstraintsForChild(property._meta, constraints, context);
        const subProperties = omit(property, '_meta');
        const subModel = model[property.propertyName];
        if (subModel != null && subProperties.length > 0) {
            const subErrors = validateProperties(subModel, subProperties, context);
            accumulatedErrors = { ...accumulatedErrors, ...subErrors };
        }
    }
    const errors = validate(model, constraints);
    accumulatedErrors = { ...accumulatedErrors, ...errors };
    return accumulatedErrors;
}

function passwordComplexityValidator(value) {
    if (typeof value === 'undefined' || value === null || value === '') return;

    //use mostly default config values:
    owasp.config({
        //     allowPassphrases       : true,
        //     minLength              : 10,
        //     minPhraseLength        : 20,
        //     minOptionalTestsToPass : 4,
        maxLength: 256 //default 128
    });
    const result = owasp.test(value);

    if (result.errors.length) {
        result.errors.push('can instead also be a passphrase of at least 20 characters.');
        return result.errors.map(e => e.replace('The password ', ''));
    }
    //no need to return anything when all is well.
}

async function validateSubModels(parentModel, parentHNode, context) {
    let results = {};
    if (!parentHNode || !parentHNode.children) return results;

    let containerProperties = parentHNode.children.filter(c => !!c.children);
    for (let i = 0; i < containerProperties.length; i++) {
        const cp = containerProperties[i];

        let childResults;
        if (parentModel.hasOwnProperty(cp.propertyName)) {
            let childModel = parentModel[cp.propertyName];
            if (Array.isArray(childModel)) {
                // Only evaluate submodels if they exist in the parentModel
                // (regardless of whether they exist in hNode)
                // Submodel should be required (and should, therefore, have already failed)
                // if this is not the desired behavior.
                if (childModel.length > 0) {
                    childResults = [];
                    for (let i = 0; i < childModel.length; i++) {
                        const cm = childModel[i];
                        let childResult = await withHNode(cm, cp, context);
                        if (childResult && Object.keys(childResult).length) {
                            childResults.push(childResult);
                        } else {
                            childResults.push(undefined);
                        }
                    }
                    if (childResults.length && childResults.some(r => r != null)) {
                        results[cp.propertyName] = childResults;
                    }
                }
            } else {
                childResults = await withHNode(childModel, cp, context);
                if (Object.keys(childResults).length) {
                    results[cp.propertyName] = childResults;
                }
            }
        } else {
            // If this container property DOES have a property name, it does not exist in the model
            // This means the user did not create this value.  If it is required, it should
            // have already failed, therefore do nothing further.
            // If the container property DOES NOT have a property name, it is probably a layout
            // or similar, so keep recursing.
            if (!cp.propertyName && cp.children) {
                childResults = await withHNode(parentModel, cp, context);
                if (Object.keys(childResults).length) {
                    results = { ...results, ...childResults };
                }
            }
        }
    }
    return results;
}

function getEligibleNodes(hNodeParent) {
    let startingSet = [];
    if (hNodeParent && hNodeParent.children) {
        hNodeParent.children.forEach(child => {
            if (child.hNodeTypeGroup === 'formElement') {
                startingSet.push(child);
            }
        });
    }
    return startingSet;
}

async function addConstraintsForChild(hNode, constraints, context) {
    let constraint = {};
    validateRequired(hNode, constraint);
    validateMinimumLength(hNode, constraint);
    validateMaximumLength(hNode, constraint);
    validatePattern(hNode, constraint);
    validateDateFormat(hNode, constraint);
    validateForeignKeys(hNode, constraint);
    validateIntegers(hNode, constraint);
    validateCurrency(hNode, constraint);
    validateEmails(hNode, constraint);
    validatePassword(hNode, constraint);
    validatePin(hNode, constraint);
    await validateEncodedField(hNode, constraint, context);

    // If drop down, uses foreign document path instead of just propertyName
    let propertyPath =
        hNode.foreignNamespace && hNode.foreignRelation
            ? `${hNode.foreignNamespace}:${hNode.originalRelation || hNode.foreignRelation}`
            : hNode.propertyName;

    constraints[propertyPath] = constraints[propertyPath]
        ? { ...constraints[propertyPath], ...constraint }
        : constraint;
}

function validateRequired(hNode, constraint) {
    if (hNode.required) {
        constraint.presence = { message: `^${hNode.title} cannot be empty`, allowEmpty: false };
    }
}
function validateMinimumLength(hNode, constraint) {
    if (typeof hNode.minLength !== 'undefined') {
        constraint.lengthEmptyOk = {
            minimum: hNode.minLength,
            message: `^${hNode.title} is too short (minimum is ${hNode.minLength} characters).`
        };
    }
}
function validateMaximumLength(hNode, constraint) {
    if (typeof hNode.maxLength !== 'undefined') {
        if (constraint.lengthEmptyOk) {
            constraint.lengthEmptyOk = {
                ...constraint.lengthEmptyOk,
                maximum: hNode.maxLength,
                message:
                    hNode.minLength === hNode.maxLength
                        ? `^${hNode.title} must be ${hNode.minLength} characters long.`
                        : `^${hNode.title} must be between ${hNode.minLength} and ${hNode.maxLength} characters long.`
            };
        } else {
            constraint.lengthEmptyOk = {
                maximum: hNode.maxLength,
                message: `^${hNode.title} is too long (maximum is ${hNode.maxLength} characters).`
            };
        }
    }
}
function validatePattern(hNode, constraint) {
    if (typeof hNode.pattern !== 'undefined') {
        constraint.formatEmptyOk = {
            pattern: hNode.pattern,
            message: `^${hNode.title} is invalid.`
        };
    }
}
function validateDateFormat(hNode, constraint) {
    if (hNode.hNodeType === 'DatePicker') {
        constraint.datetimeEmptyOk = {
            dateOnly: false,
            message: `^${hNode.title} "%{value}" is not a valid date.`
        };
    }
}
function validateIntegers(hNode, constraint) {
    if (hNode.hNodeType === 'Integer') {
        constraint.numericality = function (value) {
            let config = {
                onlyInteger: true,
                noStrings: true,
                message: `^${hNode.title} must be an integer.`
            };
            if (value !== 0 || hNode.required) {
                if (hNode.min && value < hNode.min) {
                    config.greaterThanOrEqualTo = hNode.min;
                    config.message = `^${hNode.title} must be an integer greater than or equal to ${hNode.min}.`;
                } else if (value <= Number.MIN_SAFE_INTEGER) {
                    config.greaterThanOrEqualTo = Number.MIN_SAFE_INTEGER + 1;
                    config.message = `^${hNode.title} must be an integer greater than ${Number.MIN_SAFE_INTEGER}.`;
                }

                if (hNode.max && value > hNode.max) {
                    config.lessThanOrEqualTo = hNode.max;
                    config.message = `^${hNode.title} must be an integer less than or equal to ${hNode.max}.`;
                } else if (value >= Number.MAX_SAFE_INTEGER) {
                    config.lessThanOrEqualTo = Number.MAX_SAFE_INTEGER - 1;
                    config.message = `^${hNode.title} must be an integer less than ${Number.MAX_SAFE_INTEGER}.`;
                }
                if (hNode.min && hNode.max && (value < hNode.min || value > hNode.max)) {
                    config.greaterThanOrEqualTo = hNode.min;
                    config.lessThanOrEqualTo = hNode.max;
                    config.message = `^${hNode.title} must be an integer between ${hNode.min} and ${hNode.max}.`;
                }
            }
            return config;
        };
    }
}
function validateCurrency(hNode, constraint) {
    if (hNode.hNodeType === 'Currency') {
        constraint.numericality = function (value) {
            let config = {
                onlyInteger: false,
                noStrings: true,
                message: `^${hNode.title} must be an number.`
            };
            if (value !== 0 || hNode.required) {
                if (hNode.min && value < hNode.min) {
                    config.greaterThanOrEqualTo = hNode.min;
                    config.message = `^${hNode.title} must have a value greater than or equal to ${hNode.min}.`;
                } else if (value <= Number.MIN_SAFE_INTEGER) {
                    config.greaterThanOrEqualTo = Number.MIN_SAFE_INTEGER + 1;
                    config.message = `^${hNode.title} must have a value greater than ${Number.MIN_SAFE_INTEGER}.`;
                }

                if (hNode.max && value > hNode.max) {
                    config.lessThanOrEqualTo = hNode.max;
                    config.message = `^${hNode.title} must have a value less than or equal to ${hNode.max}.`;
                } else if (value >= Number.MAX_SAFE_INTEGER) {
                    config.lessThanOrEqualTo = Number.MAX_SAFE_INTEGER - 1;
                    config.message = `^${hNode.title} must have a value less than ${Number.MAX_SAFE_INTEGER}.`;
                }
                if (hNode.min && hNode.max && (value < hNode.min || value > hNode.max)) {
                    config.greaterThanOrEqualTo = hNode.min;
                    config.lessThanOrEqualTo = hNode.max;
                    config.message = `^${hNode.title} must have a value between ${hNode.min} and ${hNode.max}.`;
                }
            }
            return config;
        };
    }
}
function validateEmails(hNode, constraint) {
    if (hNode.hNodeType === 'Email') {
        constraint.emailEmptyOk = {
            message: `^${hNode.title} of %{value} doesn't look like a valid email.`
        };
    }
}
function validatePassword(hNode, constraint) {
    if (hNode.hNodeType === 'PasswordWithConfirm' && hNode.propertyName !== 'pin') {
        constraint.complexity = { complexity: 'owasp' };
    }
}
function validatePin(hNode, constraint) {
    if (hNode.hNodeType === 'PasswordWithConfirm' && hNode.propertyName === 'pin') {
        constraint.lengthEmptyOk = {
            minimum: 4,
            maximum: 8,
            message: 'must be between 4 and 8 characters long.'
        };
        constraint.formatEmptyOk = {
            pattern: /^[0-9]*$/,
            message: 'must contain only numbers.'
        };
    }
}
function validateForeignKeys(hNode, constraint) {
    if (hNode.foreignNamespace && hNode.foreignRelation) {
        constraint.foreignKey = {
            message: `^${hNode.title} is invalid.`,
            hNode
        };
    }
}

// custom validator for things like dropDowns that create foreign key objects
validate.validators.foreignKey = function (value, options) {
    if (value === null || typeof value === 'undefined') return;
    if (Array.isArray(value)) {
        if (value.some(v => !isValidForeignKeyResult(v, options.hNode))) {
            return options.message;
        }
    } else if (validate.isObject(value)) {
        if (!isValidForeignKeyResult(value, options.hNode)) {
            return options.message;
        }
    } else {
        return options.message;
    }
};

function isValidForeignKeyResult(value, hNode) {
    const [requiredProperties, legacyDisplayProperty] = getAllDisplayProperties(hNode);

    let { namespace, relation, foreignNamespace, foreignRelation } = hNode;
    namespace = namespace ?? foreignNamespace;
    relation = relation ?? foreignRelation;
    if (requiredProperties.length === 0) {
        if (!legacyDisplayProperty) {
            throw new Error(
                `At least one display property should be required for foreign key ${namespace}:${relation}`
            );
        }
    }
    requiredProperties.push('_id');
    // Look at all the required properties for the value and fail for the
    // first one missing.
    let hasAllRequired = true;
    let warningMessages = [];
    for (let i = 0; i < requiredProperties.length; i++) {
        const path = requiredProperties[i];
        if (get(value, path) == null) {
            // Is the property missing?
            warningMessages.push(
                `[VALIDATION] required property ${path} was missing from ${namespace}:${relation} foreign key, but legacy required properties were present. `
            );
            hasAllRequired = false;
            break;
        }
    }
    // If the display properties are not present, this may be legacy data,
    // ensure that at least the legacy displayProperty is present.
    if (!hasAllRequired) {
        const hasLegacyProperties = value._id != null && get(value, legacyDisplayProperty) != null;
        // Let dev know some fishy data is here, but don't fail.
        if (hasLegacyProperties) {
            warningMessages.forEach(m => logging.debug(m));
        }
        return hasLegacyProperties;
    }
    return hasAllRequired;
}

// Create copies of validators that ignores empty strings
// (defer to presence/required validator for empty strings).
validate.validators.emailEmptyOk = function (value) {
    if (value === '') return null;
    return validate.validators.email.apply(validate.validators.email, arguments);
};
validate.validators.lengthEmptyOk = function (value, options, key, attributes) {
    if (value === '') return null;
    return validate.validators.length(value, options, key, attributes);
};
validate.validators.formatEmptyOk = function (value, options, key, attributes) {
    if (value === '') return null;
    return validate.validators.format(value, options, key, attributes);
};
validate.validators.datetimeEmptyOk = function (value, options) {
    if (typeof value === 'undefined' || value === null || value === '') return null;
    if (isNaN(Date.parse(value))) {
        return options.message;
    }
    return validate.validators.datetime.apply(validate.validators.datetime, arguments);
};

// validate.js does not include date parsing and formatting functions,
// but requires you to add them like this:
validate.extend(validate.validators.datetime, {
    parse: function (value) {
        return new Date(value);
    },
    format: function (value) {
        return value.toISOString();
    }
});
