import { useEffect, createElement as rc, createContext, useState, useCallback } from 'react';
import useEventSink from '../../hooks/useEventSink';
import { localStorage } from 'lib_ui-services';
import { useTheme } from 'styled-components';

const SIDEBAR_LOCKED = 'sidebarLocked';
export const NavbarContext = createContext({ open: false });
export default function Navbar(props) {
    const { mobile } = useTheme();
    const [locked, _setLocked] = useState(!mobile);
    const [open, setOpen] = useState(!mobile);
    const [ready, setReady] = useState(false);
    const [subscribe] = useEventSink();

    // Track whether the user likes the sidebar locked.
    const setLocked = useCallback(locked => {
        if (typeof locked === 'function') {
            _setLocked(isLocked => {
                let newLocked = locked(isLocked);
                localStorage.setKey(SIDEBAR_LOCKED, JSON.stringify(newLocked));
                return newLocked;
            });
        } else {
            localStorage.setKey(SIDEBAR_LOCKED, JSON.stringify(locked));
            _setLocked(locked);
        }
    }, []);

    // Recover user's sidebar locked preference
    useEffect(() => {
        (async () => {
            let locked = await localStorage.getKey(SIDEBAR_LOCKED, undefined, JSON.stringify(!mobile));
            locked = locked === 'true';
            _setLocked(locked);
            setReady(true);
        })();
    }, [mobile]);

    useEffect(() => {
        const unsubscribe = subscribe({ verb: 'navigate', namespace: 'application', relation: 'route' }, () => {
            if (!locked) setOpen(false);
        });
        return () => unsubscribe();
        // subscribing more than once serves no purpose and could create a memory leak.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscribe]);

    const toggleOpen = () => setOpen(isOpen => !isOpen);
    const toggleLockState = () => setLocked(isLocked => !isLocked);
    return rc(
        NavbarContext.Provider,
        { value: { ready, open: locked ? true : open, setOpen, toggleOpen, toggleLockState, locked, setLocked } },
        props.children
    );
}
