import { useState, useCallback, useContext, useEffect, useRef } from 'react';
import { contexts, hooks } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
import lodash from 'lodash';
const { isEqual } = lodash;

const { SCOPE, VISIBILITY, DURATION } = constants.retention;
const { useBbState, useRouter } = hooks;
const searchElementRetention = {
    scope: SCOPE.PER_ROUTE,
    duration: DURATION.PAGE_LOAD,
    visibility: VISIBILITY.PER_BROWSER
};

const _p = {
    useRouter
};
export const _private = _p;
/**
 * Higher Order Component that takes care of applying filter values to the search context
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function useSearchElement(props, valueToFilter, defaultValue) {
    const {
        hNode,
        hNode: { id },
        onFocus: _onFocus,
        onBlur: _onBlur
    } = props || { hNode: {} };

    // default to whatever was previously set for this route.
    const [value, setValue] = useBbState(defaultValue, id, searchElementRetention);
    const [active, setActive] = useState(false);
    const router = _p.useRouter();

    const onFocus = useCallback(
        /**
         * @param {import('react').SyntheticEvent} event
         */
        event => {
            setActive(true);
            event.target.select?.();
            _onFocus?.();
        },
        [_onFocus]
    );

    const onBlur = useCallback(() => {
        setActive(false);
        _onBlur?.();
    }, [_onBlur]);

    // Inform search pane that search element value changed
    const valueChanged = useContext(contexts.SearchContext);

    // Only trigger this "initial" valueChanged on the first render or if the route
    // search/query params change.  The route search/query params might, for instance,
    // change when navigating between the same dynamic report page but with different
    // inventories (the inventory id is in the url query params).
    const currentRoute = router.location.pathname + router.location.search;
    const prevRoute = useRef();
    useEffect(() => {
        if (prevRoute.current !== currentRoute) {
            prevRoute.current = currentRoute;
            if (value == null || isEqual(value, defaultValue)) {
                return;
            }

            valueChanged(hNode, valueToFilter(value === defaultValue ? undefined : value));
        }
    }, [valueChanged, value, hNode, defaultValue, valueToFilter, currentRoute]);

    const setValueProxy = useCallback(
        newValue => {
            setValue(prev => {
                if (
                    (newValue != null && !isEqual(newValue, defaultValue)) ||
                    ((prev != null || !isEqual(prev, defaultValue)) && !isEqual(newValue, prev))
                ) {
                    valueChanged(hNode, valueToFilter(newValue === defaultValue ? undefined : newValue));
                }
                // If the reference is different, but value is same, return prev ref to avoid
                // rerender
                if (isEqual(newValue, prev)) {
                    return prev;
                }
                return newValue;
            });
        },
        [setValue, valueChanged, valueToFilter, defaultValue, hNode]
    );
    return { active, onBlur, onFocus, value, setValue: setValueProxy };
}

export default useSearchElement;
