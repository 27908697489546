//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _didUpdate_identity_tenantSetting from './didUpdate_identity_tenantSetting';
import _didUpsert_identity_tenantSetting from './didUpsert_identity_tenantSetting';
export const didUpdate_identity_tenantSetting = _didUpdate_identity_tenantSetting;
export const didUpsert_identity_tenantSetting = _didUpsert_identity_tenantSetting;
export default {didUpdate_identity_tenantSetting, didUpsert_identity_tenantSetting};

