import { EncryptPin, UpdateServerWithNewPin } from '../../../utilities/pinUtilities';

const _p = {
    EncryptPin,
    UpdateServerWithNewPin
};
export const _private = _p;

export default {
    verb: 'doingUpdate',
    namespace: 'security',
    relation: 'profile',
    featureFlag: 'OfflineLogins',
    description: 'update the security profile - really just used for changing the PIN',
    //this is the actual logic:
    logic: doingUpdate
};

async function doingUpdate({ data, context, dispatch }) {
    if (context.type != null) return;  // this is just for updating PIN, don't run for password reset, select tenant, etc.

    const { newRecord } = data;

    if (!newRecord) return;

    const { userId, pin = '' } = newRecord;

    if (pin === '') {
        throw new Error('There is no change to save, or the PIN and confirmation PIN do not match.');
    }

    if (userId ==  null) {
        throw new Error('userId is required but not provided');
    }

    let success;
    try {
        const { encryptedPin, salt } = await _p.EncryptPin(pin);
        ({ success } = await _p.UpdateServerWithNewPin(encryptedPin, salt, userId));
    } catch (e) {
        success = false;
    }

    if (success) {
        dispatch(
            { isError: false, message: 'Successfully updated PIN.' },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
    } else {
        dispatch(
            { isError: true, message: 'Failed to update PIN.' },
            { verb: 'pop', namespace: 'application', relation: 'notification' }
        );
    }

}
