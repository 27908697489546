import { createElement as rc, Fragment, memo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import allComponents from './components/index';
import logging from '@sstdev/lib_logging';
import { helpers } from '@sstdev/lib_metadata-config';
import lodash from 'lodash';
const { kebabCase, camelCase } = lodash;
import { testProperties, contexts } from 'lib_ui-primitives';
import createTreePositionGetter from './utilities/createTreePositionGetter';
import HNodeTreePosition from './HNodeTreePosition';
const INFORMATIONAL_HNODETYPEGROUPS = ['filter', 'listColumn'];
const JIT_CHILDREN_HNODETYPE = ['EditableList', 'SearchPane'];
function HNode(props) {
    const hintsContext = useContext(contexts.HintsContext);
    // Add a block specific hint to the hints context (noop if no hint on hNode).
    useEffect(() => {
        hintsContext?.addHint(hNode, currentRoute);
    });

    if (props == null) return null;
    if (!props.hNode) {
        logging.warn("An hNode without hNode definition was found. That can't be right...");
        return null;
    }
    const { hNode, readyToRender = false } = props;

    const currentRoute = getCurrentRoute(props);
    helpers.convertDeprecatedBlockTypes(hNode);
    const { hNodeType, hNodeTypeGroup } = hNode;
    // Do not attempt to render informational hNodeTypeGroups as components
    if (INFORMATIONAL_HNODETYPEGROUPS.includes(hNodeTypeGroup)) return null;

    const hNodeTypeComponent = allComponents?.[hNodeTypeGroup]?.[hNodeType];

    // Useful for seeing the rendering order/hierarchy.
    //logging.debug(`[HNODE] ${' '.repeat(3 * depth)}${hNodeTypeGroup}_${hNodeType}_${props.hNode.title}`);

    if (!hNodeTypeComponent) {
        logging.warn(
            `A piece of the UI is not set up correctly. This failure occurred while attempting to render ${hNodeType} in the ${hNodeTypeGroup} group of UI components.`
        );
        return null;
    }

    if (hNodeTypeComponent.deferredRendering && !readyToRender) return null;

    // only process _one_ access modifier at a time.
    // The modifier will recursively re-call this code with the rest of the props,
    // at which time the _next_ access modifier is processed, etc.
    // e.g. given an hNode:
    //  {
    //      hiddenFor: 'USER',
    //      showForViewport: 'Mobile'
    //  }
    //  and the logged in person is an _ADMIN_, viewing it on the _Web_
    // This will first call the hiddenFor access Modifier component.
    // the hiddenFor access Component will recursively call hNode with the remainder of the hNode:
    //  {
    //      showForViewport: 'Mobile'
    //  }
    // Then it would hit this code again, now matching the `showForViewport` access modifier
    // and it would generate the showForViewport component, which (as the condition is false),
    // would return `null`, and processing would end.
    // Note, at this time, (2025/01/10) most of the redaction, including role and feature flag based redaction
    // happens on the server, in silo_metui-server (src/repositories/metaui/useCaseHelpers/redaction.js)
    for (const _modifier in allComponents?.accessModifiers) {
        const modifier = camelCase(_modifier);
        if (hNode[modifier] || hNode[_modifier]) {
            // split off/remove the applicable property from the hNode
            const { [modifier]: value, [_modifier]: _value, ...restOfhNode } = hNode;
            // look up the component for the access modifier
            const modifierComponent = allComponents?.accessModifiers[_modifier];
            // call the accessModified component with the remainder of the hNode props
            // and do NOT already go ahead and generate a component for the hNode itself yet!
            // Yes, it behaves somewhat similar in that respect as the JIT_CHILDREN_HNODETYPEs
            return rc(modifierComponent, { ...props, hNode: { value: value || _value, children: [restOfhNode] } });
        }
    }

    const name = kebabCase(hNodeType);
    let modifiedProps = {
        id: hNode.id,
        name,
        ...props,
        currentRoute,
        key: hNode.id,
        ...testProperties(hNode)
    };
    // prettier-ignore
    return rc(Fragment, null,
        rc(hNodeTypeComponent, modifiedProps,
            createChildren(hNode, currentRoute)
        ),
        /* eslint-disable-next-line no-undef */
        __DEBUG_HNODE_TREE_POSITION__ && props?.hNode?.treePosition && rc(HNodeTreePosition, { treePosition: props.hNode.treePosition })
    );
}

HNode.propTypes = {
    hNode: PropTypes.object.isRequired,
    treePosition: PropTypes.object,
    readyToRender: PropTypes.bool
};

const noChildren = []; // use an outer scope constant to avoid rerenders
function createChildren(hNode, currentRoute) {
    if (JIT_CHILDREN_HNODETYPE.includes(hNode.hNodeType)) return noChildren;
    if (!hNode.children) return noChildren;

    const getTreePosition = createTreePositionGetter(hNode);

    return hNode.children.map((child, i) => {
        child.treePosition = getTreePosition(i);
        return rc(HNode, { hNode: child, key: child.hNodeType + i, currentRoute });
    });
}

function getCurrentRoute(props) {
    const {
        currentRoute,
        hNode: { title, hNodeTypeGroup, hNodeType }
    } = props;

    if (hNodeTypeGroup === 'navHeading' && hNodeType !== 'NavHeadingRecordList') {
        return normalizedNewRoute(currentRoute, title);
    }
    return currentRoute;
}

export default memo(HNode);

function normalizedNewRoute(currentRoute, newAddition) {
    if (currentRoute.endsWith('/')) {
        return currentRoute + newAddition;
    } else {
        return currentRoute + '/' + newAddition;
    }
}
