import { createElement as rc, forwardRef, useCallback, useState, useEffect, useRef, useContext } from 'react';
import logging from '@sstdev/lib_logging';
import styled from '../styled';
import fromTheme from '../fromTheme';
import lodash from 'lodash';
const { isEqual } = lodash;
import useEnsureRef from '../hooks/useEnsureRef';
import FocusContext from '../contexts/FocusContext';
import propTypes from 'prop-types';

const Input = styled.input.attrs(props => ({
    name: props.name || 'text',
    type: props.type || 'textbox'
}))`
    font-family: ${fromTheme('font')};
    font-size: ${fromTheme('fontSize')};
    padding: ${fromTheme('textPadding')};
    border-radius: 0;
    border: none;
    color: ${({ disabled, theme }) => (disabled ? theme.disabledFontColor : theme.defaultFontColor)};
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: ${fromTheme('borderColor')};
    background-color: transparent;
    flex-grow: 1;
    margin-bottom: 1px;
    &:focus {
        outline: none;
        border-bottom-width: 2px;
        border-bottom-color: ${fromTheme('colorScheme', 'primary')};
        margin-bottom: 0;
    }
`;

const TextArea = styled.textarea.attrs(props => ({
    name: props.name || 'textarea'
}))`
    font-family: ${fromTheme('font')};
    font-size: ${fromTheme('fontSize')};
    padding: ${fromTheme('textPadding')};
    border-radius: 0;
    border: none;
    color: ${({ disabled, theme }) => (disabled ? theme.disabledFontColor : theme.defaultFontColor)};
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: ${fromTheme('borderColor')};
    background-color: ${fromTheme('backgroundColor')} !important;
    width: ${({ theme }) => 'calc(100% - ' + theme.textPadding * 2 + ')px'};
    margin-bottom: 1px;
    &:focus {
        outline: none;
        border-bottom-width: 2px;
        border-bottom-color: ${fromTheme('colorScheme', 'primary')};
        margin-bottom: 0;
    }
`;

/**
 * downshift dropdowns require a ref
 * @typedef {Object} Props
 * @property {(value: string | number) => void} onChange
 */
/**
 * @type {import('react').ForwardRefRenderFunction<HTMLInputElement, Props>}
 */
const TextInput = forwardRef(function TextInput(props, passedRef) {
    const ref = useEnsureRef(passedRef);
    const {
        multiline = false,
        onBlur: _onBlur,
        onFocus: _onFocus,
        onChange: _onChange,
        autoFocus,
        value,
        id,
        enabled = true,
        name = '',
        sequence,
        type = 'text'
    } = props;

    if (sequence == null && props.type !== 'file') {
        throw new Error(
            `A sequence is required for TextInputs in order to manage focus.  The sequence is missing for id ${id} name ${name}.`
        );
    }

    const focusContext = useContext(FocusContext);
    const [focused, setFocused] = useState(false);
    const [focusProvider, setFocusProvider] = useState();

    // This updates the FocusProvider with the latest info about this
    // component.
    // It is separate from the registration to avoid the component
    // being deregistered every time a relevant prop changes
    // (e.g. isVisible).
    useEffect(() => {
        if (focusProvider == null) return;
        if (ref.current.focus == null) {
            logging.error('[TextInput] The component does not have a focus method');
        }

        // binding avoids 'illegal invocation' error
        let boundFocus = ref.current.focus;
        boundFocus = boundFocus?.bind(ref.current) ?? boundFocus;
        let focusWrapper = () => {
            logging.debug(`[TextInput] attempting to focus ${id} | ${name} | sequence ${sequence}`);
            const style = window.getComputedStyle(ref.current);
            if (style.visibility === 'visible') {
                boundFocus();
                if (document.activeElement !== ref.current) {
                    setTimeout(() => {
                        boundFocus();
                    }, 0);
                }
            }
            logging.debug(`[TextInput] name active element: ${document.activeElement?.name}`);
        };

        focusProvider.updateConsumer(focusWrapper, enabled);
    }, [focusProvider, enabled, id, name, sequence, ref]);

    useEffect(() => {
        if (focusContext == null) return;
        if (ref.current.focus == null) {
            logging.error('[TextInput] The component does not have a focus method');
        }
        // register this component with the focusProvider.
        const focusProvider = focusContext.registerConsumer(id, name, sequence);
        setFocusProvider(focusProvider);
        return focusProvider.deregister;
    }, [id, name, sequence, enabled, focusContext, ref]);

    useEffect(() => {
        if (focusContext == null) return;
        if (focused) {
            focusContext.setLastFocused(id);
        }
    }, [focusContext, id, focused]);

    // Keep track of the first value of autoFocus
    const firstAutoFocus = useRef(autoFocus);
    useEffect(() => {
        // If autoFocus changes to true, but wasn't true at first, then we'll need
        // to force the focus here.  This might happen if the autoFocused input changes
        // to this one because of some async operation (like maybe another field
        // had a default value that had to be looked up asynchronously and was
        // no longer the default because it wasn't empty after the lookup).
        if (autoFocus && !firstAutoFocus.current) {
            ref.current?.focus();
        }
    }, [autoFocus, ref]);

    // React will only maintain cursor position within the input if the changes are
    // synchronous.  Some of our default lookups, etc. are async, so this will keep a version
    // of the value that is local to the component and synchronous to avoid losing
    // the cursor position.
    const [localValue, setLocalValue] = useState(value);

    const onFocus = useCallback(
        e => {
            logging.debug(`[TextInput] onFocus called for ${id} | ${name}.`);
            setFocused(true);
            if (_onFocus != null) {
                _onFocus(e);
            } else {
                e.currentTarget.select();
            }
        },
        [_onFocus, id, name]
    );

    const onBlur = useCallback(
        e => {
            logging.debug(`[TextInput] onBlur called ${id} | ${name}.`);
            setFocused(false);
            _onBlur?.(e);
        },
        [_onBlur, id, name]
    );

    const onChange = useCallback(
        e => {
            setLocalValue(e.target.value);
            _onChange?.(e.target.value);
        },
        [_onChange]
    );

    useEffect(() => {
        // don't allow 'e' to be typed with numbers (by default type = 'number' allows 'e' to be typed for scientific notation)
        const onKeyDown = (evt) => {
            if (type === 'number' && evt.key === 'e') {
                evt.preventDefault();
            }
        };

        const element = ref.current;

        element.addEventListener('keydown', onKeyDown);
        return () => {
            element.removeEventListener('keydown', onKeyDown);
        };
    });

    useEffect(() => {
        setLocalValue(prev => {
            if (!isEqual(prev, value)) {
                return value;
            }
            return prev;
        });
        focusProvider?.updateConsumer(undefined, undefined, value);
    }, [value, focusProvider]);

    if (multiline) {
        return rc(TextArea, {
            ...props,
            id,
            enabled,
            value: localValue,
            onBlur,
            onFocus,
            spellCheck: 'false',
            ref,
            onChange
        });
    }

    return rc(Input, {
        ...props,
        id,
        enabled,
        value: localValue,
        onBlur,
        onFocus,
        spellCheck: 'false',
        ref,
        'aria-label': props.ariaLabel,
        onChange,
        type
    });
});
TextInput.propTypes = {
    sequence: propTypes.number,
    type: propTypes.string,
    ariaLabel: propTypes.string
};
export default TextInput;
