import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import { View, ErrorBoundary, styled, fromTheme } from 'lib_ui-primitives';
import SharedFilterBoundary from '../contextProviders/SharedFilterBoundary';

// padding on the right is different as there is a 4 px space that I'm not sure where it is coming from
// padding on the bottom is to make sure the shadow of the last row is shown
const Dashboard = styled(View)`
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    overflow-y: auto;
    gap: ${fromTheme('mainContainer', 'gap')};
    padding-left: ${({ theme }) => theme.mainContainer.gap}px;
    padding-right: ${({ theme }) => theme.mainContainer.gap - 4}px;
    padding-bottom: 2px;
`;

const Tile = styled(View).attrs({ name: 'Tile' })`
    width: ${({ numWidgets, theme }) => {
        // by fixing the width, we enforce how many widgets go on 1 line
        // by subtracting 2 * viewMargin, we assure there is "gap" space between the widgets
        // note: we do NOT need to subtract space for the padding, as that is taken care of by the %
        // mobile gets 1 widget per line, we only need
        const numColumns = theme.mobile ? 1 : numWidgets % 3 === 0 ? 3 : 2;
        //for mobile (1 row) 100% width
        //for divisible by 3 (3 rows), 33% width - (2 gaps/3)
        // otherwise 2 columns: 50% width - (1 gap/2)
        return `Calc(${Math.floor(100 / numColumns)}% - ${
            ((numColumns - 1) * theme.mainContainer.gap) / numColumns
        }px)`;
    }};

    height: ${({ numWidgets, theme }) => {
        if (theme.mobile) {
            return 'auto';
        }
        const numColumns = numWidgets % 3 === 0 ? 3 : 2;
        const numRows = Math.ceil(numWidgets / numColumns);
        // it's all layed out in 2 or 3 columns, and we always show everything
        // so, the full height, minus the header, and the top and bottom margin.
        // we are not using %, because.....? (no idea...?)
        // then between each row there is a gap that we need to remove
        // and that is the height we get to divide over all the rows
        return `${
            (theme.height -
                theme.outerMenus.headerHeight -
                2 * theme.mainContainer.margin -
                2 - // this is for the shadow of the last row
                (numRows - 1) * theme.mainContainer.gap) /
            numRows
        }px`;
    }};

    overflow: visible;
`;

function DashboardLayout(props) {
    const { id, title: name = 'dashboard', children, ...otherProps } = props || {};

    // prettier-ignore
    return rc(Dashboard, { name, id, ...otherProps },
        children.map((child, i) =>
            rc(Tile, { key: `widget${i}`, numWidgets: children.length },
                rc(SharedFilterBoundary, {boundaryName: child.props?.hNode?.title}, null,
                    rc(ErrorBoundary, null,
                        child
                    )
                )
            )
        )
    );
}

DashboardLayout.propTypes = {
    children: PropTypes.array,
    hNode: PropTypes.object.isRequired
};

export default DashboardLayout;
