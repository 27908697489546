//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _backboneUser from './backboneUser';
import _role from './role';
import _tenantSetting from './tenantSetting';
import _user from './user';
export const backboneUser = _backboneUser;
export const role = _role;
export const tenantSetting = _tenantSetting;
export const user = _user;
export default {backboneUser, role, tenantSetting, user};

