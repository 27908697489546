import { createElement as rc, useContext } from 'react';
import { h2, Card, styled, fromTheme, View, Text, Button, contexts } from 'lib_ui-primitives';
import { constants, session } from 'lib_ui-services';
import { format } from 'date-fns';
import { CardBox } from './styles';
import Logo from '../Logo';
import useEventSink from '../../hooks/useEventSink';
import useBrand from '../../hooks/useBrand';

const Centered = styled(View).attrs({ name: 'centered' })`
    min-height: ${fromTheme('height')};
    width: 100%;
    height: 100%;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Main = styled(View).attrs({ name: 'main' })`
    flex-direction: column;
    justify-content: center;
    flex-grow: 0;
    max-width: 370px;
`;

const HeaderText = styled(h2)`
    color: ${fromTheme('card', 'header', 'textColor')};
    margin: ${({ theme }) => (theme.mobile ? '0px' : theme.viewMarginMore + 'px')};
    padding: ${fromTheme('viewPadding')};
    text-align: center;
`;

const ExplanationText = styled(Text).attrs({ name: 'explanation-text' })`
    margin: ${fromTheme('textMargin')};
    text-align: center;
    margin-bottom: 24px;
    color: ${fromTheme('errorColor')};
`;

const DetailText = styled(Text).attrs({ name: 'detail-text' })`
    margin: ${fromTheme('textMargin')};
    text-align: left;
    color: ${fromTheme('textColor')};
`;

const ButtonContainer = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    margin-top: ${fromTheme('viewMargin')};
`;

const StickyFooter = styled(View).attrs({ name: 'stickyFooter' })`
    justify-content: center;
    padding: 12px 0px;
    flex-grow: 0;
    flex-basis: auto;
    flex-shrink: 0;
`;

const _p = { logout: session.logout };
export const _private = _p;

export default function ContractExpired() {
    const [, publish] = useEventSink();
    const brand = useBrand();

    // Collect tenant and profile data from the session context to display in the contract expired page
    // and to send to the support page if the user clicks the "Contact Us" button
    const { tenant, profile } = useContext(contexts.SessionContext) ?? {};
    const { firstName, lastName, userName } = profile ?? {};

    const tenantName = tenant?.['identity:tenant']?.title;
    const contractId = tenant?.['identity:tenant']?._id;
    const contractEndDate = tenant?.contractEndDate
        ? format(new Date(tenant.contractEndDate), 'MMMM d, yyyy')
        : 'Unknown';

    // Send the user to the support page if they click the "Contact Us" button and clear the session data
    const handleContactUs = () => {
        const baseUrl = constants.SUPPORT_URL[brand].contactUrl;
        const url = new URL(baseUrl);

        // Add query parameters if they exist
        if (userName) url.searchParams.append('email', userName);
        if (firstName) url.searchParams.append('firstname', firstName);
        if (lastName) url.searchParams.append('lastname', lastName);
        if (tenantName) url.searchParams.append('company', tenantName);
        url.searchParams.append(
            'message',
            `My contract ID is ${contractId} and my contract expired on ${contractEndDate}.`
        );

        _p.logout(true, true, url.toString());
    };

    // Clear the session data and send the user back to the login page if they click the "Go Back" button
    const handleGoBack = () => {
        publish({}, { verb: 'remove', namespace: 'security', relation: 'profile' });
        _p.logout();
    };

    // prettier-ignore
    return rc(Centered, null,
        rc(Main, null,
            rc(CardBox, null,
                rc(Card.Header, { style: { justifyContent: 'center' } },
                rc(HeaderText, { name: 'card-header-title', style: { margin: 0 } }, 'Contract Expired')),
                rc(Card.Body, null,
                    rc(ExplanationText, null, 'Your contract has expired. Please contact your administrator to renew your subscription.'),
                    tenantName && rc(DetailText, null, `Tenant: ${tenantName}`),
                    contractId && rc(DetailText, null, `Contract ID: ${contractId}`),
                    contractEndDate && rc(DetailText, null, `Expiration Date: ${contractEndDate}`),
                    rc(ButtonContainer, null,
                        rc(Button, {
                            value: 'Contact Us',
                            buttonStyle: 'primary',
                            onClick: handleContactUs
                        }),
                        rc(Button, {
                            value: 'Go Back',
                            buttonStyle: 'secondary',
                            onClick: handleGoBack
                        })
                    )
                )
            )
        ),
        rc(StickyFooter, null, rc(Logo))
    );
}
