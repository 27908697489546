export default {
    getFilter,
    getUriComponent,
    fromHNode,
    getMql
};

function fromHNode() {
    return getFilter();
}

function getFilter(searchCriteria) {
    return {
        searchCriteria
    };
}

function getUriComponent(filters) {
    const filter = filters['searchCriteria'];
    if (!filter || Object.keys(filter).length === 0) return;

    // TODO: This method has to be refactored with better documentation and clear cut handling.
    // SearchCriteria is becoming more central to filtering data, but right now it's not clear
    // which part of the code is trying to address which scenario
    const criteria = parseFilter(filter);

    return { criteria };
}

function getMql(filters) {
    const { searchCriteria } = filters;
    if (searchCriteria) {
        const $and = Object.entries(searchCriteria).map(([key, value]) => ({ [key]: value }));
        return { $and };
    }
}
function parseFilter(filter) {
    let searchCriteria = {};
    Object.keys(filter).forEach(scKey => {
        if (['$or', '$and'].includes(scKey)) {
            searchCriteria[scKey] = [];
            filter[scKey].forEach(subFilter => {
                const subFilterParsed = parseFilter(subFilter);
                searchCriteria[scKey].push(subFilterParsed);
            });
        } else {
            searchCriteria = parseOutSingleFilter(searchCriteria, filter, scKey);
        }
    });
    return searchCriteria;
}
function parseOutSingleFilter(searchCriteria, filter, scKey) {
    const scOperator = Object.keys(filter[scKey])[0];
    if (scOperator === '$regex') {
        // server (mongodb) syntax for $regex is slightly different from lokijs.
        let scValue = filter[scKey][scOperator];
        if (!Array.isArray(scValue)) {
            throw new Error('Only Arrays can be parsed for $regex operators.');
        }
        searchCriteria[scKey] = { [scOperator]: scValue[0], $options: scValue[1] };
    } else if (scOperator === '$between') {
        // Server doesn't handle between - switch to two filters instead
        let scValue = filter[scKey][scOperator];
        if (!Array.isArray(scValue)) {
            throw new Error('Only Arrays can be parsed for $between operators.');
        }
        const isDate = !isNaN(Date.parse(scValue[0]));
        if (isDate) {
            searchCriteria[scKey] = {
                $gte: { $date: scValue[0] },
                $lte: { $date: scValue[1] }
            };
        } else {
            searchCriteria[scKey] = {
                $gte: scValue[0],
                $lte: scValue[1]
            };
        }
    } else if (!['$gte', '$gt', '$lte', '$lt'].includes(scOperator)) {
        let scValue = filter[scKey][scOperator];
        // Whenever possible use _id instead of title as filter criteria
        const nestedId = typeof scValue === 'object' && '_id' in scValue && !scKey.endsWith('_id');
        // The value to be compared is either _id or 'title' of the object
        let matchByTitle = false;
        const matchValue = Array.isArray(scValue) ? scValue[0] : scValue;
        matchByTitle = !nestedId && typeof matchValue === 'object' && !scKey.endsWith('_id');

        if (matchByTitle) {
            let scValueToMatch = Array.isArray(scValue) ? [] : scValue;
            if (Array.isArray(scValue)) {
                scValue.forEach(v => scValueToMatch.push(matchByTitle ? v['title'] : v));
            }
            searchCriteria[`${scKey}.title`] = { [scOperator]: scValueToMatch };
        } else {
            if (nestedId) {
                searchCriteria[scKey + '._id'] = { $oid: scValue['_id'] };
            } else {
                searchCriteria[scKey] = filter[scKey];
            }
        }
    } else {
        //we have some sort of range.
        //If we need more support than just for Date, we probably should pull in bson.
        searchCriteria[scKey] = {};
        Object.keys(filter[scKey]).forEach(op => {
            let value;
            if (isNaN(Date.parse(filter[scKey][op]))) {
                // This is not a date
                value = filter[scKey][op];
            } else {
                // This is a date
                value = { $date: filter[scKey][op] };
            }
            searchCriteria[scKey][op] = value;
        });
    }
    return searchCriteria;
}
