import { createContext, useState, useEffect, createElement as rc } from 'react';
import PropTypes from 'prop-types';
import useEventSink from '../../hooks/useEventSink';
import logging from '@sstdev/lib_logging';
import lodash from 'lodash';
const { isEqual } = lodash;
import { pageLoadStorage, contextMatching } from 'lib_ui-services';
export const ActiveRecordContext = createContext();

function ActiveRecord(props) {
    const { namespace, relation, type, children, isNew, record, activationVerb, currentRoute } = props || {};
    if (namespace == null || relation == null) {
        logging.error('[ACTIVE_RECORD] Namespace and relation are required properties for ActiveRecord');
    }
    const [activeRecord, setActiveRecord] = useState({ namespace, relation, type, isNew, record, activationVerb });
    const [subscribe, , request] = useEventSink();

    useEffect(() => {
        if (props.type && activeRecord.type !== props.type) {
            const updatedActiveRecord = { ...activeRecord, type: props.type };

            if (updatedActiveRecord?.record == null) {
                logging.debug('[ACTIVE_RECORD] removed.');
            } else {
                logging.debug(
                    `[ACTIVE_RECORD] id: ${updatedActiveRecord.record._id}, titular: ${
                        updatedActiveRecord.record.title ?? updatedActiveRecord.record.assetNo
                    }.`
                );
            }
            setActiveRecord(updatedActiveRecord);
        }
    }, [activeRecord, props.type]);

    // If the rules engine publishes a success for one of these verbs, then the active record
    // will be changing.
    useEffect(() => {
        let allowStateChange = true;
        const unsubscribes = ['copy', 'edit', 'view', 'new', 'cancel', 'submit', 'remove'].map(verb =>
            subscribe({ verb, namespace, relation, type, status: 'success' }, async (payload, context) => {
                /**
                 * @typedef payload
                 * @property {string} namespace "should" match what was configured in props
                 * @property {string} relation "should" match what was configured in props
                 * @property {string} type "should" match what was configured in props, though props _can_ change that
                 * @property {string} isNew "should" match what was configured in props?
                 * @property {object} [record] THIS is the actual new active record
                 * @property {string} [activationVerb]
                 */

                //basic validation, if this get's messed up, it becomes really confusing
                if (
                    (payload.namespace && payload.namespace !== namespace) ||
                    (payload.relation && payload.relation !== relation)
                ) {
                    logging.warn(
                        `[ACTIVE_RECORD] Invalid payload. Expected ${namespace}:${relation}, got ${payload.namespace}:${payload.relation}  `
                    );
                }
                // Confirm active record change.  If nothing cares (no subscribers) this request will just
                // resolve without an error or timeout.  As of 2022-03-14 this is only consumed by useFormMachine
                try {
                    // Cancellation of a redirect reboundEvent cannot be subject to confirmation
                    const reboundEvents = pageLoadStorage.getKey(`reboundEvents|${currentRoute}`) ?? [];
                    const isReboundEvent = reboundEvents.some(re => contextMatching(re, context));
                    //Single Record forms are re-initialized right away.
                    //Therefore, a popup with a single record form in it cannot be "cancel"ed (closed) without the warning of pending changes.
                    //By passing setting the type for the cancel to "ignore-changes", this will allow us to avoid the confirmation
                    //See, e.g. didCreate_print_job
                    if (payload.type !== 'ignore-changes' && !isReboundEvent) {
                        // This will fail (promise reject) to the catch statement below if anything does not confirm the change.
                        // Otherwise, it will continue and set the new activeRecord.
                        await request(
                            { activeRecord, newRecord: payload, changeContext: context },
                            { verb: 'confirm', namespace, relation, type: 'changeRecord' },
                            undefined,
                            false // do not error if there are no subscribers
                        );
                    }
                    if (allowStateChange) {
                        //setActiveRecord(record);
                        // Avoid rerenders, etc if user clicks on the same record again.
                        setActiveRecord(prev => {
                            if (!isEqual(prev, payload)) {
                                if (payload?.record == null) {
                                    logging.debug('[ACTIVE_RECORD] removed.');
                                } else {
                                    logging.debug(
                                        `[ACTIVE_RECORD] id: ${payload.record._id}, titular: ${
                                            payload.record.title ?? payload.record.assetNo
                                        }.`
                                    );
                                }

                                return { ...payload, namespace: prev.namespace, relation: prev.relation };
                            }
                            return prev;
                        });
                    }
                } catch (err) {
                    logging.info('Active record change canceled');
                    logging.error(err);
                }
            })
        );
        return () => {
            allowStateChange = false;
            unsubscribes.forEach(u => u());
        };
    }, [namespace, relation, type, subscribe, request, activeRecord, currentRoute]);

    //render the provider.
    return rc(ActiveRecordContext.Provider, { value: activeRecord }, children);
}

ActiveRecord.propTypes = {
    namespace: PropTypes.string.isRequired,
    relation: PropTypes.string.isRequired,
    type: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    isNew: PropTypes.bool
};
export default ActiveRecord;
