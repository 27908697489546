import { metadata, ObjectId, loadTransaction, asyncUtilities, constants, filters } from 'lib_ui-services';
import getLocationInfo from './getLocationInfo';
import lodash from 'lodash';
const { pick } = lodash;
import getAllFeatureFlags from '../../../../utilities/getAllFeatureFlags';
import memoizedAccessChecker from '../../../../utilities/accessChecker';
import { cancelSensorReadAndItem } from '../../../../utilities/dispatchHelpers';

const _p = {
    addNewItem,
    editItem,
    setImmediate: func => setTimeout(func, 0),
    asyncConfirmationModal,
    confirmFoundItem,
    getLocationInfo,
    getMinimumForeignKeyFieldsFromDictionary: metadata.getMinimumForeignKeyFieldsFromDictionary,
    memoizedAccessChecker,
    cancelSensorReadAndItem
};

export const _private = _p;

export default {
    verb: 'doingMark',
    namespace: 'item',
    relation: 'item',
    description: 'Mark event to mark the given record as found',
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'item',
                type: 'find'
            },
            query: ({ data }) => {
                const { markText, caseSensitive = false, exactMatchOnly = true } = data;
                //records in the grid use fullText filter. Do the same here
                const filter = filters.fullTextSearch.getMql({
                    fullTextSearch: { searchTerm: markText },
                    propertiesToSearch: { propertiesToSearch: ['assetNo', 'serialNo'] },
                    caseSensitive,
                    exactMatchOnly,
                    preferLocal: true
                });
                return {
                    ...filter,
                    'meta.deleted': { $exists: false }
                };
            }
        },
        {
            context: {
                verb: 'get',
                namespace: 'location',
                relation: 'location',
                type: 'get'
            },
            query: ({ data }) => {
                return { _id: data.locationId };
            }
        },
        {
            context: { verb: 'get', namespace: 'inventory', relation: 'inventory', type: 'find' },
            query: () => ({
                criteria: { 'inventory:status.title': 'Active' }
            })
        },
        {
            context: { verb: 'get', namespace: 'identity', relation: 'userRole' }
        }
    ],
    //this is the actual logic:
    logic: markAsFound
};

async function markAsFound({ data, context, prerequisiteResults, dispatch }) {
    // doingMark_item_item_all has its own rule
    if (context.type === 'all') return;
    if (getAllFeatureFlags(context).includes('multiInventory')) return;

    const itemRecords = prerequisiteResults?.[0]?.result ?? [];
    const locationRecords = prerequisiteResults?.[1]?.result ?? [];
    const activeInventory = prerequisiteResults?.[2]?.result ?? [];
    const userRole = prerequisiteResults?.[3]?.result?.[0];
    const clientDataRights = getAllFeatureFlags(context).includes('clientDataRights') ?? false;

    // Just in case we ended up here without active inventory
    if (!activeInventory || activeInventory.length === 0) {
        dispatch(
            {
                message: 'No active inventory found!  You must have an active inventory to mark items as found.',
                isError: true
            },
            {
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
        return;
    }
    if (locationRecords.length > 1) {
        dispatch(
            { message: `Multiple locations found for _id ${data.locationId}!`, isError: true },
            {
                ...context,
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
        return;
    }
    let itemRecord;
    // Order of priority
    // 1. Exact match AssetId - Case Sensitive
    // 2. Exact match Serial No - Case Sensitive
    // 3. Exact Match AssetId - Case Insensitive
    // 4. Exact match Serial No - Case Insensitive
    // For FLAIR, markText < 8 characters are left-padded with 0 in another rule,
    // and might never match a record with a shorter serial, which is acceptable.
    // We do NOT do partial matching.
    // Whichever of these matches result in a single record, that is taken, independently of the (in)active status of that match

    const exactCSMatchFilter = new RegExp('^' + data.markText + '$');
    //prefer a match on assetNo over on on SerialNo
    let exactCSMatch = itemRecords.filter(item => item.assetNo?.match(exactCSMatchFilter));
    if (!exactCSMatch.length) {
        exactCSMatch = itemRecords.filter(item => item.serialNo?.match(exactCSMatchFilter));
    }
    if (exactCSMatch.length === 1) {
        itemRecord = exactCSMatch[0];
    } else {
        //if we still don't have an exact match, try case insensitive, but still look for exact match
        const exactMatchFilter = new RegExp('^' + data.markText + '$', 'i');
        //prefer an match on assetNo over on on SerialNo
        let exactMatch = itemRecords.filter(item => item.assetNo?.match(exactMatchFilter));
        if (!exactMatch.length) {
            itemRecords.filter(item => item.serialNo?.match(exactMatchFilter));
        }
        if (exactMatch.length === 1) {
            itemRecord = exactMatch[0];
        }
    }

    //if we haven't been able to narrow it down to 1 by now, then multiple records matched
    if (itemRecords.length > 1 && !itemRecord) {
        const message = `Multiple assets found for ${data.markText}!`;
        dispatch(
            { message, isError: true },
            {
                ...context,
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
        throw new Error(message);
    }

    // We only need room and building here for the modal question below
    // don't waste time on trying to get the company.
    // IF NEEDED, that happens though the _p.getLocationInfo() call.
    const location = await _p.getMinimumForeignKeyFieldsFromDictionary(
        locationRecords[0],
        'item',
        'item',
        'location',
        'location'
    );
    location['location:building'] = locationRecords[0]['location:building'];
    // No asset found, ask user if a new one should be created.
    if (!itemRecord) {
        // Display modal to confirm that user wants to create a new item
        const modalInfo = {
            message: `No asset with an ID or Serial Number of '${data.markText}' was found. Would you like to create one now?`
        };
        if (await _p.asyncConfirmationModal(modalInfo, dispatch)) {
            _p.addNewItem(data, location, activeInventory, dispatch, context);
        } else {
            _p.cancelSensorReadAndItem(dispatch, data);
        }
    } else {
        const dataAccess = await _p.memoizedAccessChecker('item', 'item', userRole, clientDataRights);
        if (!dataAccess.hasAccess(itemRecord)) {
            _p.cancelSensorReadAndItem(dispatch, data);
            return;
        }
        if (itemRecord.active === false) {
            throw new Error('Unable to update inactive record.');
        }
        // Most common case where there is one item that matches the request
        await _p.confirmFoundItem(data, itemRecord, location, activeInventory, dispatch, context);
    }
}

// Popup edit screen for user to update item information before marking it as found
async function confirmFoundItem(payload, item, newLocation, activeInventory, dispatch, context) {
    // If there is location change, get confirmation before making the change
    const oldLocationInfo = pick(item, ['location:company', 'location:building', 'location:location']);

    // Handle no change in location
    if (
        oldLocationInfo['location:location'] &&
        newLocation &&
        oldLocationInfo['location:location']._id === newLocation._id
    ) {
        return _p.editItem(payload, item, oldLocationInfo, activeInventory, dispatch, context);
    }

    let locationChangeMsg = '';

    if (oldLocationInfo['location:location']) {
        if (oldLocationInfo['location:building']) {
            locationChangeMsg = `Previous location was in room '${oldLocationInfo['location:location'].title}' of building '${oldLocationInfo['location:building'].title}'. Which location would you like to use?`;
        } else {
            locationChangeMsg = `Previous location was in '${oldLocationInfo['location:location'].title}'. Which location would you like to use?`;
        }
    } else {
        locationChangeMsg = 'No previous location. Which location would you like to use?';
    }

    // If location changed, display modal to confirm
    const modalInfo = {
        message: locationChangeMsg,
        okButtonText: 'CURRENT',
        cancelButtonText: 'PREVIOUS'
    };

    const newLocationSelected = await _p.asyncConfirmationModal(modalInfo, dispatch);
    if (newLocationSelected) {
        const newLocationInfo = await _p.getLocationInfo(newLocation, dispatch);

        return _p.editItem(payload, item, newLocationInfo, activeInventory, dispatch, context);
    } else {
        return _p.editItem(payload, item, oldLocationInfo, activeInventory, dispatch, context);
    }
}

async function editItem(payload, oldRecord, locationInfo, activeInventory, dispatch, context) {
    const { foundBy, sensorType } = payload;

    let newRecord = {
        ...oldRecord,
        ...locationInfo,
        inventory: [
            {
                'inventory:inventory': { _id: activeInventory[0]._id, title: activeInventory[0].title },
                ...oldRecord?.inventory?.[0],
                found: true,
                foundDate: new Date().toISOString(),
                foundBy,
                foundByScan: [
                    constants.sensorTypes.BARCODE,
                    constants.sensorTypes.RFID,
                    constants.sensorTypes.BLE
                ].includes(sensorType)
            }
        ]
    };

    await loadTransaction.create(
        async () => {
            // Allow user to edit the item before saving
            await dispatch(
                {
                    _id: oldRecord._id,
                    activeRecord: newRecord,
                    oldRecord
                },
                { ...context, verb: 'edit' },
                true
            );
        },
        context,
        dispatch
    );
}

async function asyncConfirmationModal(modalInfo, dispatch) {
    return new Promise(resolve => {
        dispatch(
            {
                message: 'Are you sure you want to make this change?',
                okButtonText: 'OK',
                okAction: () => resolve(true),
                cancelAction: () => resolve(false),
                ...modalInfo
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    });
}

async function addNewItem(payload, location, activeInventory, dispatch, context) {
    const { foundBy, markText, sensorType } = payload;
    // Enable creating new item with this markText

    const locationInfo = await _p.getLocationInfo(location, dispatch);
    let newRecord = {
        _id: new ObjectId().toString(),
        assetNo: markText,
        ...locationInfo,
        inventory: [
            {
                'inventory:inventory': { _id: activeInventory[0]._id, title: activeInventory[0].title },
                isNew: true,
                found: false
            }
        ]
    };

    await loadTransaction.create(
        async () => {
            // Create active record for new relation
            await dispatch(
                {
                    newRecord,
                    createdBySearch: true
                },
                { ...context, verb: 'new' },
                {
                    awaitResult: true,
                    waitForSubscriber: true
                }
            );

            // Dispatch this change separately so that record will be dirty
            //while maintaining logical event flows for easier rules engine interceptions
            await asyncUtilities.PromiseImmediate(() => {
                dispatch(
                    {
                        propertyName: 'inventory[0]',
                        newValue: {
                            'inventory:inventory': { _id: activeInventory[0]._id, title: activeInventory[0].title },
                            isNew: true,
                            found: true,
                            foundDate: new Date().toISOString(),
                            foundBy,
                            foundByScan: [
                                constants.sensorTypes.BARCODE,
                                constants.sensorTypes.RFID,
                                constants.sensorTypes.BLE
                            ].includes(sensorType)
                        }
                    },
                    { ...context, verb: 'beginChange' },
                    { waitForSubscriber: true }
                );
            });
        },
        context,
        dispatch
    );
}
