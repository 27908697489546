/**
 * @module retention
 */

/**
 * @typedef {import ('lib_ui-primitives/src/BbState/BbState').SCOPE} BBState_SCOPE
 * @typedef {import ('lib_ui-primitives/src/BbState/BbState').VISIBILITY} BBState_VISIBILITY
 * @typedef {import ('lib_ui-primitives/src/BbState/BbState').DURATION} BBState_DURATION
 *
 */
/**
 * SCOPE influences the KEY under which the state is stored. It directly influences how many
 * variations of the same state can exist next to each other
 */
export const SCOPE = Object.freeze({
    /**
     * Data can only be accessed by when passing in the same id in conjunction with the same stateName
     * This allows for many different "copies" of the same state to be stored in the same storage
     * requires retention.id to be set
     * @type {BBState_SCOPE.LOCAL}
     */
    LOCAL: 'local',
    /**
     * Data is stored by statename + routeKey.
     * Each "page" will have its own copy of the state
     * @type {BBState_SCOPE.SESSION}
     */
    PER_ROUTE: 'perRoute',
    /**
     * Data is stored by statename only.
     * There is only a single value of the state for the entire application
     * (data IS separated by tenant)
     * @type {BBState_SCOPE.GLOBAL}
     */
    GLOBAL: 'global'
});

/**
 * VISIBILITY defines FROM WHERE the state can be accessed as well, or in other words,
 * where else the state is stored (only inside the browser,
 * centrally for the user, centrally for the tenant)
 */
export const VISIBILITY = Object.freeze({
    /**
     * For Visibility:  perBrowser, the data will not leave the browser
     * but is shared across all users of the current tenant on the same browser.
     * @type {BBState_VISIBILITY.PER_BROWSER}
     */
    PER_BROWSER: 'perBrowser',
    /**
     * For Visibility:  perUser, data will be stored centrally on a server
     * and is shared across all browsers for the same user.
     * Any change will result in .... TBD .... being transmitted to the server
     * On the server, data is stored on .... TBD ....
     * @type {BBState_VISIBILITY.PER_USER}
     * @deprecated Not yet available
     */
    PER_USER: 'perUser',
    /**
     * For Visibility:  perTenant, data will be stored centrally on a server
     * and is shared across all browsers for all users of the same tenant.
     * Any change will result in an identity:tenantSettings update being transmitted to the server
     * On the server, data is stored on identity:tenant inside the .settings property
     * @type {BBState_VISIBILITY.PER_TENANT}
     */
    PER_TENANT: 'perTenant'
    // PER_ROLE?
});

/**
 * DURATION defines HOW the state is stored  (React State, In-memory, local storage)
 */
export const DURATION = Object.freeze({
    /**
     * Data is stored in React State and is only available for the duration of the request
     * (Not available outside of react components)
     * @type {BBState.DURATION.REQUEST}
     */
    REQUEST: 'request',
    /**
     * Store for the duration of this page session (removed at reload)
     * @type {BBState.DURATION.PAGE_LOAD}
     * */
    PAGE_LOAD: 'pageLoad',
    /**
     * Store for the duration of this user session (removed at logout)
     * @type {BBState.DURATION.USER_SESSION}
     * */
    USER_SESSION: 'userSession',
    /**
     * Data is stored in the browser's local storage, and is persisted across browser sessions
     * (data IS separated by tenant)
     * @type {BBState.DURATION.ALWAYS}
     * */
    ALWAYS: 'always'
});

export default { SCOPE, VISIBILITY, DURATION };
