import { createElement as rc } from 'react';
import { Link } from 'react-router-dom';

import styled from '../../styled';
import fromTheme from '../../fromTheme';

const LinkStyled = styled(Link)`
    text-decoration-color: ${fromTheme('defaultFontColor')};
`;

/** @type {react.FC<{}>} */
export default function RouterLink(props) {
    if (Array.isArray(props.children)) {
        throw new Error(
            `RouterLink must have exactly one child, or it is incompatible with React Native. Link to ${
                props.to
            } contains children with the following props passed in: ${JSON.stringify(props.children.map(c => c.props))}`
        );
    }
    return rc(LinkStyled, props);
}
