import { createElement as rc, useMemo } from 'react';
import { styled, View } from 'lib_ui-primitives';
import useDoubleClick from '../../hooks/useDoubleClick';
// TODO: Switch to non feature flag import.  I need this one (for now) for unit tests.
// eslint-disable-next-line import/extensions
import TenantItem from './TenantItem.SwitchToAuth0.js';

const TenantWrap = styled(View).attrs({ name: 'tenant-wrap' })`
    flex-direction: column;
    overflow-y: auto;
`;
TenantWrap.displayName = 'TenantWrap';

const AllTenants = props => {
    const { onSubmit, selectedUseCase, setSelectedUseCase, selectedTenant, setSelectedTenant, tenants } = props ?? {};

    const handleClick = useDoubleClick(
        (event, useCase, tenant) => {
            event.persist();
            onSubmit(event, useCase, tenant);
        },
        [onSubmit],
        (event, useCase, tenant) => {
            // Tenant must go first with React Native.  I think it isn't as good
            // as web at batching state changes.
            setSelectedTenant(tenant);
            setSelectedUseCase(useCase);
        },
        [setSelectedUseCase, setSelectedTenant],
        { timeout: 200 }
    );

    const sortedTenants = useMemo(() => tenants.sort(ascendingByTitle), [tenants]);

    // prettier-ignore
    return rc(TenantWrap, null,
        sortedTenants.map(tenant => rc(TenantItem, {
                key: tenant['identity:tenant']._id,
                onClick: handleClick,
                selectedUseCase,
                selectedTenant,
                startCollapsed: sortedTenants.length > 3,
                tenant
            })
        )
    );
};
export default AllTenants;
const ascendingByTitle = (a, b) => {
    const valueA = a?.['identity:tenant']?.title || '';
    const valueB = b?.['identity:tenant']?.title || '';
    if (valueA > valueB) {
        return 1;
    }
    if (valueA < valueB) {
        return -1;
    }
    return 0;
};
