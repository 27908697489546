function doChildrenRequireSidebar(hNode) {

    const navHeadingChildren = hNode?.children?.filter(child => child.hNodeTypeGroup === 'navHeading');

    if (navHeadingChildren?.length > 1) {    // there's more than one heading at this level, we need a menu
        return true;
    } else if (!navHeadingChildren || navHeadingChildren.length === 0) {   // no more navHeading children, but are any children a NavigationSelectionContext?
        return hNode?.children?.some(child => child.hNodeType === 'NavigationSelectionContext') || false;
    } else {   // only one page at this level, but are there multiple children?
        return doChildrenRequireSidebar(navHeadingChildren[0]);
    }
}

export default doChildrenRequireSidebar;