import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { Fragment, createElement as rc, useContext, useMemo, useCallback, useState, useEffect, useRef } from 'react';
import ActiveRecord from '../../contextProviders/ActiveRecord';
import useActiveRecord from '../../../hooks/useActiveRecord';
import { View, styled, contexts, testProperties } from 'lib_ui-primitives';
import StyledSummaryDetail from './StyledSummaryDetail';
import FilterInterdependencyBoundary from '../../contextProviders/FilterInterdependencyBoundary';
import LoadingBoundary from '../../contextProviders/LoadingBoundary';
import { useLayoutEffect } from 'react';
import lodash from 'lodash';
const { isEqual } = lodash;
import logging from '@sstdev/lib_logging';

const SplitPanel = styled(View)`
    display: ${({ panelDisplay }) => (panelDisplay ? 'flex' : 'none')};
`;
const MobileSplitPanel = styled(View).attrs({ name: 'mobile-split-panel' })`
    ${({ panelDisplay }) => (panelDisplay ? 'display: flex; flex-grow: 1; margin-right: 1px' : 'display: none;')};
`;
MobileSplitPanel.displayName = 'MobileSplitPanel';

export default function SummaryDetailLayout(props) {
    if (props == null) return null;
    // `record` is mainly included for test purposes here. It will usually
    // be supplied to the ActiveRecord component by an event from the rules
    // engine or similar.
    const { children, hNode, record, activationVerb, currentRoute } = props;
    // TODO: To avoid confusion, change this to Namespace/Relation in the block
    // (instead of defaultNamespace/defaultRelation).
    const { defaultNamespace, defaultRelation } = hNode;
    // prettier-ignore
    return rc(LoadingBoundary, null,
        rc(ActiveRecord, { namespace: defaultNamespace, relation: defaultRelation, record, activationVerb, currentRoute },
            rc(FilterInterdependencyBoundary, { name: 'summary-detail-layout' },
                rc(InnerSummaryDetailLayout, { hNode }, children)
            )
        )
    );
}

const DEFAULT_COLLAPSED_SPLIT = [100, 0];
export function InnerSummaryDetailLayout(props) {
    const {
        children,
        hNode,
        hNode: { defaultDetailWidth = 30 }
    } = props || {};
    const activeRecord = useActiveRecord();
    const { activationVerb, record } = activeRecord;
    const theme = useContext(ThemeContext);
    let DEFAULT_SPLIT_SIZES = useMemo(() => {
        return [100 - defaultDetailWidth, defaultDetailWidth];
    }, [defaultDetailWidth]);
    // The split sizes are tracked here so that they can be added to the SplitResizeContext
    const [splitSizes, _setSplitSizes] = useState(DEFAULT_COLLAPSED_SPLIT);
    const [collapsed, setCollapsed] = useState(1);

    const ref = useRef();

    // This ensures that the detail pane is at least as wide as the form entry max width
    // defined in the theme.
    const setSplitSizes = useCallback(
        newValue => {
            const splitter = ref.current;
            if (theme.mobile || splitter == null || collapsed) return;
            evaluateAndSetSplitSizes(splitter, _setSplitSizes, theme, newValue);
        },
        [theme, collapsed]
    );

    // This ensures the detail pane is large enough with the initial render (in case
    // it needs to be enlarged from the default split size).
    useLayoutEffect(() => setSplitSizes(DEFAULT_SPLIT_SIZES), [DEFAULT_SPLIT_SIZES, setSplitSizes]);

    // Observing a user changing the split sizes is important because it
    // changes the size of the detail pane without triggering an onResize
    // event.  In other words, anything dependent on knowing the size of this
    // pane (like TabViewLayout) will need to know if the user does this so
    // it can recalculate accordingly.  See CarouselContainer.js
    const onDragEnd = useCallback(setSplitSizes, [setSplitSizes]);

    const detailPanes = useMemo(
        () =>
            children.filter(
                ({ props: { hNode } }) => hNode.showForAction === activationVerb && hNode.hNodeType === 'DetailPane'
            ),
        [activationVerb, children]
    );

    const summaryChildren = useMemo(() => children.filter(c => c.props.hNode.hNodeType !== 'DetailPane'), [children]);

    const displayDetail = record != null;
    const displaySummary = !theme.mobile || record == null;

    // I know collapsed could just be calculated in-line, but there is a bug with react-split
    // where it doesn't render correctly if you start with one of your panels collapsed.
    // This useEffect causes it to render a second time and fix the problem :(
    useEffect(() => {
        if (record == null) {
            setCollapsed(1); // collapse detail panel
        } else if (theme.mobile) {
            setCollapsed(0); // collapse summary panel
        } else {
            setCollapsed(undefined);
        }
    }, [record, theme.mobile]);

    if (theme.mobile) {
        return rc(
            Fragment,
            null,
            rc(
                MobileSplitPanel,
                {
                    ...testProperties(hNode, 'detail-container'),
                    panelDisplay: displayDetail,
                    name: 'detail-panes-container'
                },
                detailPanes
            ),
            rc(
                MobileSplitPanel,
                {
                    ...testProperties(hNode, 'summary-container'),
                    panelDisplay: displaySummary,
                    name: 'summary-container'
                },
                summaryChildren
            )
        );
    }
    // The SplitResizeContext below is consumed by ancestors that need to react to changes in width.
    // Unfortunately, changes in the split percentage do not trigger a resize event.
    // prettier-ignore
    return rc(contexts.SplitResizeContext.Provider, { value: splitSizes },
        rc(StyledSummaryDetail, {
            'data-testid': 'summary-detail',
            name: 'summary-detail',
            gutterSize: collapsed != null ? 0 : 8,
            sizes: DEFAULT_COLLAPSED_SPLIT,
            minSize: 0,
            onDragEnd,
            collapsed,
            ref
        },
            rc(SplitPanel, { panelDisplay: displaySummary, 'data-testid': 'summary-children' }, summaryChildren),
            rc(SplitPanel, { panelDisplay: displayDetail, 'data-testid': 'detail-panes' }, detailPanes)
        )
    );
}

SummaryDetailLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    record: PropTypes.object,
    activationVerb: PropTypes.string,
    currentRoute: PropTypes.string.isRequired,
    hNode: PropTypes.shape({
        defaultNamespace: PropTypes.string,
        defaultRelation: PropTypes.string
    })
};

/**
 * Evaluates and sets the split sizes of a splitter component based on the provided values.
 * If the second value of the split sizes is less than a theme-specified minimum width, the
 * split sizes are adjusted to ensure the second value meets the minimum width requirement.
 * Otherwise, the split sizes are set as requested.
 *
 * @param {Splitter} splitter - The underlying (non-react) splitter component.
 * @param {Function} _setSplitSizes - The react useState function to set the split sizes.
 * @param {Object} theme - The theme object (which contains the maximum width of form
 * element (which is the minimum width for the detail pane)).
 * @param {number[]} newValue - The new split sizes to be evaluated and set.
 */
export function evaluateAndSetSplitSizes(splitter, _setSplitSizes, theme, newValue) {
    const clientSize = splitter.parent.getBoundingClientRect();
    if ((newValue[1] / 100) * clientSize.width < theme.form.entryMaxWidth) {
        const detailPercent = (theme.form.entryMaxWidth / clientSize.width) * 100;
        const summaryPercent = 100 - detailPercent;
        const revisedValue = [summaryPercent, detailPercent];
        logging.debug(
            `[SUMMARY_DETAIL_LAYOUT] requested split: ${JSON.stringify(newValue)} | revisedValue: [${JSON.stringify(
                revisedValue
            )}]`
        );
        // This is necessary because the underlying splitter component is not a react component and
        // does not react to declarative changes in the split sizes.
        splitter.split.setSizes(revisedValue);
        _setSplitSizes(oldValue => {
            if (isEqual(oldValue, revisedValue)) {
                return oldValue;
            }
            return revisedValue;
        });
    } else {
        logging.debug(`[SUMMARY_DETAIL_LAYOUT] requested split: ${JSON.stringify(newValue)}`);
        _setSplitSizes(oldValue => {
            if (isEqual(oldValue, newValue)) {
                return oldValue;
            }
            return newValue;
        });
        // This is necessary because the underlying splitter component is not a react component and
        // does not react to declarative changes in the split sizes.
        setTimeout(() => splitter.split.setSizes(newValue), 2);
    }
}
