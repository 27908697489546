import { BbState } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';

const { retention } = constants;
const { SCOPE, DURATION, VISIBILITY } = retention;
export const _private = { BbState };

export default {
    verb: 'didUpdate',
    namespace: 'application',
    relation: 'useCase',
    type: 'syncAllDataToLocal',
    description: "Load the user's role so data right permissions can use it.",
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'identity',
                relation: 'role',
                type: 'find'
            },
            query: ({ context }) => {
                const _id = context?.session?.role?._id;
                return { _id };
            }
        }
    ],
    //this is the actual logic:
    logic: onSyncFinished
};

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {Promise}
 */
async function onSyncFinished({ prerequisiteResults = [], context, log }) {
    const {
        session: { role }
    } = context;

    const role_retention = {
        // DURATION.PAGE_LOAD/VISIBILITY.PER_BROWSER: this value gets cleared on page reload or login/out
        // but that is OK, as the sync runs in all of those scenarios.
        duration: DURATION.PAGE_LOAD,
        visibility: VISIBILITY.PER_BROWSER,
        scope: SCOPE.LOCAL,
        id: role._id
    };

    const [{ result: [dbRole = { _isDefault: true }] } = {}] = prerequisiteResults;
    if (dbRole._isDefault) {
        // Note, if no role was found for the given _id,
        // or if we never get here due to an error thrown before here,
        // Then the dataRights filtering will never allow any data to be shown....
        log.error(`Failed to find user's role: ${role.title}. Data filtering unavailable.`);
    }

    //useful for testing, hardcode as if there data rights
    // dbRole.system = false;
    // dbRole.dataRights = {
    //     'location:building': [{ title: 'Building 2', _id: '61a64035dce16fc2924358a5' }]
    // };
    const bbState = _private.BbState.connect(role_retention);
    await bbState.setAsync(dbRole, 'role');
}
