import { createElement as rc, useState, useCallback, useRef, useEffect } from 'react';
import lodash from 'lodash';
const { set, clone, merge } = lodash;
import { MenuItem, Portal, View, styled, Button, useDebounce, Text } from 'lib_ui-primitives';
import { useTheme } from 'styled-components';
import useCustomTheme from '../../hooks/useCustomTheme';
import ThemeEntries from './ThemeEntries';
import logging from '@sstdev/lib_logging';

const AllThemeEntries = styled(View).attrs({ name: 'all-theme-entries' })`
    overflow-y: auto;
    flex-direction: column;
    flex-grow: 1;
`;
AllThemeEntries.displayName = 'AllThemeEntries';

const HorizontalFooter = styled(View).attrs({ name: 'horizontal-footer' })`
    flex-direction: row;
    min-height: 55px;
    margin-bottom: 10px;
`;
HorizontalFooter.displayName = 'HorizontalFooter';

const _ThemeEditor = styled(View).attrs({ name: 'theme-editor' })`
    background-color: fromTheme('baseBackgroundColor');
    flex-direction: column;
    height: ${({ theme }) => theme.height - 24}px;
`;
/**
 * @typedef {Object} Props
 */
/** @type {import('react').FC<Props>} */
function ThemeEditor() {
    const theme = useTheme();
    // Load the custom theme from local storage.
    const [customTheme, setCustomTheme, ready] = useCustomTheme();
    // Use a draft of the theme changes to avoid changing the theme until the user clicks save.
    const [intermediateTheme, setIntermediateTheme] = useState();
    const [portalOpen, setPortalOpen] = useState(false);
    const changes = useRef({});

    useEffect(() => {
        if (ready) {
            changes.current = clone(customTheme);
            setIntermediateTheme(merge(theme, customTheme));
        }
    }, [customTheme, theme, ready]);

    const togglePortal = useCallback(() => {
        setPortalOpen(portalOpen => {
            return !portalOpen;
        });
    }, []);

    // Avoid rerendering on each keystroke by debouncing the setIntermediateTheme call.
    const updateTheme = useDebounce((propertyName, value) => {
        logging.debug('[THEME_EDITOR] ', propertyName, value);
        setIntermediateTheme(previous => {
            const newTheme = clone(previous);
            set(newTheme, propertyName, value);
            set(changes.current, propertyName, value);
            return newTheme;
        });
    }, []);

    const reset = useCallback(() => {
        changes.current = {};
        setCustomTheme(changes.current);
        togglePortal();
    }, [togglePortal, setCustomTheme]);

    const saveTheme = useCallback(() => {
        setCustomTheme(changes.current);
        togglePortal();
    }, [togglePortal, setCustomTheme]);

    const closePortal = useCallback(() => {
        setPortalOpen(false);
    }, []);

    // prettier-ignore
    return rc(MenuItem, { key: 'themeEditor', onClick: togglePortal},
        'Theme Editor',
        portalOpen && rc(Portal, { id: 'themeEditorPortal', onUnmount: closePortal },
            !ready && rc(Text, null, 'Loading...'),
            ready && rc(_ThemeEditor, { onClick: e => e.stopPropagation() },
                rc(AllThemeEntries, null,
                    rc(ThemeEntries, {themeValues: intermediateTheme, title: 'Theme Editor', updateTheme, collapsed: false})
                ),
                rc(HorizontalFooter, null,
                    rc(Button, { onClick: reset }, 'Reset'),
                    rc(Button, { onClick: togglePortal }, 'Cancel'),
                    rc(Button, { onClick: saveTheme }, 'Save')
                )
            )
        )
    );
}

ThemeEditor.defaultProps = {};

ThemeEditor.propTypes = {};

export default ThemeEditor;
